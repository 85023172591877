import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get as getLodash } from 'lodash';

import AllowedForUser from '../container/AllowedForUser';
import CollapseArea from '../container/CollapseArea';

import { bodyGrey, /*blue*/ } from '../style/colors';
import InternNavLink from '../style/InternNavLink';
import ExternNavLink from '../style/ExternNavLink';
import IconLink from '../style/IconLink';
//import Link from '../style/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
//import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const RowNoWrap = styled('div')`
    display: flex;    
    flex-flow: no wrap;
    
    ${({ center }) => center && 'justify-content: center;'}
    ${({ right }) => right && 'justify-content: flex-end;'}
    ${({ between }) => between && 'justify-content: space-between;'}
    
    align-items: ${({ hcenter }) => (hcenter ? 'center' : 'stretch')};
`;

const RowWithWrap = styled('div')`
    display: flex;    
    flex-flow: row wrap;
    
    ${({ center }) => center && 'justify-content: center;'}
    ${({ right }) => right && 'justify-content: flex-end;'}
    ${({ between }) => between && 'justify-content: space-between;'}
    
    align-items: ${({ hcenter }) => (hcenter ? 'center' : 'stretch')};
`;

const SubheaderStyle = styled(RowNoWrap)`
	align-items: center;
	justify-content: center;
	height: 6%;
	width: 100%;
    background-color: ${bodyGrey};
    border-bottom: 1px solid rgba(32, 33, 36, 0.28);
`;

const ContentBlock = styled(RowNoWrap)`
    align-items: center;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	@media screen and (max-width:815px){
    	#navright { display:none; }
	}
	width: 900px;
`;

const LeftNavbar = styled(RowWithWrap)`
    font-weight: 500;
    font-size: 1rem;
`;

const RightNavbar = styled(RowNoWrap)`
    font-weight: 500;
    font-size: 1rem;
`;

/*
const Title = styled(Link)`
    display: flex;
	align-items: center;
	justify-content: center; 
    padding: 0 1em;
	text-align: right;
	margin-bottom: 5px;
	margin-left: 70px;
	width: 100%;
    font-size: 22px;
    font-weight: 300;
    color: ${blue};
    &:hover {
        color: grey;
    }
	&:visited {
		color: ${blue};
	}
    &.activeNavItem {
        border-bottom: 5px solid rgba(0, 0, 0, 0.25);
        margin-bottom: 0;
    }
`;
*/

class Subheader extends Component {
    render() {
        const token = localStorage.getItem('token');
        let titel = '';
        const path = this.props.path.split('/')[1];
        //suchen
        if (path.toLowerCase() === 'search') titel = 'Eingabe';
		if (path.toLowerCase() === 'band') titel = 'Bände';
        if (path.toLowerCase() === 'advsearch') titel = 'Angepasste Suche';
        if (path.toLowerCase() === 'register') titel = 'Register';
        if (path.toLowerCase() === 'filter') titel = 'Suche';
        if (path.toLowerCase() === 'suche') titel = 'Suche';
		if (path.toLowerCase() === 'erweitert') titel = 'Erweiterte Suche';
		if (path.toLowerCase() === 'attributes') titel = 'Suche';
        if (path.toLowerCase() === 'operator') titel = 'Erweiterte Suche';
        if (path.toLowerCase() === 'list') titel = 'Listen';
		if (path.toLowerCase() === 'benutzungshinweise') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'bildnachweise') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'briefdesmonats') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'letterofthemonth') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'editionsbaende') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'editions') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'instructions') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'engl') titel = 'Theologenbriefwechsel';
		if (path.toLowerCase() === 'karte') titel = 'Theologenbriefwechsel';
        //parsed bei der suche den typ dazu
        if (titel !== '' && this.props.path.split('/')[2] !== undefined && this.props.path.split('/')[2] !== "engl") {
            let type = this.props.path.split('/')[2];
            if (type.toLowerCase() === 'geo') type = 'Geographische Kategorien';
            if (type.toLowerCase() === 'uebform') type = 'Überlieferungsform';
            if (type.toLowerCase() === 'person')
                if (
                    this.props.path.split('/')[3] !== undefined &&
                    this.props.path.split('/')[3].toLowerCase() === 'zitiername'
                )
                    type = 'Zitiername';
            if (type) titel += ': ' + type.charAt(0).toUpperCase() + type.slice(1);
        }
        //edit, log & publicView
        if (
            path.toLowerCase() === 'edit' ||
            path.toLowerCase() === 'ansicht' ||
            path.toLowerCase() === 'log' ||
			path.toLowerCase() === 'ref'
        ) {
            let short = this.props.short ? this.props.short : '';
            //let long = this.props.long ? this.props.long : '';
            let datum = this.props.datum ? this.props.datum : '';
            let archiv = this.props.archiv ? this.props.archiv : '';
            let signatur = this.props.signatur ? this.props.signatur : '';
            if (signatur.length > 12) signatur = signatur.slice(0,12) + '...'
            let seiteHandschrift = this.props.hsSeite ? this.props.hsSeite : '';
            let druck = this.props.druck ? this.props.druck : '';
            if (druck.length > 19) druck = druck.slice(0,19) + '...';
            let seiteBriefdruck = this.props.briefdruckSeite ? this.props.briefdruckSeite : '';
            
            let type = this.props.path.split('/')[2];
            if (type && type.toLowerCase() === 'geo') type = 'Geographische Kategorien';
            if (type && type.toLowerCase() === 'uebform') type = 'Überlieferungsform';
            if (type && type !== 'briefhandschrift' && type !== 'briefdruck') titel = type.charAt(0).toUpperCase() + type.slice(1);
            if (type === 'briefhandschrift') {
				titel += archiv + ', ' + signatur + ', ' + seiteHandschrift
			} 
			else if (type === 'brief') {
				titel += ' ' + short + ' (' + datum + ')'
			}
			else if (type === 'briefdruck') {
				titel += druck + ', ' + seiteBriefdruck
			}
			else {
				titel += ' ' + short;
			}
            if (path.toLowerCase() === 'log') titel = 'Log: ' + titel;
			if (path.toLowerCase() === 'ref') titel = 'Lösche ' + titel;
        }
        //rest
        if (path.toLowerCase() === '') titel = 'Theologenbriefwechsel';
        if (path.toLowerCase() === 'login') titel = 'Theologenbriefwechsel';
        if (path.toLowerCase() === 'user') titel = 'Mitarbeiter';
        if (path.toLowerCase() === 'brief') titel = 'Redirect';
        return (
            <>
                <title>{titel}</title>
					{this.props.path.split('/')[1] !== 'findOrt' && (
	                <SubheaderStyle>
						<ContentBlock>
		                    <LeftNavbar>
								{((this.props.path.split('/')[1] !== 'erweitert' && this.props.path.split('/')[1] !== 'filter') || 
								((this.props.path.split('/')[1] === 'erweitert' || this.props.path.split('/')[1] === 'filter') 
									&& this.props.path.split('/')[2] !== 'brief')) && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/brief/engl">Letters</ExternNavLink>) : 
										(<ExternNavLink to="/filter/brief">Briefe</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'erweitert' && this.props.path.split('/')[2] === 'brief' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/brief/engl">Letters</ExternNavLink>) : 
										(<ExternNavLink to="/filter/brief">Briefe</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'filter' && this.props.path.split('/')[2] === 'brief' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/brief/engl">Letters</ExternNavLink>) : 
										(<ExternNavLink to="/filter/brief">Briefe</ExternNavLink>) 
									}
									</>
								)}
								{((this.props.path.split('/')[1] !== 'erweitert' && this.props.path.split('/')[1] !== 'filter') || 
								((this.props.path.split('/')[1] === 'erweitert' || this.props.path.split('/')[1] === 'filter') 
									&& this.props.path.split('/')[2] !== 'person')) && (
		                        	<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/person/engl">Persons</ExternNavLink>) : 
										(<ExternNavLink to="/filter/person">Personen</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'erweitert' && this.props.path.split('/')[2] === 'person' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/person/engl">Persons</ExternNavLink>) : 
										(<ExternNavLink to="/filter/person">Personen</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'filter' && this.props.path.split('/')[2] === 'person' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/person/engl">Persons</ExternNavLink>) : 
										(<ExternNavLink to="/filter/person">Personen</ExternNavLink>) 
									}
									</>
								)}
								{((this.props.path.split('/')[1] !== 'erweitert' && this.props.path.split('/')[1] !== 'filter') || 
								((this.props.path.split('/')[1] === 'erweitert' || this.props.path.split('/')[1] === 'filter') 
									&& this.props.path.split('/')[2] !== 'ort')) && (
		                        	<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/ort/engl">Places</ExternNavLink>) : 
										(<ExternNavLink to="/filter/ort">Orte</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'filter' && this.props.path.split('/')[2] === 'ort' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/ort/engl">Places</ExternNavLink>) : 
										(<ExternNavLink to="/filter/ort">Orte</ExternNavLink>) 
									}
									</>
								)}
								{((this.props.path.split('/')[1] !== 'erweitert' && this.props.path.split('/')[1] !== 'filter') || 
								((this.props.path.split('/')[1] === 'erweitert' || this.props.path.split('/')[1] === 'filter') 
									&& this.props.path.split('/')[2] !== 'sache')) && (
		                        	<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/sache/engl">Subjects</ExternNavLink>) : 
										(<ExternNavLink to="/filter/sache">Sachen</ExternNavLink>) 
									}
									</>
								)}
								{this.props.path.split('/')[1] === 'filter' && this.props.path.split('/')[2] === 'sache' && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? 
										(<ExternNavLink to="/filter/sache/engl">Subjects</ExternNavLink>) : 
										(<ExternNavLink to="/filter/sache">Sachen</ExternNavLink>) 
									}
									</>
								)}
								{/*this.props.path.split('/')[1] === 'karte' && <Title to="/">Theologenbriefwechsel</Title>*/}
								<InternNavLink to="/login">
		                            {token === null ? 'Login' : 'Logout'}
		                        </InternNavLink>
								<AllowedForUser rolle={'Hiwi'}>
		                            <InternNavLink to={'/user/favoriten/' + this.props.id} color="grey">
		                                {token === null ? '' : localStorage.getItem('short')}
		                            </InternNavLink>
		                        </AllowedForUser>
		                        {token && (
		                            <>
		                                <InternNavLink to="/search">Eingabe</InternNavLink>
		                                {this.props.path.split('/')[1] !== 'operator' && (
											<InternNavLink to="/attributes">Suche</InternNavLink>
										)}
										{this.props.path.split('/')[1] === 'operator' && (
											<InternNavLink to="/operator">Suche</InternNavLink>
										)}
									   	<CollapseArea noborder show={false}>
									   		<RowNoWrap>
												<InternNavLink to="/band">Bände</InternNavLink>
												<InternNavLink to="/list">Listen</InternNavLink>
												{/*<InternNavLink to="/advSearch">...</InternNavLink>*/}
											</RowNoWrap>
										</CollapseArea>
									</>
								)}
							</LeftNavbar>
							<RightNavbar id="navright">
								{this.props.path.split('/')[this.props.path.split('/').length-1] !== 'engl' && (
									<IconLink to="/">
											<FontAwesomeIcon icon={faHome} />
			                        </IconLink>
								)}
								{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' && (
									<IconLink to="/engl">
											<FontAwesomeIcon icon={faHome} />
			                        </IconLink>
								)}
								{/*
								<IconLink to="//www.hadw-bw.de/forschung/forschungsstelle/theologenbrief-wechsel-im-suedwesten-des-reichs-der-fruehen-neuzeit-1550-1620/kontakt">
										<FontAwesomeIcon icon={faEnvelope} />
		                        </IconLink>
								<IconLink to="/">
										<FontAwesomeIcon icon={faSearch} />
		                        </IconLink>
								*/}
								{!token && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] !== 'engl' && (
										<IconLink to="/benutzungshinweise">
												<FontAwesomeIcon icon={faQuestion} />
				                        </IconLink>
									)}
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' && (
										<IconLink to="/instructions/engl">
												<FontAwesomeIcon icon={faQuestion} />
				                        </IconLink>
									)}
									</>
								)}
								{!token && (
									<>
									{this.props.path.split('/')[this.props.path.split('/').length-1] !== 'engl' && (
										<IconLink to="/engl">
												English
				                        </IconLink>
									)}
									{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' && (
										<IconLink to="/">
												Deutsch
				                        </IconLink>
				                    )}
									</>
								)}
		                    </RightNavbar>
						</ContentBlock>
	                </SubheaderStyle>
				)}
            </>
        );
    }
}

const mapStateToProps = state => ({
    path: state.router.location.pathname,
    short: state.item.short && state.item.short.v,
    long: state.item.long && state.item.long.v,
    datum: state.item.datierung && state.item.datierung.listenform && state.item.datierung.listenform.v,
    archiv: state.item.archiv && state.item.archiv.v && state.item.archiv.v.short && state.item.archiv.v.short.v,
    signatur: state.item.signatur && state.item.signatur.v && state.item.signatur.v.short && state.item.signatur.v.short.v,
    druck: state.item.druck && state.item.druck.v && state.item.druck.v.short && state.item.druck.v.short.v,
    hsSeite: state.item.seite && state.item.seite.v,
    briefdruckSeite: state.item.seite && state.item.seite.v,
    id: getLodash(state, 'auth.user.id'),
});

export default connect(mapStateToProps)(Subheader);
