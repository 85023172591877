import React from 'react';
import styled from 'styled-components';
import ItemContainer from '../style/ItemContainer';
import TextblockArea from '../style/TextblockArea';
import Area from '../style/Area';
import Row from '../style/Row';
import H2 from '../style/H2';
import { grey } from '../style/colors';
import Link from '../style/Link';
import Space from '../style/Space';
import background from '../../assets/images/background.jpg';

const Style = styled('div')`
	text-align: justify;
	justify-content: center;
    max-width: 100%;
    height: 100%;
	line-height: 1.5;
	background-image: url(${background});
	background-position: center;
	background-attachment: scroll;
`;

const Titel = styled('div')`
    font-size: 1.5em !important;
    font-weight: bold;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
	margin-top: 5px !important;
    margin-bottom: 8px !important;
    color: ${grey} !important;
	text-align: center;
    max-width: 800px;
`;

const Brieftitel = styled('div')`
	margin-top: 3px;
    margin-bottom: 5px;
`;

const Unterschrift = styled('div')`
	text-align: right;
`;

const ImageBox = styled('div')`
    align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 8px;
	background-color: ${props => (props.color ? props.color : '')};
    max-width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const ImageBoxCentered = styled('div')`
    align-items: center;
	justify-content: center;
	margin-left: 15.5em;
	margin-bottom: 8px;
	background-color: ${props => (props.color ? props.color : '')};
    max-width: ${props => (props.width ? props.width : 'fit-content')};
    max-height: ${props => (props.height ? props.height : 'fit-content')};
`;

const ImageColumns = styled('div')`
    align-items: center;
	margin-top: 10px;
	margin-bottom: 8px;
	background-color: ${props => (props.color ? props.color : '')};
    max-width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

//const Datum = new Date();
//const Jahr = Datum.getFullYear();
//const Monat = Datum.getMonth()+1;

class BriefDesMonats extends React.Component {
render() { return (  
 <Style>
	<br/><br/><br/><br/>
	<Row center>
		<H2>Brief des Monats</H2>
			<br/><br/><br/><br/>
	</Row>
	{/*
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Monat Jahr: Titel</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b></b> 
						 (<Link to="/brief/#####" target="_blank">Brief-ID #####</Link>)<br/>
					</Brieftitel>
					Text<br/>
					<ImageBox>
						<img src="#.jpg" width="100%" alt="" />
						<Space/>Bildbeschriftung&nbsp;
						<Link to="//link" target="_blank">(Quellenangabe)</Link>
					</ImageBox>
					<br/>
					<Unterschrift></Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	*/}
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Dezember 2024: Pakete mit Namensschildern: Die Pest in Tübingen 1610</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>4. September 1610, Stuttgart. Herzog Johann Friedrich von Württemberg an Superattendenten, 
						Magister Domus und Prokurator des Tübinger Stifts </b> 
						 (<Link to="/brief/54351" target="_blank">Brief-ID 54351</Link>)<br/>
					</Brieftitel>
					Wiederkehrende Seuchen und Epidemien waren nicht nur in den 1560er und 1570er Jahren zur 
					Normalität geworden (vgl. die Briefe des Monats Februar 2021, Dezember 2022, März 2023, März 
					und April 2024), sondern hielten die Zeitgenossen auch im 17. Jahrhundert in Bewegung. Im 
					September 1610 grassierte die Pest in Tübingen. Der Mömpelgarder Stipendiat Joachim Ernst 
					Hermann hatte sich infiziert und man fürchtete, dass sich andere Studenten anstecken könnten. 
					Um die Ausbreitung der Seuche unter den Stipendiaten des Tübinger Stifts wirksam einzudämmen, 
					ließ Herzog Johann Friedrich gezielte Maßnahmen ergreifen und wandte sich am 4. September an 
					Stephan Gerlach, Matthias Hafenreffer, Vitus Müller und Marx Schmidlapp als Verantwortliche 
					des Stift: Der erkrankte Mömpelgarder Student sollte umgehend nach Hause fahren. Da weitere 
					Krankheitsfälle zu erwarten waren,<i> da sie länger beysamen gelaßen </i>würden, sollten sämtliche 
					Stipendiaten etwas früher als sonst in die<i> gewohnliche herpßt vacantz </i>geschickt werden.
					<br/><br/>
					Vor ihrer Abreise wurden jedoch noch Vorkehrungen für den Fall getroffen, dass Universität 
					und Stift wegen der Epidemie aus Tübingen weichen müssten, wie es in der Vergangenheit bereits 
					des Öfteren der Fall gewesen war. Jeder Stipendiat sollte seine Habe<i> einpackhen und einschlagen </i> 
					und mit einem Namensschild versehen<i> vor die gemach herauß uff die gäng </i>stellen, damit man nicht 
					mehr<i> in locum infectum </i>gehen müsse, sondern seine Sachen kurzerhand aufladen und anderswohin 
					transportieren könne.<br/>
					Die Stipendiaten sollten sich dann zu ihren Eltern oder Verwandten in Gegenden mit
					<i> reiner lufft </i>begeben, wo die Seuche also noch nicht grassierte. Unter keinen Umständen sollten sie aber nach 
					Stuttgart oder an andere Orte reisen, in der sich Mitglieder der herzoglichen Familie mit ihrer 
					Hofhaltung aufhielten.
					<br/><br/>
					Die Vorkehrungen, die man hinsichtlich einer zeitweiligen Verlegung des Tübinger Stifts getroffen 
					hatte, erwiesen sich als äußerst sinnvoll, denn noch im September 1610 musste der Lehrbetrieb in 
					Tübingen eingestellt werden. Die Universität und das Stift wurden in die ca. 40 km nördlich 
					gelegene Landstadt Calw verlegt, und erst im Frühjahr 1611 kehrten sie nach Tübingen zurück.
					<br/><br/>
					<ImageBoxCentered width="385px">
						<img src="pestkreuz.jpg" width="100%" alt="" />
						<Space/>Pestkreuz bei Leiberg (Kreis Paderborn).
						<Link to="//commons.wikimedia.org/wiki/File:Mestermannpestkreuz.jpg" target="_blank">(wikimedia).</Link>
						<Space/>Inschrift: ANNO 1635 DEN 25 [AV]GVSTI HAT VNS GOT DIE PESTILENS GESANT, WIE 
						MANGEM IST BEKANT, SINT VOM DORF LEBERG 400 MENSCHEN GESTORBEN, DENEN GOT DIE SELIKIT ERWORBEN. 
						AMEN.&nbsp;
					</ImageBoxCentered>
					<br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>November 2024: Evangelische Vorbehalte gegen das kopernikanische Weltbild</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>12. April 1598, Tübingen. Matthias Hafenreffer an Johannes Kepler </b> 
						 (<Link to="/brief/26122" target="_blank">Brief-ID 26122</Link>)<br/>
					</Brieftitel>
					Wie schwer sich die römisch-katholische Kirche mit der heliozentrischen Lehre Nikolaus Kopernikus’ 
					(1473-1543) tat, ist bekannt. Ins Kollektivbewusstsein eingebrannt hat sich der Prozess, den die 
					römische Inquisition gegen den italienischen Forscher Galileo Galilei (1564-1642) im Juni 1633 
					wegen seines Festhaltens am kopernikanischen System führte.<br/><br/>
					Wer das Briefcorpus unseres Forschungsprojekts näher studiert, entdeckt in der Korrespondenz 
					zwischen dem Tübinger Theologieprofessor Matthias Hafenreffer (1561-1619) und seinem ehemaligen 
					Studenten, dem in Graz tätigen Mathematikprofessor und Astronomen Johannes Kepler (1571-1630), 
					auffällige Parallelen: hier der rastlos forschende Astronom, der seine bahnbrechenden Beobachtungen 
					eifrig veröffentlicht, dort die kirchlich-theologische Instanz, die seine astronomischen Aussagen 
					kritisch verfolgt und vor allem die Selbstverständlichkeit, mit der er sein kopernikanisches 
					Weltbild mit der Heiligen Schrift in Einklang bringt, mit Sorge zur Kenntnis nimmt. Für Hafenreffer 
					ließ die Bibel einzig und allein ein geozentrisches Weltbild zu. Heliozentrische Modelle waren als 
					reine Gedankenkonstrukte erlaubt, mussten aber ausdrücklich als Hypothesen identifiziert werden, um 
					nicht im Widerspruch zur Bibel zu stehen. In unzähligen Briefen schmeichelt Hafenreffer seinem 
					ehemaligen Schüler aufgrund seines mathematischen Könnens, ermahnt ihn aber zugleich in einem 
					herablassenden Ton, seine Hände von theologischen Fragen zu lassen. Kepler möge sich als bloßer 
					Mathematiker (<i>nudus mathematicus</i>) betätigen indem er seine heliozentrischen Forschungsergebnisse als 
					reine Hypothesen präsentiert. In einem Brief vom 12. April 1598 lobt Hafenreffer Kepler ausdrücklich 
					dafür, dass er in seinem ersten grundlegenden astronomischen Werk<i> Mysterium Cosmographicum </i>(1596) 
					dasjenige Kapitel, in dem er die kopernikanische Lehre als im Einklang mit der Bibel darlegen wollte, 
					fallenließ. Hafenreffers Lob muss in Keplers Ohren hohl geklungen haben: Im Vorfeld der Drucklegung 
					des<i> Mysterium Cosmographicum </i>in Tübingen im Sommer 1596 war es ja Hafenreffer selbst, der im Stillen 
					dafür gesorgt hatte, dass dieses Kapitel gestrichen wurde.
					<br/><br/>
					<ImageBox>
						<img src="heliozentrisch.jpg" width="100%" alt="" />
						<Space/>Nikolaus Kopernikus’ eigenhändige Darstellung seines heliozentrischen Weltbildes im Manuskript 
						von De revolutionibus orbium celestium (ca. 1536)&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:De_Revolutionibus_manuscript_p9b.jpg" target="_blank">(wikimedia)</Link>
					</ImageBox>
					<br/>
					<Unterschrift></Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Oktober 2024: Häusliche Altenpflege in Tübingen und Schwäbisch Hall</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>9. Februar 1596, Schwäbisch Hall. Johann Morhard an Rektor Matthias Hafenreffer, Doktoren und Regenten der Universität Tübingen </b> 
						 (<Link to="/brief/89306" target="_blank">Brief-ID 89306</Link>)<br/>
					</Brieftitel>
					Johann Morhard (1554-1631) entstammte der bekannten Tübinger Druckerfamilie, er war der Sohn von Ulrich 
					Morhard d. J. und seiner Frau Katharina. Nach dem Tod seines Vaters (1567) heiratete seine Mutter ein 
					zweites Mal: Sie ging die Ehe mit Alexander Hock ein, gemeinsam führten sie die Druckerei in Tübingen 
					weiter.<br/><br/>
					Ende des 16. Jahrhunderts war Katharina Morhard (geb. 1526) eine alte, kranke Frau, und ihr 
					Sohn, der seit 1586 als Stadtarzt in Schwäbisch Hall tätig war, bemühte sich bei der Universität Tübingen 
					um finanzielle Unterstützung – um ein sogenannten Victalitium – für sie. Am 9. Februar 1596 schrieb Johann Morhard an den Rektor der Universität Tübingen, den Theologieprofessor 
					Matthias Hafenreffer, und berichtete über den Zustand seiner Mutter: Inzwischen sei sie 70 Jahre alt, 
					leide an Krankheiten und sei immer häufiger bettlägerig. Sie könne den Haushalt nicht mehr führen und 
					ihrem Mann die ehelichen Pflichten nicht mehr erfüllen. Von ihrem Ehemann erfahre sie keinerlei<i> wart 
					oder pfleg</i>, und er weigere sich auch, Mittel für ihre Versorgung zur Verfügung zu stellen. Folglich 
					drohe sich ihr Gesundheitszustand immer weiter zu verschlechtern, so dass er und seine Schwester Anna 
					sich um die alte Mutter kümmern müssten.<br/><br/>
					Die Geschwister vereinbarten schließlich mit ihrem Stiefvater, dass dieser das Wohnhaus in Tübingen 
					verlassen solle, um es verkaufen zu können. Von dem Erlös sollte der Stiefvater ausgezahlt werden und 
					die Mutter<i> artzney, labung vnd vnderhaltung </i>erhalten. Die Universität hatte der zeitweiligen, 
					pflegebedingten Trennung von Tisch und Bett im Hause Morhard bereits zugestimmt, und zwar so lange, bis 
					die alte Frau wieder all ihren haushaltlichen und ehelichen Pflichten nachkommen und mit ihrem Mann unter 
					einem Dach leben könnte – oder bis Gott sie aus dem zeitlichen Leben abberufen würde. Johann Morhard 
					betonte in seinem Schreiben an die Universität, dass nicht die dauerhafte Trennung der Eheleute 
					herbeigeführt werden solle. Ziel sei vielmehr, die kranke Frau, die zu Hause nicht die nötige Pflege 
					und Fürsorge erfahre, an einem abgesonderten Ort besser zu versorgen, wie man es traditionell auch in 
					Spitälern mit den Alten und Kranken handhabe.<br/><br/>
					Johann und Anna hatten mit ihrem Stiefvater abgesprochen, dass er um Martini (11. November) aus dem Haus 
					auszieht. Da er jedoch erst eine andere Unterkunft finden musste, wohnte er länger als vereinbart darin. 
					Die Mutter war inzwischen bei ihrer Tochter Anna in Tübingen untergebracht worden. Johann hätte sie auch 
					gerne zu sich nach Schwäbisch Hall geholt, die weite Reise im Winter war jedoch zu beschwerlich und er 
					hatte aufgrund seiner vielen Kinder keinen Platz für sie. Im Sommer plante er aber, Räumlichkeiten für 
					seine Mutter freizumachen.<br/>
					Im April 1596, wenige Monate, nachdem Johann Morhard an die Universität Tübingen geschrieben hatte, ließ er 
					seine Mutter tatsächlich zu sich nach Schwäbisch Hall bringen. Die alte Frau war aber offensichtlich so krank 
					und schwach, dass sie nicht mehr in die eheliche Gemeinschaft zurückkehren konnte. Im Mai des folgenden Jahres 
					erlitt sie einen Schlaganfall und starb eines<i> sanfften </i>Todes, wie Morhard in seiner Haller 
					Haus-Chronik notierte.<br/><br/>
					<ImageBoxCentered width="385px">
						<img src="greisin.jpg" width="100%" alt="" />
						<Space/>Alte Frau mit grünem Kopftuch, Christian Seybold († 1768), Öl auf Kupfer&nbsp;
						<Link to="//skd-online-collection.skd.museum/Details/Index/405469" target="_blank">(© Gemäldegalerie Alte Meister, Staatliche Kunstsammlungen Dresden)</Link>
					</ImageBoxCentered>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>September 2024: Ghosting in der frühen Neuzeit – das diskrete Verschwinden Wolfgang Zündelins</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>16. Juni 1600, Heidelberg. Daniel Tossanus d. Ä. an Graf Ludwig von Sayn zu Wittgenstein </b> 
						(<Link to="/brief/26386" target="_blank">Brief-ID 26386</Link>)<br/>
					</Brieftitel>
					Der Heidelberger Superintendent und Theologieprofessor Daniel Tossanus berichtete am 16. Juni 1600 
					seinem Freund, dem Grafen Ludwig von Sayn zu Wittgenstein, von einem tragischen Ereignis: Eleonore, 
					das noch nicht ganz einjährige Töchterchen der Kurfürstin Louise Juliana und des Kurfürsten Friedrich 
					IV. von der Pfalz sei wenige Tage zuvor verstorben. Aber auch ein zweites unglückliches Ereignis 
					beschäftigte ihn: Am 30. Mai sei der kurfürstliche Rat Wolfgang Zündelin zu seinem gewohnten Spaziergang 
					in der Nähe des Heidelberger Schlosses aufgebrochen. Seitdem war er nicht mehr gesehen worden. Sein 
					mysteriöses Verschwinden gebe dem Kurfürsten und dessen gesamtem Heidelberger Umfeld Anlass zu Sorge und 
					Spekulationen (<i>cum autem 30. Maii pro more extra arcem solus deambulatum prodiisset, ab eo tempore 
					conspectus non fuit, quae res serenissimo Electori et nobis omnibus varias curas et cogitationes 
					iniecit</i>).<br/>
					<ImageBox>
						<img src="hdschloss.jpg" width="100%" alt="" />
						<Space/>Kurpfälzisches Skizzenbuch: Blick auf das Heidelberger Schloss von Südosten, ca. 1590-1610.&nbsp;
						<Link to="//www.staatsgalerie.de/de/collection/object/DC9F5614467656754C52F6940E36B7AA" target="_blank">
						(Staatsgalerie Stuttgart, Graphische Sammlung, alter Bestand)</Link>
					</ImageBox>
					<br/>
					Man kann sich die Verblüffung und vielleicht auch die Empörung Friedrichs IV. allzu gut vorstellen: 
					Nachdem er Zündelin in allen umliegenden Ortschaften hatte suchen lassen, erhielt er drei Wochen später 
					von diesem aus Konstanz die Bitte, ihm seine zurückgelassenen Besitztümer übersenden zu lassen. Kurfürst 
					Friedrich IV. war zum Opfer dessen geworden, was wir heute als „Ghosting“ bezeichnen würden: der 
					einseitige, unangekündigte Abbruch jeglichen Kontaktes, oft verbunden mit dem Verlassen eines 
					Arbeitsverhältnisses.<br/><br/>
					Noch heute rätseln Historiker über die Gründe für das spurlose Verschwinden des kurfürstlichen Rates. 
					Als Friedrich IV. ihn 1596 in seine Dienste nahm, hatte Zündelin bereits ein bewegtes und ereignisreiches 
					Berufsleben hinter sich: Anfang der 1570er Jahre war der gebildete Jurist als Agent in kurpfälzischen 
					Diensten nach Venedig gesandt worden, wo er bis 1589 nicht nur Friedrich III., sondern auch den 
					hessischen Landgrafen Wilhelm IV. sowie den sächsischen Kurfürsten Christian I. wöchentlich mit 
					politischen Nachrichten versorgte. Die Arbeit in der Lagunenstadt, wo Diplomaten aus Italien, Frankreich 
					und Konstantinopel verkehrten, war nicht ungefährlich. Zündelin musste verdeckt arbeiten, immer in der 
					Angst, enttarnt zu werden, wie es seinem Freund Girolamo Donzellini widerfuhr, der 1587 von der 
					Inquisition hingerichtet wurde. 1589 wechselte er zum Hof Christians I. nach Dresden, wo er zu einem 
					engen Freund des sächsischen Kanzlers Nikolaus Krell wurde und den politischen Kurs seines 
					calvinistischen Dienstherrn bewusst mittrug. Der Tod Christians I. 1591 und Krells darauffolgende 
					Verhaftung nötigten Zündelin zur Flucht aus Dresden. Nach Umwegen über Frankfurt, Straßburg und Basel 
					fand er am kurpfälzischen Hof in Heidelberg Zuflucht. Trotz seiner sicheren Anstellung als kurpfälzischer 
					Rat und des hohen Ansehens, das er bei Friedrich IV. genoss, scheinen ihn die anticalvinistische Reaktion 
					in Sachsen und das Schicksal seines Freundes Krell schwer traumatisiert zu haben. Unbekannt bleibt, warum 
					er sich in Heidelberg vor einem „großen, bevorstehenden Jammer“ fürchtete, wie er Friedrich IV. von 
					Konstanz aus kryptisch wissen ließ.<br/>
					Wolfgang Zündelin soll 1614 in Winterthur eines friedlichen Todes gestorben sein.
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>August 2024: „vf der Erden essen“? Wenn Stipendiaten sich beschweren</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>11. Juni 1575, Stuttgart. Ludwig, Herzog von Württemberg an Superattendenten und Magister Domus des Stifts in Tübingen </b> 
						(<Link to="/brief/82779" target="_blank">Brief-ID 82779</Link>)<br/>
					</Brieftitel>
					Gelegentlich kam es vor, dass Stipendiaten des berühmten Tübinger Stifts sich einer Übertretung oder eines 
					Vergehens schuldig machten und sich dafür eine Strafe zuzogen. In der Datenbank unseres Forschungsprojekts 
					sind mit dem Schlagwort „Bestrafung von Stipendiaten“ zahlreiche Briefe verknüpft, die das Tübinger Stift 
					betreffen. Sie lassen erkennen, wie vielfältig die Probleme waren, mit denen sich die württembergische 
					Obrigkeit in Stuttgart angesichts fehlbarer Stipendiaten befassen musste. So kam es beispielsweise zu 
					Zuwiderhandlungen gegen die Stiftsordnung durch heimliches Verlassen des Stifts oder das Tragen zerschnittener 
					Kleider. Mitunter wurde Geld gestohlen oder auf der Gasse musiziert. Geahndet wurden auch das Abfassen von 
					Schmähschriften, vorehelicher Beischlaf oder Eheschließungen ohne das Wissen des Präzeptors. Die Strafen 
					fielen je nach Schweregrad des Fehlverhaltens unterschiedlich aus. Nicht selten wurden die Delinquenten für 
					etliche Tage in den Karzer gesteckt.<br/><br/>
					Eine eigentümliche Form der Bestrafung begegnet in einem Brief aus dem Jahr 1575. Darin teilt Herzog Ludwig 
					den Vorstehern des Stifts mit, dass zwei aus Mömpelgard stammende Stipendiaten, Fabritius Docourt und Antonius 
					Quelotus, persönlich in Stuttgart erschienen sind und um Erlass ihrer Strafe ersuchen, „auf der Erde essen zu 
					müssen“. Diese Strafe hätten sie wegen eines (ihrer Meinung nach) geringen Vergehens erhalten. Herzog Ludwig 
					bittet nun die Vorsteher des Stifts um einen schriftlichen Bericht über die Art des Vergehens der beiden 
					Stipendiaten. Da diese ohne Wissen und Erlaubnis der Stiftsvorsteher und gegen die herzogliche Anordnung nach 
					Stuttgart gezogen sind, sollen sie mit einer Karzerstrafe belegt werden.<br/><br/>
					Die Strafe, „auf der Erde essen“ zu müssen, ist nicht etwa auf eine Erfindung des Tübinger Stifts 
					zurückzuführen. Schon in einigen Mönchsklöstern war diese Form der Maßregelung üblich – etwa dann, wenn jemand 
					das Gebot des Stillschweigens übertreten hatte. Tatsächlich mussten die Delinquenten auf dem Boden sitzen, um 
					dann dort ihre Mahlzeit einzunehmen. In milderen Fällen gab es hierfür ein kleines Brett oder ein 
					abgesondertes Bänkchen, auf dem sie sitzen durften. Was sich die beiden Stipendiaten in unserem Brief 
					zuschulden kommen ließen, ist nicht bekannt. Sie verschlimmerten jedoch ihre Lage und zogen sich eine ungleich 
					härtere Strafe zu, indem sie das Stift unerlaubt verlassen hatten, um nach Stuttgart zu reisen und sich dort 
					zu beschweren – und gerieten damit vom Regen in die Traufe.<br/><br/>
					<ImageBox>
						<img src="tuebingen.jpg" width="100%" alt="" />
						<Space/>Abbildung: Hogenberg/Braun: Tübingen von Süden. Kolorierter Kupferstich (1572/1590)&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:Tuebingen_Braun-Hogenberg.jpg" target="_blank">(wikimedia)</Link>
					</ImageBox>
					<br/>
					<Unterschrift>Judith Steiniger</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juli 2024: Honig, Ziegenmilch und Kräuter – ein Mittel zur Vorbeugung gegen Magenschmerzen</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>18. September 1570, Basel. Simon Sulzer an Johannes Marbach </b> 
						(<Link to="/brief/19983" target="_blank">Brief-ID 19983</Link>)<br/>
					</Brieftitel>
					Der Straßburger Theologe Johannes Marbach (1521-1581) war ein von gesundheitlichen Beschwerden geplagter Mann
					 – das zumindest suggeriert ein Blick in seinen Briefwechsel. Immer wieder erkundigen sich seine Korrespondenten
				 	nach seinem Gesundheitszustand oder wünschen ihm Besserung; auch in den Briefwechseln seiner Söhne Erasmus 
					(1548-1593) und Philipp (1550-1611) sind Johannes Marbachs Krankheiten immer wieder Thema.<br/><br/>
					Wiederholt tauscht sich Marbach mit seinem Vertrauten, dem Basler Antistes und Theologieprofessor Simon Sulzer 
					(1508-1585), über gesundheitliche Fragen aus; dieser zeigt sich nicht nur besorgt, wenn es Marbach schlecht geht 
					– und zwar deshalb, weil sein Tod für die Kirche einen herben Verlust bedeuten würde –, sondern er erteilt auch 
					medizinische Ratschläge. Sulzer gegenüber berichtet Marbach etwa von überwundenen Fieberanfällen und einem zu 
					diesem Zweck vorgenommenen Aderlass; er bittet ihn gar, den Zeitpunkt für die nächste Behandlung sowie die zu 
					entnehmende Blutmenge festzusetzen (<Link to="/brief/10454" target="_blank">Brief-ID 10454</Link>). Sulzer wiederum berät Marbach mit Blick auf sein 
					Podagra-Leiden und empfiehlt ihm die prophylaktische Verwendung von Terpentin, also des Harzausflusses von 
					Nadelbäumen, gegen seine Nierensteine (<Link to="/brief/20160" target="_blank">Brief-ID 20160</Link>).<br/><br/>
					Doch auch der Magen macht Marbach offenbar zu schaffen. Am 18. September 1570 
					(<Link to="/brief/19983" target="_blank">Brief-ID 19983</Link>) übermittelt ihm 
					Sulzer ein detailliertes Rezept für eine Brühe auf Honigbasis, die Magenschmerzen vorbeugen soll. Grundlage sind 
					nun nicht die verbreiteten Mischungen aus Honig und Wasser (Honigwein, Met) oder Honig und Essig (das seit der 
					Antike medizinisch eingesetzte Oxymel), sondern eine Kombination aus Honig, Wasser und Milch: Man nehme, so 
					schreibt Sulzer, zunächst ein Viertelmaß Honig und ebenso viel Wasser. Den Honig sollte man nicht bei Krämern 
					besorgen, sondern irgendwo bei Bauern, die ihn meist direkt aus den Bienenstöcken (<i>ex alvearibus</i>) entnehmen. 
					Das Wasser ist zunächst zu kochen – mit einer Unze Anissamen (<i>cum anysi uncia panniculo involuti</i>); es soll 
					dann in den Honig gegossen und dieser auf dem Feuer gekocht und abgeschäumt werden, bis das Wasser verkocht ist. 
					Sodann nehme man Molke aus Ziegenmilch (<i>serum … caprini lactis</i>), sofern verfügbar, oder aus Kuhmilch, worin 
					man Salbei, Majoran, Melisse und Thymian koche:<i> in eo bulliatur salvia, maiorana, melissa et thymus</i>. Wenn 
					dies fertig ist, gieße man einen Löffel voll Honig hinein. Als morgendliche Brühe, so schließt Sulzer, ist diese 
					Zubereitung nahrhaft und beugt Magenschmerzen vor.<br/><br/>
					Honig gilt als entzündungshemmend, antiseptisch und wundheilend; zeitgenössische Abhandlungen weisen zudem darauf 
					hin, dass er nicht nur nahrhaft und polyvalent verwendbar ist, sondern auch magenfreundlich. Konsultiert man 
					zudem das<i> Kreüter Buch </i>des Botanikers Hieronymus Bock (gest. 1554), zuerst gedruckt 1539 in Straßburg, 1546 
					dann erneut mit zahlreichen eindrücklichen Abbildungen (VD16 B 6016), dann scheint die Wahl der beizumischenden 
					Kräuter durchaus zielführend: Anis, heißt es dort,<i> heilet den magen</i>, Melisse hilft gegen<i> magen drucken </i>
					und Salbei, die<i> edelst Teütsch wurtz</i>, wirkt entgiftend,<i> ist dem gantzen jnnerlichen leib nutzlich </i>und 
					hilft gegen Durchfall.<br/><br/>
					<ImageBoxCentered width="385px">
						<img src="kreuterbuch.jpg" width="100%" alt="" />
						<Space/>Hieronymus Bock, Kreüter Buch, Straßburg 1546 (VD16 B 6016), Bl. XXr&nbsp;
						<Link to="//www.digitale-sammlungen.de/view/bsb11200232?page=81" target="_blank">(Augsburg SB, 2 Nat 26)</Link>
					</ImageBoxCentered><br/>
					Eine direkte Antwort Marbachs ist nicht überliefert. In seinem nächsten Brief an Marbach, geschrieben am 
					24. Oktober 1570 (<Link to="/brief/19964" target="_blank">Brief-ID 19964</Link>), deutet Sulzer aber an, dass es 
					diesem wieder besser geht. Sein Heilmittel scheint also gewirkt zu haben.<br/><br/>					
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juni 2024: Ein aufwändiger Weintransport</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>17. Dezember 1578, Reichenweier. Nikolaus Cancerinus an Johannes Pappus </b>
						(<Link to="/brief/91015" target="_blank">Brief-ID 91015</Link>)<br/>
					</Brieftitel>
					Gegen Ende des Jahres 1578 schrieb der Straßburger Theologieprofessor und Pfarrer Johannes Pappus einen Brief an 
					einen Kollegen, den Superintendenten Nikolaus Cancerinus in Reichenweier (Riquewihr). Dieser Brief ist leider nicht 
					erhalten. Wir wissen aber von ihm durch die ausführliche Antwort, die Cancerinus am 17. Dezember 1578 verfasste. 
					Pappus wollte Wein kaufen – offenbar nicht solchen aus der Straßburger Gegend, sondern von den Winzern rund um 
					Reichenweier – und hatte Cancerinus gebeten, sich darum zu kümmern.<br/><br/>
					Cancerinus sicherte daraufhin seinem Briefpartner die größtmögliche Sorgfalt zu. Doch bedurfte die Lieferung des 
					gewünschten Weines aus der oberelsässischen Gegend nach Straßburg erst noch aufwändiger Vorbereitungen, über die 
					Cancerinus in seiner Antwort detailliert Auskunft gibt. Zunächst waren nämlich dafür bestimmte Fässer nötig, über 
					die man in Reichenweier nicht verfügte. Deshalb sollte Pappus leere Fässer nach Reichenweier schicken und sich 
					dabei der Hilfe von Straßburger Kaufmännern (etwa der Familien Zorn oder Meisner) bedienen. Die Straßburger 
					Kaufleute sollten die Fässer auf dem Fluss Ill bis in das Dorf Illhäusern transportieren lassen. Von dort würden 
					sie auf dem Landweg von Einwohnern des Dorfes Gemar (Guémar) nach Reichenweier gebracht und mit dem Wein befüllt 
					zurücktransportiert, um schließlich mit dem Lastkahn auf der Ill zurück nach Straßburg zu gelangen.<br/><br/>
					Auch die Frage der Bezahlung wurde von Cancerinus angeschnitten. Der Reichenweierer Verwalter würde den Wein bar 
					bezahlen, doch hätte Pappus dafür den ihm ausgelegten Betrag dem Propst des Straßburger Predigerkollegs zu zahlen. 
					Sollte Pappus die leeren Fässer nicht schicken können, müsste er sich bis zum Monat April gedulden, denn dann würde 
					Cancerinus den Wein für die Fürstin versenden (war damit vielleicht Barbara von Hessen gemeint, eine Tochter des 
					Landgrafen Philipp I. von Hessen, die 1555 in Reichenweier den Grafen Georg I. von Württemberg-Mömpelgard geehelicht 
					hatte und ab 1568 in zweiter Ehe mit einem Grafen von Waldeck verheiratet war?). Cancerinus stellte in Aussicht, den 
					Wein für Pappus zusammen mit der Lieferung an die Fürstin zu schicken – was den Vorteil hätte, dass der Transport von 
					einem Bürger bewacht würde, sei es doch schon vorgekommen, dass die trunksüchtigen Schiffer die Weinfässer geleert 
					und mit Wasser befüllt hätten.<br/><br/>				
					<ImageBoxCentered width="385px">
						<img src="wein.jpg" width="100%" alt="" />
						<Space/>Weinhändler&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:Marchands_de_vin_XVe.jpg" target="_blank">(Wikimedia)</Link>
					</ImageBoxCentered>
					<br/>				
					<Unterschrift>Judith Steiniger</Unterschrift>					
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Mai 2024: Posthume Beschimpfungen</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="flacius.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/wiki/File:Matthias_Flacius_engraving.jpg?uselang=de" target="_blank">Matthias Flacius Illyricus (Quelle: Wikimedia)</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel>
						<b>25. Mai 1575, Tübingen. Jakob Andreae an Johannes Marbach </b> 
						(<Link to="/brief/21212" target="_blank">Brief-ID 21212</Link>)<br/>
					</Brieftitel>
					<i>De mortuis nil nisi bene </i>– an diese aphoristische Maxime hat sich der Tübinger Theologe Jakob Andreae (1528-1590) 
					zumindest in einem markanten Fall nicht gehalten. Als der umstrittene Lutheraner Matthias Flacius Illyricus am 11. März 1575 
					in Frankfurt am Main starb, bedachte ihn Andreae in mehreren Briefen mit wenig pietätvollen Worten.<br/><br/>
					Flacius war Zeit seines Lebens in heftige theologische Streitigkeiten verwickelt, besonders über den freien Willen und die 
					Erbsünde. Flacius zufolge gehört die Erbsünde untrennbar zum Wesen des Menschen – eine Position, die im lutherischen Lager 
					auf schärfste Kritik stieß. Andreae hatte im Rahmen seines ersten Konkordienversuchs in den Jahren 1569/70 noch – allerdings 
					vergeblich – um Flacius geworben. Am Verständnis der Erbsünde entzündete sich schließlich jedoch auch zwischen Andreae und 
					Flacius eine erbitterte Gegnerschaft; im August 1571 trafen sie sich in Straßburg zu einem ergebnislosen Gespräch, in den 
					Folgejahren verhärteten sich die Fronten. In mehreren Briefen klagte Andreae immer wieder über seinen Widersacher und dessen 
					Anhänger.<br/>
					Gegenüber Johannes Marbach in Straßburg etwa, wo Flacius über mehrere Jahre hinweg Asyl gewährt wurde, nennt Andreae Flacius 
					Ende 1574 einen<i> Sycophanta et perturbator nostrarum Ecclesiarum </i>– einen Verräter, Lügner oder Schmarotzer sowie Verwirrer 
					unserer Kirchen (<Link to="/brief/20813" target="_blank">Brief-ID 20813</Link>). 
					Nach Flacius’ Tod verschärft er den Ton: Am 23. April 1575 (<Link to="/brief/21042" target="_blank">Brief-ID 21042</Link>) schreibt er 
					Marbach, er danke Gott um der Kirche willen für den Tod Flacius’, der ein großer Unruhestifter gewesen sei:<i> Illyricum mortuum 
					esse, non mei, sed Ecclesiae causa ago Deo gratias; fuit enim homo turbulentissimus.</i><br/><br/>
					Am 25. Mai 1575 schließlich ist Andreaes Urteil, wieder gegenüber Marbach, noch radikaler: Zunächst stichelt er 
					gegen die in Kursachsen verhafteten Zwinglianer und berichtet von einer Schrift gegen die Zürcher Theologen, die ihn angegriffen 
					hatten. Von<i> Schelmen </i>gescholten zu werden, so beteuert Andreae, empfinde er als Ehre. Zu diesen gehöre auch sein 
					Intimfeind Flacius, der des Teufels sei (<i>meus, seu potius Diaboli, Illyricus</i>), ja mehr noch, der nun zweifellos mit allen 
					Teufeln speise (<i>quem non dubito nunc cum omnibus Diabolis caenare</i>). Neulich habe er zudem seinem Landesherrn, Herzog 
					Ludwig von Württemberg, einen abgeschriebenen Brief des Flacius gezeigt; dieser verabscheue Flacius’ Gottlosigkeit und gratuliere 
					der Kirche zu dessen Tod (<i>qui eius impietatem detestatur et Ecclesiae Dei propter huius perturbatoris mortem gratulatur</i>).
					<br/><br/>
					Die unerbittliche Schärfe, mit der sich Andreae über den toten Flacius äußert, mag überraschen, ist aber auch ein Indiz dafür, 
					dass der Tübinger den kontroversen Illyricus womöglich als ernsthaftes Hindernis auf dem Weg zu einer lutherischen 
					Einigungsformel betrachtete – und als permanenten Unruheherd, dessen Tod ihm kaum Mitgefühl abnötigte.<br/>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>April 2024: Wohnraum – Brennholz – Hausrat: Das Tübinger Stift zieht um</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>19. August 1571, Stuttgart. Herzog Ludwig von Württemberg an Superattendenten und Magister Domus des Tübinger Stifts </b> 
						(<Link to="/brief/73968" target="_blank">Brief-ID 73968</Link>)<br/>
					</Brieftitel>
					Nicht nur in Heidelberg grassierte im 16. Jahrhundert häufig die Pest, wie der Brief des Monats März 2024 belegt, sondern 
					auch Tübingen wurde mehrfach von Epidemien heimgesucht, die sowohl die Universität als auch das Stift – die theologische 
					Ausbildungsstätte begabter Landeskinder, – regelmäßig dazu zwangen, die Stadt zu verlassen. Anlässlich des Pestausbruchs 
					1566 war das Stift in die rund 50 km entfernt gelegene kleine Reichsstadt Esslingen am Neckar ausgewichen. 1571 wiederholte 
					sich dieser Auszug. Wie aufwendig die Logistik war, wenn eine universitäre Institution den Ort wechselte, geht aus einem Brief 
					hervor, den Herzog Ludwig von Württemberg im August 1571 an die Verantwortlichen des Stifts sandte, namentlich an Johannes Brenz 
					d.J. und Jakob Heerbrand als die beiden Superattendenten sowie an Samuel Heiland als Magister Domus.<br/><br/>
					Zunächst einmal mussten Brenz, Heerbrand und Heiland selbst eine Unterkunft in Esslingen finden und so hatte der Herzog dafür 
					gesorgt, dass Brenz und Heiland zwei<i> behausungen </i>zur Verfügung gestellt wurden. Um die hohen Mietkosten des für Samuel Heiland 
					vorgesehenen Objekts zu verringern, wurde ihm erlaubt, die dritte Stube unterzuvermieten, und zwar an den Sohn des 
					Kirchenratsdirektors Johannes Entzlin. Herzog Ludwig hatte den Abt des Klosters Salem gebeten, Jakob Heerbrand im Salemer Pfleghof unterzubringen, wo dieser bereits bei 
					der früheren Pestepidemie 1566 gewohnt hatte. Er war zuversichtlich, der Abt werde die Unterkunft<i> guetwillig darleihen</i>.
					Auch wegen des Brennholzes, mit dem die Räumlichkeiten des Stifts in Esslingen beheizt werden sollten, waren bereits Vorkehrungen 
					getroffen worden. Brenz, Heerbrand und Heiland sollten angeben, wie viele Wagen sie für den Transport von Feuerholz benötigten.<br/>
					Um Aufwand und Kosten zu sparen, wurde ihnen angeboten,<i> haußrath vnnd kuchingeschirr </i>des nahegelegenen Dominikanerinnenklosters 
					Weiler zu benutzen. Das Kloster wurde im Zuge der Reformation aufgelöst, der Konvent bestand nur noch aus wenigen Schwestern und 
					der Hausrat wurde nach und nach entbehrlich. Der Hofmeister war angewiesen worden, ihnen Geschirr und Geräte zu zeigen und ein 
					Verzeichnis von den Dingen zu erstellen, die ihnen nützlich sein könnten. Ferner sollte er ihnen auch<i> ein fuoder strow oder zwey 
					zu füllung der stro seckh </i>zukommen zu lassen.<br/><br/>
					Den Stipendiaten,<i> in derenn patria noch guetter lufft </i>war, sollte erlaubt werden, in den Herbstferien in ihre Heimat zu fahren, 
					sich von dort aber nicht in andere Gegenden zu begeben,<i> da pestis </i>regiert. Schließlich sollten die Stipendiaten an Crucis – am 
					14. September – für das Wintersemester wieder ins Tübinger Stift nach Esslingen zurückkehren.<br/>					
					<ImageBox>
						<img src="esslingen2.jpg" width="100%" alt="" />
						<Space/>Stadtansicht von Esslingen, 1685&nbsp;
						<Link to="//www.landesarchiv-bw.de/plink/?f=1-513175-1" target="_blank">(Stuttgart HSA, H 107/15, Bd. 7, Bl. 22)</Link>
					</ImageBox>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>März 2024: Notmaßnahmen zur Zeit der Pest in Heidelberg</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>13. Januar 1564, Lohrbach. Kurfürst Friedrich III. von der Pfalz an den Heidelberger Rat und die kurpfälzischen Kirchenräte </b> 
						(<Link to="/brief/81225" target="_blank">Brief-ID 81225</Link>)<br/>
					</Brieftitel>
					Mitteleuropa wurde zwischen 1347 und 1667 immer wieder von Pestepidemien heimgesucht. In den betroffenen Gegenden fiel 
					dieser Seuche oft die Mehrzahl der Bevölkerung zum Opfer. Die Pestinfektion wird durch ein Bakterium, das Flöhe von Ratten 
					auf Menschen übertragen, ausgelöst. Diese Tatsache war den Menschen damals nicht bewusst. Übertragen werden kann die Infektion 
					zudem von Mensch zu Mensch (Tröpfcheninfektion). Diese Art der Übertragung war im Bewusstsein. Man schützte sich vor ihr durch 
					starke Duftstoffe und auch Verhüllung des Körpers. Ärzte schützten sich auch mit Masken; sie wurden deshalb „Schnabeldoktoren“ 
					genannt.
					<br/><br/>
					<ImageBoxCentered width="385px" >
						<img src="baselhmniemz.jpg" width="100%" alt="" />
						<Space/>Basler Arzt mit Pestmaske (wohl Theodor Zwinger d. J., 1569-1610). Ausschnitt aus dem Gemälde auf Kupfer, [1610?], Basel HM, Inv. 1904.45 (Foto Andreas Niemz)
					</ImageBoxCentered>
					<br/>
					Die häufigste Auswirkung der Infektion ist das Anschwellen der Lymphknoten, die sich mit Eiter füllen (Beulenpest); seltener ist 
					die schneller eintretende, fast unweigerlich zum Tod führende Infektion der Lunge (Lungenpest).
					Die Epidemie griff in rasanter Geschwindigkeit um sich, vor allem in den Städten und hier bei der zumeist dicht beieinander 
					wohnenden ärmeren Bevölkerung. Wer dazu in der Lage war, wählte angesichts der Bedrohung durch den „schwarzen Tod“ einen radikalen 
					Ausweg: die Flucht aus der Stadt. Der Nürnberger Arzt und Schriftsteller Hans Folz gab hierzu 1482 die Empfehlung „Fleuch pald, 
					fleuch ferr, kum wieder spot! Das sind drei krewter in der not.” Die vor der Pest fliehenden Menschen trugen zur schnellen 
					Verbreitung der Epidemie über große Entfernungen wesentlich bei.<br/><br/>
					Die oberrheinischen Gebiete wurden im 16. Jahrhundert im Durchschnitt etwa alle 13 Jahre von der Pest heimgesucht, besonders 
					heftig in den Jahren 1501–1503, 1550–1553, 1563–1565 und 1595/97.
					Im Winter 1563/64 wütete die Pest in Heidelberg mit größter Vehemenz. Die Bestattung der zahlreichen Leichen auf dem hartgefrorenen 
					Friedhof rund um die Peterskirche war eine schier nicht mehr zu bewältigende Aufgabe.
					Die Universität Heidelberg hielt sich vom September 1563 bis zum März 1564 in Oppenheim auf, Kurfürst Friedrich III. mit seinem 
					Hofstaat von August 1563 bis April 1564 in seinem Wasserschloss Lohrbach (6 km nördlich von Mosbach).<br/><br/>In seinem Brief vom 
					13. Januar 1564 rief der Kurfürst den Behörden in Erinnerung, die Stadt rein zu halten und ausreichend tiefe Gräber auszuheben, 
					um die weitere Ausbreitung der Seuche zu verhindern. Er warnte vor der Gefahr, die Seuche könne sich im nächsten Sommer durch den 
					Gestank der Leichen und die dadurch vergiftete Luft weiter ausbreiten. Als Notmaßnahme sollte der ganze Kirchhof zu St. Peter drei 
					oder vier Schuh hoch mit Erde aus der oberhalb liegenden Steingrube beschüttet werden. Um die Zufuhr der Erde zu erleichtern, sollte 
					die Kirchhofmauer an einer Stelle zur Einrichtung eines Weges aufgebrochen werden. Durch diese Maßnahmen erhoffte sich der Kurfürst 
					einen Rückgang der Seuche und in der Folge seine Rückkehr nach Heidelberg. Er bat um Bericht über den Vollzug dieser Maßnahmen. 
					Die anfallenden Kosten wollte er erstatten.<br/>
					<Unterschrift>Thomas Wilhelmi</Unterschrift>			
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Februar 2024: Deutsch als Zweitsprache im 16. Jahrhundert</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>14. Juli 1559, London. Edmund Grindal an Konrad Hubert </b> 
						(<Link to="/brief/17475" target="_blank">Brief-ID 17475</Link>)<br/>
					</Brieftitel>
					Auslandsaufenthalte zum Zweck des Fremdsprachenerwerbs sind für angehende Wissenschaftler heute nicht nur nichts 
					Ungewöhnliches, sondern werden in akademische Lebensläufe häufig bewusst eingeplant. Hinter Edmund Grindals (1520-1583) 
					Erlernen der deutschen Sprache stand jedoch kein gutüberlegter Fortbildungswunsch, sondern eine erzwungene Flucht, um 
					sein Leben zu retten. Der ehemalige Kaplan Edwards VI. und Bischof von London musste infolge des Machtantritts Maria 
					Tudors, der „blutigen Maria“, nach Straßburg fliehen, wo er im August 1554 eintraf. Somit blieb ihm das Schicksal 
					prominenter Protestanten wie Hugh Latimer, Nicholas Ridley oder Thomas Cranmer, die unter der Herrschaft Marias 
					hingerichtet wurden, erspart. Während seines Straßburger Exils schottete sich Grindal nicht in einer akademischen Blase ab, 
					sondern bemühte sich aktiv darum, die Sprache seiner Gastgeber zu erlernen. In der Rückschau attestierte ihm Konrad Hubert, 
					die deutsche Sprache während seines Exils mit derartigem Erfolg erlernt zu haben, dass er sich beim elsässischen 
					Kirchenvolk Anerkennung und Beliebtheit als Prediger verschaffte.<br/><br/>
					Grindal wollte seine Deutschkenntnisse auch nach seiner Rückkehr nach England im Januar 1559 nicht einfach in 
					Vergessenheit geraten lassen, wie ein Brief an Konrad Hubert zeigt.  Darin klagt er über eine Art „Fachkräftemangel“ in 
					England und fleht Hubert an, ihm aus seinem Bekanntenkreis einen Sekretär zu besorgen. Dieser soll Latein, Griechisch, 
					vor allem aber Hebräisch können, ein eifriger Bibelleser sein und eine lesbare Handschrift haben. 
					<ImageBox>
						<img src="bdm17475.jpg" width="100%" alt="" />
						<Space/><i>vnd das die geschrifft zimlich gut were </i>– Der Londoner Bischof Edmund Grindal lässt es sich nicht nehmen, 
						ein paar Brocken Deutsch in seinen lateinischen Brief einzustreuen.&nbsp;(Straßburg StA, 1 AST 157, S. 481)
					</ImageBox>
					<br/>
					Für seine Schreibtätigkeit werde der Betreffende ein ansehnliches Gehalt, bezahlten Heimaturlaub, Englischunterricht und 
					zwei Garnituren Kleidung „englischer Art“ (<i>duo Anglico more vestimenta</i>) erwarten können.	Den entscheidenden Grund, 
					weshalb Grindal derart kostspielige Anstrengungen unternimmt, um einen Sekretär aus dem Elsass 
					zu bekommen, verrät er erst am Schluss seines Briefes: „Weil ich nicht bereit bin, eure Sprache völlig zu verlernen“ 
					(<i>Quia vestram linguam prorsus dediscere nollem</i>). Die teure Fachkraft sollte Grindal also neben ihrer Tätigkeit als 
					Sekretär auch als deutschsprachiger Gesprächspartner dienen, und Grindal war folglich bereit, sich die Pflege von Deutsch 
					als Zweitsprache etwas kosten zu lassen.<br/>
					Seine Suche wurde mit Erfolg gekrönt, denn wenig später wurde Diethelm Blarer, Sohn des exilierten Konstanzer Ratsherrn 
					Thomas Blarer, als Sekretär angestellt. Grindals Traum vom deutschsprachigen Sekretär war Wirklichkeit geworden.<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>	
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Januar 2024: Heidelberg – ein<i> locus horribilis </i>für Lutheraner</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>28. Februar 1579, Heidelberg. Petrus Patiens an Matthias Ritter d. J. in [Frankfurt am Main] </b> 
						(<Link to="/brief/83494" target="_blank">Brief-ID 83494</Link>)<br/>
					</Brieftitel>
					Die Kurpfalz war das erste Territorium im Reich, das unter Kurfürst Friedrich III. den reformierten Glauben angenommen 
					und sich in der Folgezeit zu einer Bastion des „Calvinismus“ entwickelt hatte. Die kurze Periode der Restauration des 
					Luthertums unter Kurfürst Ludwig VI., der von 1576 bis 1583 regierte, konnte die Dominanz des Reformiertentums, vor allem 
					in der Stadt Heidelberg, nicht endgültig beseitigen. In dieser Zeit wirkten in Heidelberg lutherische Prediger gegen 
					erheblichen Widerstand und oft auch widerwillig. So mancher Geistliche war froh, wenn er die Pfalz wieder verlassen 
					durfte, oder lehnte einen Ruf des Kurfürsten von vornherein dankend ab. Für Lutheraner war Heidelberg also ein schwieriges 
					Pflaster.<br/><br/>
					Petrus Patiens (1530–1584) war einer der Prediger an der Heiliggeistkirche, der zunächst aus Frankfurt „entliehen“ worden 
					war und von Kurfürst Ludwig VI. überredet werden musste, nicht wieder zu gehen und schließlich auch die Stelle des 
					Generalsuperintendenten anzunehmen. Die Entscheidung dürfte ihm nicht leicht gefallen sein. In einem Brief an seinen 
					ehemaligen Frankfurter Kollegen Matthias Ritter vom 28. Februar 1579 beschreibt er zunächst, wie die Calvinisten in 
					Heidelberg damit prahlten, dass sie durch ihre Gebete bereits zwei lutherische Prediger ins Jenseits befördert 
					hätten:<i> quod Calvinistae glorientur se precibus suis Praepositum et Stoltzium e vivis removisse. </i>Mit dem Propst ist 
					der Stuttgarter Theologe Balthasar Bidembach (1533–1578) gemeint, der gleich nach der Regierungsübernahme Ludwigs VI. 
					leihweise aus Württemberg nach Heidelberg auf die Kanzel der Heiliggeistkirche gekommen war. Bald nach seiner Rückkehr aus 
					der Pfalz erkrankte er – wie andere Mitglieder seiner Familie – an Melancholie und starb im August 1578. Dies werteten die 
					Heidelberger Reformierten als Sieg auf ganzer Linie. Auch den Tod des Heidelberger Generalsuperintendenten Andreas Stoltz 
					im Mai 1578 schlachteten sie mit antilutherischer Polemik aus. Patiens, dessen Nachfolger, musste diese Art 
					am eigenen Leib erfahren:<i> se [sc. Calvinistas] libenter me remissuros Francofurtum aut certe, si maneam, me quoque precibus 
					suis sublaturum: zu tod beten. </i>Eine nicht gerade fromme und christliche Praxis des Betens, die die Reformierten ihm 
					androhten!<br/><br/>
					Einer unter den Heidelberger Calvinisten, der solches bis nach Frankfurt zu Ritter verbreitete, war der Buchbinder Jodocus 
					(Jost) Zimmermann. Dessen Blasphemie war stadtbekannt, wie Patiens zwischen den Zeilen verrät: Die Universität habe ihn 
					bereits ausgeschlossen und der Stadtrat verweigere ihm das Bürgerrecht. Während einer Predigt, die Patiens hielt, habe 
					besagter Buchbinder gegen ihn gezetert (<i>in quadam concione mea expuerit contra me</i>) und beim Verlassen der 
					Heiliggeistkirche gesagt:<i> hat mich der Teuffel heut mit Narren beschissen. </i>Diese Vorfälle konnten nicht folgenlos 
					bleiben, und so wurde der Buchbinder vor den Kirchenrat zitiert. Dort verleugnete er alles, bis der Frankfurter Prediger 
					Ritter als Gewährsmann namentlich genannt wurde –<i> tunc obmutuit</i>. Als Konsequenz erhielt Ritter für sein Denunzieren zu 
					seinem Verdruss (<i>cum stomacho</i>) ein „freundliches“ Schreiben vom Buchbinder aus der Pfalz.<br/>
					<ImageBox>
						<img src="hdaltstadt.jpg" width="100%" alt="" />
						<Space/>Ausschnitt aus einem Gemälde des flämischen Malers Jacques Fouquières (1620) mit Blick vom Schloss auf die Heidelberger Altstadt, die Heiliggeistkirche, die Barfüßerkirche sowie die Alte Brücke.&nbsp;
						<Link to="//upload.wikimedia.org/wikipedia/commons/3/35/Hortus_Palatinus_und_Heidelberger_Schloss_von_Jacques_Fouquiere.jpg" target="_blank">(Quelle: Wikimedia)</Link>
					</ImageBox><br/>
					<Unterschrift>Marcel Böhme</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Dezember 2023: Streit zwischen „Town und Gown“ in Heidelberg</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>5. September 1586, [Heidelberg]. Daniel Tossanus d. Ä. an Johann Jakob Grynaeus </b> 
						(<Link to="/brief/11017" target="_blank">Brief-ID 11017</Link>)<br/>
					</Brieftitel>
					Universitäten entstanden im Mittelalter als Körperschaften von Professoren und Studenten<i> (universitates magistrorum 
					et scholarium) </i>und erlangten im Laufe der Zeit eine selbständige akademische Gerichtsbarkeit. Diese juristische 
					Selbstverwaltung führte nicht selten zu Spannungen mit der nichtakademischen Bürgerschaft der Städte, in denen die 
					Universitäten angesiedelt waren. In der angelsächsischen Geschichtsforschung wird diese strukturell angelegte 
					Gegnerschaft mit dem Begriffspaar „Town and Gown“ – letzteres in Anspielung auf die Talare, die die Studenten im 
					Mittelalter trugen – bezeichnet.<br/><br/>
					Genau einen solchen Konflikt beschreibt der Heidelberger Theologieprofessor Daniel Tossanus (1541-1602) in einem Brief 
					an seinen Basler Freund Johann Jakob Grynaeus (1540-1617) vom 5. September 1586: <br/>
					<i> Captus erat nebulo quidam, qui ante triennium studiosus hic fuerat, 
					et a Schulteto sive praetor urbis propter blasphemias in Deum et in Principem prolatus et effusus dum forte per suburbium 
					iter haberet, comprehensus et in carcerem missus. Eum sibi Rector vendicans, quod iuxta privilegia studiosi per quinquennium, 
					etiamsi discedant, privilegiis gaudere debeant, in Carcerem Academicum dederi curarat.</i>
					<ImageBox>
						<img src="bdm11017.jpg" width="100%" alt="" />
						<Space/>Basel UB, G II 12, S. 105
					</ImageBox>
					<br/>
					Ein „Taugenichts“, der vor drei Jahren in Heidelberg studiert hatte, sei auf der Durchreise wieder am Neckar erschienen und 
					aufgrund seiner Lästerung Gottes und des Fürsten – des kurfürstlichen Administrators Johann Casimir – verhaftet worden. Da 
					aber Studenten noch fünf Jahre nach ihrem Weggang akademische Privilegien – etwa die ausschließliche Verantwortung vor 
					universitären Gerichten – genossen, sorgte der Rektor der Universität für Überstellung des „Taugenichts“ aus dem Stadtgefängnis 
					in den Karzer der Universität. Dies erzürnte den kurpfälzischen Hofgerichtsrat Hartmann Hartmanni d. J. (1523-1587) derart, 
					dass er den Universitätskarzer aufbrach und den Studenten in das fürstliche Gerichtsgefängnis bringen und von bewaffneten 
					Bürgern bewachen ließ.<br/>					
					Nun war eine Situation entstanden, in der einerseits aufgebrachte Studenten die Anerkennung der akademischen Privilegien 
					des Gefangenen und somit dessen Freilassung erzwingen wollten, während sich andererseits empörte Stadtbürger bewaffneten 
					und über die Studenten herfielen, ja sogar einen von ihnen töteten.	Konfessionelle Rivalitäten verstärkten den Konflikt: 
					Viele Stadtbürger verstanden sich als Anhänger des Luthertums und verunglimpften alle Studenten pauschal als „Calvinianer“. 
					Tossanus selbst musste erleben, wie er am Tag, nachdem er seinen Brief an Grynaeus geschrieben hatte, von einem bewaffneten 
					Bürger vor seinem Haus bedroht wurde, der ihn anschrie:<i> Veni, tu krützlosen Calvinisc[h]en wältschen pfaf! </i><br/><br/>
					Erst 1587 konnte der Konflikt durch ein Machtwort des Kuradministrators Johann Casimir beendet werden, der die<i> gänzliche 
					erörterung und hinlegung deß […] zwischen der universität […] und der bürgerschaft […] entstandenen mißverstands und 
					unruhe </i> anordnete.<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>November 2023: Alchemie in Straßburg</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>30. Januar 1571, Straßburg. Johannes Pappus an Philipp Marbach </b> 
						(<Link to="/brief/20075" target="_blank">Brief-ID 20075</Link>)<br/>
					</Brieftitel>
					Hören wir das Wort „Alchemie“, erscheinen unwillkürlich geheimnisvolle chemische Experimente vor unserem geistigen Auge, bei 
					denen in früheren Jahrhunderten versucht wurde, Edelmetalle, vor allem Gold, herzustellen und den<i> lapis philosophorum</i>, 
					den „Stein der Weisen“, zu finden. Dabei ging es in der alten Tradition der Alchemie nicht nur um die Kunst des Goldmachens. 
					Vielmehr sollten chemische Prozesse mit der spirituellen Entwicklung und Perfektionierung des Alchemisten verbunden werden. 
					Über das Mittelalter hinaus lassen sich die Einflüsse alchemistischer Lehren bis weit hinein in die Neuzeit verfolgen. So 
					zeigt Johann Wolfgang von Goethe, der sich schon in seiner Straßburger Zeit mit dem „Faust“-Stoff beschäftigt hatte, in 
					der gleichnamigen Tragödie, wie dem verzweifelten Faust auf Geheiß Mephistos von der Hexe ein Zaubertrank gereicht wird, 
					der ihn verjüngt und ihm neue Kräfte verleiht. Mit diesem Trank war wohl das alchemistische Elixier namens Trinkgold 
					(<i>aurum potabile</i>) gemeint, wie in der Forschung vermutet wird.<br/><br/>
					Manch ein Gelehrter im 16. Jahrhundert wandte alchemistische Produkte an. So erfahren wir aus einem Brief des Straßburger 
					Theologieprofessors Johannes Pappus an den jungen Magister Philipp Marbach vom 30. Januar 1571, dass der kürzlich verstorbene 
					Straßburger Physikprofessor Johannes Bruno für längere Zeit ein<i> aurum solutum oder aurum potabile </i>zu sich genommen und 
					auch seiner früheren Ehefrau verabreicht hätte. Interessanterweise tritt nun in unserem Theologenbrief noch ein Vergleich hinzu, 
					den Johannes Bruno kurz vor seinem Tod zwischen der Wirkung des<i> aurum potabile </i>und der Heiligen Schrift angestellt haben 
					soll. Das Gold, das den Leib heilen sollte, sei zu schwach, als dass es helfen könnte. Insofern sei dieses Gold der Heiligen 
					Schrift ähnlich: Es macht den, der es trinkt, wieder gesund oder wird, wenn es nicht hilft, gänzlich wieder ausgeschieden. 
					Genauso könne das Wort Gottes in einem sorglosen, verlorenen Herzen sein Ziel nicht erreichen, aber in einem gut vorbereiteten 
					Herzen sei das Heil gewiss. Bruno habe, so Pappus, kurz vor seiner Erkrankung die Bibel gelesen, ohne dass sie eine große 
					Wirkung auf ihn gehabt hätte, was seine Schuld gewesen sei. Doch jetzt habe er in seiner unglücklichen Lage den Trost begierig 
					angenommen und die wunderbare Wirkung des ewigen Heils gespürt.<br/><br/>
					Trinkgold wurde im 16. Jahrhundert häufig als Arznei verwendet. Jedoch waren nicht alle Gelehrten von der Wirksamkeit 
					alchemistischer Methoden überzeugt, wie wir aus anderen Briefen, etwa denen des Arztes Thomas Erastus, entnehmen können.<br/>
					<ImageBox>
						<img src="alchemist.jpg" width="100%" alt="" />
						<Space/>Ein Alchemist in seinem Labor. Gemälde eines Schülers David Teniers d. J.&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:An_alchemist_in_his_laboratory._Oil_painting_by_a_follower_o_Wellcome_L0018022.jpg" target="_blank">(Wikimedia)</Link>
					</ImageBox>
					<Unterschrift>Judith Steiniger</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Oktober 2023: Plumpsklo-Konflikte</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>9. Juli 1549, Jena. Victorinus Strigel an Erhard Schnepf </b> 
						(<Link to="/brief/93454" target="_blank">Brief-ID 93454</Link>)<br/>
					</Brieftitel>
					Bisweilen kommen auch in Theologenbriefen unverblümt ganz und gar weltliche Themen zur Sprache. So gewährt etwa ein Brief 
					Victorinus Strigels (1524-1569) an seinen Jenaer Professorenkollegen und zukünftigen Schwiegervater Erhard Schnepf (1495-1558) 
					ungewöhnliche Einblicke in die frühneuzeitliche Wohnungssuche – und bemerkenswerte Ausstattungswünsche. Strigel hatte offenbar 
					in Schnepfs Auftrag mit dem Vermieter einer Wohnung, in die dieser mit seiner Familie zu ziehen erwog, verhandelt. Am 9. Juli 
					1549 berichtet Strigel darüber – er hatte u. a. einen von Schnepf geäußerten Wunsch angesprochen, nämlich die Einrichtung eines 
					Aborts:<i> egimus […] de aedificando loco reliquiarum cibi et potionis.</i><br/>
					Strigel wird sogar noch konkreter: <i> Novam sedem, in quam reliquiae sordium abiiciantur, ita se aedificaturum pollicetur, ut 
					tuae voluntati satisfaciat, etiamsi propter vitandam ἁψιμαχίαν cum vicino illud alterum, de quo a principio disserebas, omittat. </i>
					Der Vermieter habe also, so Strigel, versprochen, dass er – wie von Schnepf gewünscht – einen ‚Toilettensitz‘ bauen würde, durch 
					den die Exkremente ‚entsorgt‘ werden können. Um Auseinandersetzungen mit dem Nachbarn zu vermeiden, werde er auf ein zweites von 
					Schnepf geäußertes Anliegen jedoch nicht eingehen. Was könnte mit diesen Anspielungen gemeint sein?<br/><br/>
					Seit dem Spätmittelalter waren zunächst in Burgen, dann auch in Städten sog. Aborterker verbreitet, also Erker oder Nischen, in 
					denen sich ein Sitz befand. Bei deren Benutzung fielen die Exkremente häufig einfach ins Freie, etwa in eine wenig begangene 
					Gasse oder einen Hinterhof, einen Fluss, bisweilen auch in einen Kanal, der in einen Graben geleitet wurde. Vielleicht 
					befürchtete Schnepfs potentieller Vermieter einen Konflikt mit dem Nachbarn aufgrund der Art und Weise, wie das Abwasser 
					gesammelt oder entsorgt werden sollte – und der damit verbundenen Geruchsbelästigung.<br/>
					Tatsächlich fanden sich in mittelalterlichen und frühneuzeitlichen Städten – anders als im antiken Rom, wo es mit der Cloaca 
					Maxima ein ausgefeiltes Abwasserkanalsystem gab – in der Regel Sickergruben, entweder unter Plumpsklos oder als Sammelbecken 
					für Abwasser. Dass dies tatsächlich zu unangenehmen Situationen führen konnte, belegt ein Brief des Matthias Flacius Illyricus 
					an Johannes Flinner (Brief-ID 28192): Er habe bemerkt, so klagt Flacius, dass aus der Fäkaliengrube des Nachbarhauses ein 
					ekelhafter Gestank in das Zimmer ziehe, das er bewohnte (<i>me deprehendisse tetros fetores exhalare ex cloaca […] recte in 
					illud hypocaustum, quod ipse incolere deberem</i>). Dabei musste er, weil seine Familie so groß ist, häufig die Tür öffnen. Der 
					Fäkaliengestank war so unerträglich, dass Flacius, wie er Flinner versichert, keine andere Wahl hatte, als sich eine neue Wohnung 
					zu suchen.<br/><br/>
					<ImageBoxCentered width="385px" >
						<img src="bdm93454.jpg" width="100%" alt="" />
						<Space/>Aborterker an Zürcher Häusern, Ausschnitt aus dem Murerplan von 1576&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:Abtritterker_Murer.jpg" target="_blank">(Wikimedia)</Link>
					</ImageBoxCentered>
					<br/>
					<ItemContainer>
					Näheres zum Briefwechsel Victorinus Strigels mit Erhard und Dietrich Schnepf bietet:<br/>
					Max Graff: Familie und Konfession. Zu Victorinus Strigels Briefen an Erhard und Dietrich Schnepf, in: Südwestdeutsche 
					Theologenbriefwechsel. Kirchen- und kulturgeschichtliche Einblicke, hg. v. Marcel Böhme, Max Graff und Judith Steiniger, 
					Heidelberg 2023 (Heidelberger Forschungen 48), S. 137-159.
					</ItemContainer>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>			
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>September 2023: Die Vorzüge Londons als Studienort</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>25. März 1590, [Heidelberg]. Daniel Tossanus d. Ä. an Graf Ludwig I. von Sayn zu Wittgenstein </b> 
						(<Link to="/brief/24863" target="_blank">Brief-ID 24863</Link>)<br/>
					</Brieftitel>
					Der aus Frankreich stammende, seit 1573 in kurpfälzischen Diensten stehende reformierte Pfarrer und Theologe Daniel Tossanus d. Ä. (1541-1602) 
					unterhielt Zeit seines Lebens einen regen Briefwechsel mit Graf Ludwig I. von Wittgenstein, der selbst dem pfälzischen Kurfürsten mehrfach als 
					Hofmeister gedient hatte. In einem dieser Briefe an seinen Freund spricht Tossanus die geplante italienische Studienreise von Ludwigs Sohn 
					zunächst vorsichtig an, geht aber dann einen Schritt weiter und rät ihm offen davon ab, seinen Sohn nach Italien zu schicken. Interessanterweise 
					führt Tossanus keine Argumente gegen Italien an, sondern ergeht sich in überschwänglichem Lob für einen anderen weltläufigen, kulturell blühenden 
					Ort, der sich wie kein anderer in Europa für ein Studium eigne: London. Tossanus lobt die englische Königin Elisabeth I. als „ein Wunder unserer 
					Zeit“ (<i>miraculum nostri temporis</i>) und zeichnet ein bestechendes Bild ihres Königreiches und dessen Hauptstadt, die er als unübertroffen 
					kosmopolitisch beschreibt: London sei zu einem „Schauplatz unserer christlichen Welt“ geworden und Ludwigs Sohn werde dort genauso gut Französisch 
					oder Italienisch lernen können wie in Frankreich oder Italien selbst.<br/>
					<ImageBox>
						<img src="bdm24863.jpg" width="100%" alt="" />
						<Space/><i>Londinum est quasi theatrum hodie orbis christiani, et ibi lingua Gallica et Italica disci potest, ut in Gallia aut Italia.</i>&nbsp;
						<Link to="//nbn-resolving.org/urn:nbn:de:urmel-ufb-158166" target="_blank">(Gotha FB, Chart. A 129, fol. 4r)</Link>
					</ImageBox>
					<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>August 2023: Andere Länder, andere Sitten – Französische Studenten in Tübingen</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>[nicht nach 2. Dezember 1575], [Tübingen]. Carolus de Wacongne, Ludovicus Mustel, Antonius Dormalius, Caesar Sena und Petrus Durandus an [Georg Hamberger], Rektor der Universität Tübingen </b> 
						(<Link to="/brief/72990" target="_blank">Brief-ID 72990</Link>)<br/>
					</Brieftitel>
					Für Studierende ist der Vorgang eine Selbstverständlichkeit: die Immatrikulation. Mit diesem Wort wird zunächst einmal die Einschreibung in die Matrikel, 
					das Personenverzeichnis der Universität, bezeichnet, die heute auf digitalem Wege erfolgt. Außerdem gibt es den Festakt der feierlichen Immatrikulation, 
					bei der die neuen Studierenden an ihrer Universität willkommen geheißen werden. Die Immatrikulation ist eine Voraussetzung für das zu absolvierende Studium 
					und für die Erlangung akademischer Grade. Seit dem späten Mittelalter wurden für den Vorgang des Inskribierens Bücher benutzt, in die unter Angabe des 
					jeweiligen Datums die Namen der Studenten und ihr Herkunftsort eingetragen wurden. Dies geschah lange Zeit überwiegend auf Lateinisch – der damaligen 
					Wissenschaftssprache, in der man auch die Lehrveranstaltungen abhielt. Bei der Einschreibung leisteten die angehenden Studenten einen Eid und entrichteten 
					in der Regel eine Gebühr, wenn sie nicht so arm waren, dass mitunter zu ihren Namen der Zusatz „nihil, p[auper]“ hinzugesetzt wurde. Dies bedeutete, dass 
					der betreffende Student aufgrund seiner Mittellosigkeit nichts entrichten musste.<br/><br/>	
					Jedoch waren diese Einschreibungen nicht für alle Studenten in Europa gleichermaßen ein bekanntes, übliches Prozedere. So wandten sich im Jahr 1575 fünf 
					Studenten, die aus Frankreich zum Studium der Artes und der deutschen Sprache nach Tübingen gekommen waren, mit einem Brief an Georg Hamberger, den Rektor 
					der Tübinger Universität. Darin teilten sie mit, dass sie in der Annahme, die Tübinger Universität sei wie die französischen Akademien organisiert, sich aus 
					Unkenntnis des hiesigen Brauches noch nicht eingeschrieben hätten. Da sie nun davon gehört hätten, bitten sie den Rektor, ihre Entschuldigung anzunehmen und 
					ihre Namen aufzunehmen.	Aus dieser Erklärung wird ersichtlich, dass der Brauch der Immatrikulation bzw. die Institution von Matrikeln nicht überall in Europa in gleicher Dichte 
					verbreitet war. Es handelte sich im Wesentlichen um ein deutsches Phänomen, das mit der Ausprägung eines spezifisch landesfürstlichen Universitätsmodells 
					einherging und sich von den Universitäten in Süd- und Westeuropa unterschied.<br/><br/>
					Vier der oben genannten französischen Studenten in Tübingen wurden schließlich am 2. und am 6. Dezember 1575 in die Tübinger Matrikel eingeschrieben:
					<ImageBox>
						<img src="bdm72990.jpg" width="100%" alt="" />
						<Space/>Matricula Universitatis, Band IV, 1564–1585&nbsp;
						<Link to="//idb.ub.uni-tuebingen.de/opendigi/UAT_005_27#p=82" target="_blank">(Tübingen UA, UAT 5/27, fol. 36v-37r)</Link>
					</ImageBox>
					<br/>
					<Unterschrift>Judith Steiniger</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juli 2023: Melanchthons Träume</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>22. August 1546, [Nürnberg]. Veit Dietrich an Johannes Brenz (d. Ä.) </b> 
					(<Link to="/brief/50733" target="_blank">Brief-ID 50733</Link>)<br/>
					<b>27. Juli 1567, Esslingen. Jakob Andreae an Johannes Marbach </b> 
					(<Link to="/brief/19293" target="_blank">Brief-ID 19293</Link>)<br/>
					<b>[Februar/März 1567], Zweibrücken. Cunmann Flinspach an Konrad Hubert </b> 
					(<Link to="/brief/19272" target="_blank">Brief-ID 19272</Link>)<Space/><br/>
					</Brieftitel>
					An der Wende zum 20. Jahrhundert verfasste der Psychoanalytiker Sigmund Freud seine berühmte Schrift
					<i> Die Traumdeutung. </i>Freud galten Träume als Wunscherfüllungen, als Bilder, in denen sich verdrängte 
					Wünsche manifestieren – und somit als Möglichkeit, zum Unbewussten des Menschen vorzudringen. Dabei 
					spielten Träume, Visionen und ihre Deutung bereits seit der Antike in verschiedenen Kulturen eine wichtige 
					Rolle. So wurden sie etwa als religiöse Erfahrungen wahrgenommen oder zur Vorhersage der Zukunft 
					interpretiert. Bereits im Mittelalter existierten Traumbücher, die für bestimmte Bilder und Inhalte 
					Entschlüsselungen festhielten.<br/><br/>Auch in frühneuzeitlichen Theologenbriefen werden 
					gelegentlich Träume thematisiert, besonders dann, wenn sich die Korrespondenten nahestehen. So beginnt etwa 
					der Zweibrücker Pfarrer Cunman Flinspach im Jahr 1567 einen Brief an seinen Straßburger Freund Konrad Hubert 
					mit einer besorgten Bitte: Er möchte dringend wissen, wie es Hubert geht, denn er hatte ungewöhnliche Träume, 
					die sich auf ihn bezogen (<i>somnia tui causa habui insueta</i>). Im gleichen Jahr erklärt der 
					württembergische Theologe Jakob Andreae dem Präsidenten des Straßburger Kirchenkonvents Johannes Marbach, 
					warum er diesen im Rahmen einer Reise nicht besucht hat – und verweist dabei auf einen Traum, der ihn 
					veranlasste, seine Route zu ändern: <i> per somnium admonitus sum, ut per aliam viam redirem domum. </i>
					Tatsächlich sei, so führt er weiter aus, die Gefahr durch die feindseligen Altgläubigen so groß, dass ihm 
					kaum ein Weg sicher genug erscheint. In beiden Fällen werden Träume also von den Träumenden als Ankündigungen 
					drohenden Unglücks verstanden.<br/><br/>Etwas anders gelagert ist der Fall, den der Nürnberger 
					Prediger Veit Dietrich im August 1546 gegenüber Johannes Brenz schildert. Dietrich war ein Vertrauter Martin 
					Luthers und Philipp Melanchthons – und um Letzteren geht es in seinem Brief. <i> Philippus </i>sei in bester 
					Stimmung, schreibt Dietrich, denn: <i> Habet somnia fausta </i>– er hat Träume, die Gutes verheißen. 
					Eindrücklich gibt Dietrich in der Folge den Inhalt zweier Träume wieder. Im ersten läuft Melanchthon zusammen 
					mit dem Nordhäuser Stadtschreiber durch eine Gasse in Regensburg. In einem Bächlein erblickt er einen 
					Ertrunkenen (<i>ibi videt quendam submersum</i>). Als er sich diesem nähert, flieht sein Begleiter zitternd: 
					Es ist Kaiser Karl V. (<i>videt Carolum esse</i>). Melanchthon versucht vergeblich, den Körper zu bergen; an 
					der Wasseroberfläche steigen dafür zwei Ringe unterschiedlicher Größe und Form empor (<i>videt […] emergere 
					super aquam duos annulos magnitudine et figura dissimiles</i>).<br/><br/>Veit Dietrich beschreibt 
					noch einen zweiten Traum Melanchthons: Zusammen mit Joachim Camerarius legt dieser in einer Festung den 
					Seinen die<i> Philippika </i>des Demosthenes aus. Als er zufällig auf die Elbe schaut, ist diese voller Blut 
					(<i>videt eum multo manare sanguine</i>). Konkrete Deutungen liefert Dietrich nicht, sondern belässt es bei 
					einem lakonischen Hinweis: Brenz wisse um die Sehergabe Melanchthons, den Dietrich mit dem biblischen 
					Propheten und Traumdeuter Daniel gleichsetzt (<i>scis divinatricem naturam in hoc Daniele nostro</i>).					
					<br/>
					<ImageBox>
						<img src="dan7.jpg" width="100%" alt="" />
						<Space/>Daniels Traum von den vier Weltreichen (Dan 7).&nbsp;
						<Link to="//www.e-rara.ch/zuz/content/zoom/7589226" target="_blank">(Merian d. Ä., Icones Bibliae 3, 1630 (Zürich ZB, Rv 76), S. 43)</Link>
					</ImageBox>
					<br/>
					Ein Blick auf den konkreten historischen Kontext – den Dietrich am Ende des Briefs selbst anspricht – legt nahe, 
					worauf sich Melanchthons Träume beziehen: Im Sommer 1546 beginnt der Schmalkaldische Krieg zwischen Kaiser 
					Karl V. und einem Bündnis protestantischer (Reichs-)Städte und Fürsten, gerade der Süden des Reichs ist anfangs 
					ein wichtiger Kriegsschauplatz. Melanchthons träumerische Gesichte erwiesen sich allerdings in Bezug auf Karl V. 
					als unzutreffend: Mit dem Sieg seiner Truppen in der Schlacht bei Mühlberg im April 1547 beendete der Kaiser den 
					Krieg erfolgreich.<br/>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juni 2023: „Ein Blick ins Buch und zwei ins Leben …“ - Anforderungen an einen Geschichtsschreiber</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>27. Juli 1577, Reichenweier. Nikolaus Cancerinus an Johannes Pappus, Theologieprofessor in Straßburg</b> (
						<Link to="/brief/80003" target="_blank">Brief-ID 80003</Link>)<Space/>
					<br/>
					</Brieftitel>
					Im Rahmen unseres Forschungsprojekts treten Theologen vor allem als Briefschreiber in Erscheinung. Etliche Geistliche griffen 
					auch zur Feder, um theologische Abhandlungen zu verfassen, nicht selten in polemischer Absicht. Gelegentlich entstanden sogar 
					Bühnenstücke oder Gedichte. Daneben gab es auch Versuche auf dem Gebiet der Geschichtsschreibung, die von den Zeitgenossen 
					mitunter durchaus kritisch beobachtet wurden. Dies können wir einem Brief entnehmen, den der in Reichenweier im Elsass wirkende 
					Pfarrer und Superintendent Nikolaus Cancerinus am 27. Juli 1577 an den Straßburger Theologieprofessor Johannes Pappus schrieb. 
					In diesem Brief teilt Cancerinus mit, dass der Wunsch von Pappus, ein Buch des Autors Kaspar Brusch zu erhalten, nicht erfüllt 
					werden kann, weil schon Konrad Lautenbach, ein Pfarrer im elsässischen Hunaweier, das Exemplar für sich beansprucht habe. 
					Lautenbach sammle nämlich die Werke von Geschichtsschreibern, weil er die Kommentare von Sleidanus über die Religion und den 
					Staat bis zur Gegenwart fortsetzen möchte.<br/><br/>
					Mit diesen Kommentaren ist die Reformationsgeschichte<i> De statu religionis et reipublicae Carolo Quinto Caesare Commentarii </i>
					gemeint, die der aus Schleiden in der Eifel stammende Diplomat und Historiker Johannes Sleidanus in Straßburg im Jahr 1555 
					publiziert hatte. Dieses Werk wurde sehr populär, auch wenn es dem Verfasser Kritik einbrachte, gerade weil Sleidanus sich um 
					Unparteilichkeit bei der Darstellung der Konfessionen bemühte. Und es setzte Maßstäbe – da Sleidanus als Zeitzeuge schrieb, 
					Originalquellen heranzog und sich mit Polemik zurückhielt.<br/><br/>
					In seinem Brief schreibt Cancerinus von seiner Befürchtung, dass es Konrad Lautenbach an der Fähigkeit für diese Aufgabe mangeln 
					könnte. Cancerinus begründet dies wie folgt: Wer Geschichte schreiben will, muss nicht nur über eine außergewöhnliche 
					Gelehrsamkeit und gesunde Urteilskraft verfügen, sondern auch viele Ereignisse gesehen und persönlich erlebt haben – was bei 
					Sleidanus der Fall war. 
					Lautenbach aber – den Cancerinus geringschätzig und auf Griechisch als „Nachahmer“ (μιμητής) tituliert – habe sich niemals mit 
					Geschichtsschreibung beschäftigt; und daher könne Cancerinus die übrigen Anforderungskriterien für ein solches Unterfangen 
					übergehen. Somit erfahren wir leider nicht, an welche weiteren Kriterien Cancerinus dachte. Aber wir können aus dem zuvor 
					Gesagten einige Voraussetzungen ableiten, die seiner Meinung nach entscheidend für einen guten Historiographen waren: 
					Gelehrsamkeit, Urteilskraft, Sachverstand und Augenzeugenschaft. Doch was wurde aus dem Vorhaben Konrad Lautenbachs, das Werk von 
					Sleidanus fortzusetzen? Mehrere gewichtige Indizien legen nahe, dass Lautenbach in Zusammenarbeit mit Michael Beuther die 
					Fortsetzungen für die Jahre 1556 bis 1595 verfasste, die viele Jahre später im Druck erscheinen sollten.
					<ImageBox>
						<img src="klio.jpg" width="100%" alt="" />
						<Space/>Klio, die Muse der Geschichtsschreibung, mit ihren Attributen Lorbeerkranz und Schriftrollen.&nbsp;
						<Link to="//commons.wikimedia.org/wiki/File:Clio_-_the_Greek_muse_of_history_and_the_lyre_playing.jpg#/media/File:Clio_-_the_Greek_muse_of_history_and_the_lyre_playing.jpg" target="_blank">(Quelle: Wikimedia)</Link>
					</ImageBox>
					<br/>
					<Unterschrift>Judith Steiniger</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Mai 2023: Königsmord! – Gattenmord? Das Ende des schottischen Königs Henry Stuart</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>30. März 1567. Thomas Erastus an Heinrich Bullinger</b> 
					(<Link to="/brief/42842" target="_blank">Brief-ID 42842</Link>)<Space/>
					<br/>
					</Brieftitel>
					Thomas Erastus, den wir in der Rubrik „Brief des Monats“ bereits in seiner Profession als Arzt (September 2021), in seiner Funktion 
					als Berater Kurfürst Friedrichs III. und Rektor der Universität Heidelberg (Juli 2021) sowie als Privatmann und Liebhaber von Schweizer 
					Käse (Juli 2022 und Februar 2023) kennengelernt haben, begegnet uns heute als Multiplikator von Nachrichten aus aller Welt, die unter 
					der Bezeichnung „Newe Zeitungen“ in deutscher Sprache abgefasst und den Briefen auf separatem Bogen beigegeben wurden.<br/><br/>
					In einem Brief, den Erastus am 30. März 1567 an Heinrich Bullinger nach Zürich schrieb, berichtete er, was ihm über politische und 
					gesellschaftliche Ereignisse aus ganz Europa zu Ohren gekommen war. Hierzu zählten Nachrichten über den Bischof von Lüttich, der den 
					Aufstand der Protestanten in Hasselt niedergeschlagen und die Stadt eingenommen hatte, über den Grafen von Egmond, der sich den Papisten 
					angeschlossen und die Menschen in Flandern zum Treueschwur auf den König gezwungen hatte, über die Belagerungen von Antwerpen und Gotha, 
					die Pläne der Türken vor Wien sowie die in Ungarn grassierende Epidemie.<br/><br/>
					Während Erastus die meisten dieser Ereignisse und Begebenheiten recht knapp und sachlich beschreibt, schildert er ein weiteres Vorkommnis 
					– den am 10. Februar 1567 verübten Mord an Henry Stuart, dem Gemahl der schottischen Königin Maria Stuart – ausführlich und äußerst 
					detailreich: Die Explosion, die das Anwesen in Edinburgh, in dem der König krank im Bett lag, zerstörte, hatte dieser zwar gemeinsam mit 
					seinem Diener im Keller überlebt, als er anschließend aber durch den Garten fliehen wollte, wurde er überfallen und mit seinem eigenen 
					Hemd erdrosselt. Erastus zeichnet den Tathergang so anschaulich nach, dass wir ihn selbst zu Wort kommen lassen wollen:<br/><br/>
					<i>Aus Schotten wird des Konigs mord also angezeigt: Als der konig noch etwas kranck an purpeln oder kindblattern gelegen, ist die konigin, 
					welche ihme lange zeit kein eheliche beiwonung thun wollen, zu ihme komen vnd sich gebaret, als wolte sie sich versünen; ihme auch 
					zugesagt, nach acht tagen wider sich zu ihm zuthun; darauff freuntlich von ihme abgescheiden, vnd doch als bald ire wonung von des königs 
					wonung weiter hinden geruckt, damit das pulffer sie nit erreichte.<br/>
					Des abends, als sich der könig zu ruwen legen wollen vnd sein vertrawter knab zum fenster aus beide geharnester man gesehen, dasselbig 
					dem konig angezeigt, ist er im hembd allein mit dem iungen in ein keller hinab gestigen; bald ist der palast zersprengt vnd, wer darinnen 
					ergriffen, getödt worden.<br/>
					Vber 1 stund hatt er den iungen hinaus geschickt zubesehen, ob er sicher were. Als aber der iunge bekant vnd nach [dem] könig gefragt vnd 
					angegriffen worden, hatt er angefangen zu schreien. Als solchs der konig gehort vnd aus dem keller gestigen, durch einen garten entfliehen 
					wollen, ist er ersehen vnd ereilt, ihme das hembd zerrissen vnd damit erwürgt worden.</i><br/><br/>
					Erastus beschließt seinen Bericht mit dem Hinweis auf das Gerücht, die evangelischen Reichsstände wollten an der katholischen Königin Maria 
					Stuart Rache nehmen, weil sie ihr den Mord an ihrem Gatten anlasteten. Zudem stamme sie aus dem Geschlecht der Guisen, das den Protestanten 
					schon viel Unheil gebracht habe.<br/><br/>
					Dieses Schreiben, das von Heidelberg nach Zürich geschickt wurde, führt eindrucksvoll vor Augen, dass sich Theologen in ihren Briefen nicht 
					ausschließlich mit konfessionellen Fragen oder ideengeschichtlichen Diskursen befassten. Die Korrespondenz stand zwar in einem 
					übergeordneten gelehrsamkeitsgeschichtlichen Kontext, sie diente jedoch zu einer Zeit, die noch keine Massenmedien kannte, in hohem Maße 
					auch zur Verbreitung von Nachrichten über Ereignisse aller Art. Erastus‘ Schilderung des Königs- oder Gattenmords in Schottland greift zum 
					einen das auf, was er darüber erfahren hatte, zum anderen spiegelt sie seine persönlich gefärbte Sicht auf den Fall. Ähnlich wie 
					Informationsmedien unserer Tage hatten Briefe in der Vormoderne somit einen wesentlichen Anteil an der Meinungsbildung.
					<br/>
					<ImageBox>
						<img src="bdm42842.jpg" width="100%" alt="" />
						<Space/>Handzeichnung: Ermordung von Henry Stuart, Ehemann von Maria Stuart, Königin von Schottland&nbsp;
						<Link to="//en.wikipedia.org/wiki/Henry_Stuart,_Lord_Darnley#/media/File:Kirk_o'_Field_contemporary_sketch.jpg" target="_blank">(Quelle: Wikipedia)</Link>
					</ImageBox>
					<br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>April 2023: Conscendere soleo nostros colles et montes – Spaziergänge eines Professors</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>7. August 1579, Neustadt an der Haardt. Hieronymus Zanchi an Kaspar Olevian</b> 
					(<Link to="/brief/46900" target="_blank">Brief-ID 46900</Link>)<Space/>
					<br/>
					</Brieftitel>
					Im Laufe seines Lebens musste der aus Italien stammende evangelische Theologe Hieronymus Zanchi mehr als einen Umzug bewältigen. 
					Besonders beschwerlich war der Ortswechsel von Piuro bei Chiavenna nach Heidelberg, wie der Brief des Monats Januar 2023 
					eindrücklich veranschaulicht. Nicht weniger als 6 Wochen war Zanchi mit seiner gesamten Familie samt eines gerade entbundenen 
					Kindes mitten im Winter über die Alpen unterwegs, bis er am 23. Januar 1568 am Neckar ankam.<br/><br/> Beim aktuellen Brief des Monats 
					treffen wir Zanchi schon wieder an einem neuen Wohnort: Neustadt an der Haardt (heute: an der Weinstraße). 16 Monate davor hatte 
					Zanchi im Zuge der Relutheranisierung Heidelbergs durch Kurfürst Ludwig VI. seine Wirkungsstätte nach über zehn Jahren verlassen 
					müssen, um schon wieder neu Fuß zu fassen, diesmal im linksrheinischen Fürstentum Pfalz-Lautern. Im bescheidenen Oberamtsstädtchen 
					Neustadt war Pfalzgraf Johann Casimir, der calvinistisch gesinnte Bruder Ludwigs VI., dabei, eine eigene Hochschule aufzubauen, 
					die den aus der Universität Heidelberg vertriebenen reformierten Professoren – Zanchi eingeschlossen – Unterschlupf bot. Obwohl 
					die 50 km von Heidelberg bis Neustadt ungleich kürzer als die knapp 500 km von Piuro bis Heidelberg waren, bedeuteten sie für den 
					63-jährigen Gelehrten eine gewaltige Degradierung: Zuhause war für ihn nicht mehr eine 6.500 Einwohner zählende Universitätsstadt 
					mit europäischer Ausstrahlung, sondern das höchstens 1.400 Einwohner umfassende Provinznest Neustadt!<br/><br/> Es muss für den langjährigen 
					reformierten Professor erniedrigend gewesen sein, zu erfahren, dass inzwischen lutherische Theologen in Heidelberg das Sagen hatten, 
					und dass nicht nur Jakob Andreae und Martin Chemnitz, sondern sogar sein alter Straßburger Gegner Johannes Marbach, der bereits vor 
					16 Jahren für seinen demütigenden Weggang aus der elsässischen Reichsstadt gesorgt hatte, nun in seiner ehemaligen Wirkungsstätte am 
					Neckar herumstolzierten. Nicht ohne Sarkasmus berichtet Zanchi in einem Brief an Kaspar Olevian vom 7. August 1579 (Brief-ID 46900): 
					„Ich höre, dass unser Straßburger bisweilen auf einem Pferd, das mit einem bis zur Erde reichenden Tuch bedeckt ist, durch die Stadt 
					zu reiten pflegt, mit einem Gefolge, das dem eines Fürsten oder eher dem des Erzbischofs von Konstantinopel gleicht“.<br/><br/>
					Doch Zanchi weist trotzig jeden Neid von sich:<i> felicior ego sum, qui solus et pedes quotidie conscendere soleo hosce nostros colles 
					et montes. </i>„Glücklicher bin ich, der ich alleine und zu Fuß täglich die hiesigen Hügel und Berge zu besteigen pflege.“<br/><br/>
					Wer die örtlichen Begebenheiten in Augenschein nimmt, wird schnell ahnen, was Zanchi mit<i> hosce nostros colles et montes </i>meinte und 
					weshalb er sich glücklicher zu Fuß in der Provinz als zu Pferd in Heidelberg wähnte. Denn schon vom Casimirianum (Bild 1, vorne links) 
					aus, wo Zanchi unterrichtete, laden die Anhöhen und Hügel, die Neustadt umgeben, zum Wandern ein.
					<br/><br/>
					<ImageBoxCentered width="385px" >
						<img src="neustadt1.jpg" width="100%" alt="" />
					</ImageBoxCentered>
					Man kann sich durchaus vorstellen, wie Zanchi sie gerne durchstreifte und auf seine Wirkungsstätte, das Casimirianum, herunterblickte:
					<ImageBoxCentered width="385px" >
						<img src="neustadt2.jpg" width="100%" alt="" />
					</ImageBoxCentered>
					Schließlich mag sein Blick von dort aus unweigerlich zum Königstuhl geschweift sein, wo er das diesem zu Füßen liegende Heidelberg in 
					der Ferne erahnte, an seine alte Wirkungsstätte zurückdachte und über die dortigen Ereignisse nachsann:
					<ImageBoxCentered width="385px" >
						<img src="neustadt3.jpg" width="100%" alt="" />
					</ImageBoxCentered>
					Neustadt blieb noch für die nächsten elf Jahre Zanchis Zuhause. Der Tod ereilte ihn während eines Besuches in Heidelberg am 19. November 1590.
					<br/><br/>
					Dieser Brief ist im jüngsten Band des Theologenbriefwechsels ediert: <Link to="/editionsbaende">ThBW 2 (Kurpfalz I)</Link>, S. 529f.
					<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>				
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>März 2023: Astronomie ohne Himmelsglobus? Universitäre Lehre unter erschwerten Bedingungen</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>20. Februar 1567, Esslingen. Jakob Heerbrand, Johannes Brenz d. J. und Samuel Heiland an Herzog Christoph von Württemberg</b> (
						<Link to="/brief/70872" target="_blank">Brief-ID 70872</Link>)<Space/>
					<br/>
					</Brieftitel>
					Pandemien begleiten die Menschheitsgeschichte seit jeher. Zu den besonders verheerenden Ereignissen dieser Art gehörte die Pest, die zwischen 1346 
					und 1353 rund 25 Millionen Todesopfer in ganz Europa forderte und die Bevölkerung damit um ein Drittel dezimierte. In den nachfolgenden Jahrhunderten 
					brach die Pest als lokale epidemische Erscheinung immer wieder aus. Tübingen wurde zwischen 1480 und 1680 über ein Dutzend Mal von dieser Seuche 
					heimgesucht, was insbesondere die dortige Universität vor große Herausforderungen stellte: Wie konnte man den Lehrbetrieb fortsetzen, wie Professoren 
					und Studenten vor der Ansteckungsgefahr schützen?<br/>Wie anderenorts entschied man sich auch in Tübingen, die Universität für den Zeitraum der 
					Epidemie zu verlegen, und so zogen Professoren, Studenten sowie die Stipendiaten des Tübinger Stifts in 200 Jahren insgesamt vierzehn Mal aus der Stadt 
					und setzten den Lehrbetrieb stattdessen in Nagold, Rottenburg, Blaubeuren, Herrenberg oder Calw fort. 1571 und 1576 wurde die Reichsstadt Esslingen zum 
					mehrwöchigen Pestexil der Universität. Welche logistischen Herausforderungen zu bewältigen waren, wenn mehrere hundert Personen mit Sack und Pack 
					in überwiegend kleinen Landstädten untergebracht werden mussten, wird in den Briefen der Tübinger Theologen eingehend thematisiert. Welche Folgen diese 
					Situation jedoch insbesondere für den universitären Lehrbetrieb hatte, erfahren wir aus einem Schreiben, dass die verantwortlichen Theologen des Tübinger 
					Stifts am 20. Februar 1567 an Christoph von Württemberg sandten. Die beiden Superattendenten Jakob Heerbrand und Johannes Brenz d. J. sowie der Magister 
					Domus Samuel Heiland berichteten dem Herzog:<br/><br/>
					Die Stipendiaten hätten sich<i> ettliche mal </i>darüber beklagt, dass beim Umzug der Universität nach Esslingen die mathematischen Instrumente ihrer 
					Größe wegen nicht mitgenommen werden konnten. Samuel Eisenmenger (1534–1585), ihr Professor für Astronomie, habe folglich<i> kain recht taugelich Instrument </i>
					zur Hand,<i> damitt er die orbes coelestes, auch Astronum cursum gnugsam demonstriern künde. </i>Die Stipendiaten seien in ihrem Studium,<i> wöllches 
					allain durch Instrumenta vnd oculares demonstrationes will geleertt vnd verstanden werden, </i>stark eingeschränkt und verlören überaus viel Zeit damit, 
					Eisenmengers Erklärungen ohne Demonstration anhand der Instrumente zu verstehen. Nun wisse man aber, dass der Herzog ein solches Instrument – nämlich eine 
					Sphaira – besitze. Heerbrand, Brenz und Heiland bitten folglich darum, den Studenten diesen Himmelsglobus zur Verfügung zu stellen und versichern, dass sie 
					persönlich Sorge tragen werden, ihn<i> rayn vnd sauber </i>zu erhalten und ihn ausschließlich für den genannten Zweck zu verwenden.<br/><br/>
					Diese geschmeidig vorgetragene Bitte um Überlassung des Himmelsglobus für Lehrzwecke an der Universität wurde allerdings vom Herzog abgelehnt: Die Sphaira 
					sei nicht für den genannten Zweck geeignet und überdies<i> mangelhafft, </i>also beschädigt oder nicht funktionstüchtig. Da es der Universität nicht möglich 
					sei, die Instrumente aus Tübingen nach Esslingen zu holen, sollen während des Exils mit den Studenten eben andere astronomische Lektionen durchgenommen werden!
					<br/>
					<ImageBoxCentered width="385px" >
						<img src="sphaira.jpg" width="100%" alt="" />
						<Space/>Himmelsglobus des Pfarrers und Astronomen Johannes Stöffler aus Justingen, 1493 signiert. Stöffler lehrte von 1511 bis zu seinem Tod 1531 als 
						erster Professor für Astronomie an der Universität Tübingen. –&nbsp;Bild:&nbsp;
						<Link to="//www.landesmuseum-stuttgart.de/sammlung/sammlung-online/dk-details?dk_object_id=509" target="_blank">Landesmuseum Stuttgart</Link>
					</ImageBoxCentered>
					<br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Februar 2023: Der 10.000. Brief ist online!<br/> Briefbeförderung, Heidelberger Katechismus und guter Käse</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>30. Januar [1563], Heidelberg. Thomas Erastus an Heinrich Bullinger in Zürich</b> (<Link to="/brief/18570" target="_blank">Brief-ID 18570</Link>)<Space/>
					<br/>
					</Brieftitel>
					Seit Sommer 2020 können Interessierte die Online-Datenbank des Heidelberger Akademieprojekts „Theologenbriefwechsel“ erkunden. Der 1. Februar 2023 markiert 
					nun einen gewichtigen Meilenstein: Mit dem vorliegenden wird der 10.000. (mit Regest versehene und durch Schlagworte inhaltlich erschlossene) Brief 
					freigeschaltet. Bis zum Ende der Projektlaufzeit im Jahr 2031 wird die Zahl der erfassten Briefe auf ca. 35.000 ansteigen.
					<br/><br/>
					Der Brief, den Thomas Erastus (1524-1583) am 30. Januar 1563 aus Heidelberg an Heinrich Bullinger (1504-1575) in Zürich schrieb, ist geradezu exemplarisch 
					für die im Rahmen des Projekts bearbeiteten Korrespondenzen: Er gibt nicht nur einen Eindruck davon, unter welchen Bedingungen das Medium Brief zum Einsatz 
					kommt, sondern veranschaulicht auch die thematische Vielfalt frühneuzeitlicher Briefe im Allgemeinen und der Theologenbriefwechsel im Speziellen. 
					Schließlich belegt er eindrucksvoll, dass es die Auswertung von Briefen erlaubt, allgemein- und konfessionsgeschichtliche Vorgänge genauer zu fassen.
					Der aus der Schweiz stammende Erastus war Mediziner und seit 1558 Professor an der Universität Heidelberg. Gleichzeitig war er Mitglied des Kirchenrats und 
					eine in theologischen und konfessionellen Fragen einflussreiche Figur – und er unterhielt wichtige briefliche Kontakte, besonders ins reformierte Zürich.
					<br/><br/>
					Im vorliegenden Brief an Bullinger spricht Erastus eingangs ein recht profanes, aber elementares Problem an: die Frage nach der sicheren Beförderung von 
					Briefen. Tatsächlich, so Erastus, hätten in letzter Zeit keine Boten zur Verfügung gestanden. Nun referiert er knapp seinen Vorschlag für den Transport von 
					Briefen zwischen Zürich und Heidelberg und bittet um Bullingers Einschätzung dazu: Bullinger möge seine Briefe an Jakob Rüeger, Münsterpfarrer in 
					Schaffhausen, senden, sofern dieser in der Lage ist, sie rasch nach Rottweil weiterzuleiten. Dort nämlich verfügt Kurfürst Friedrich III. über einen 
					Mittelsmann, der für die Beförderung der Briefe nach Heidelberg sorgen könnte. Er selbst würde seine Briefe nach Rottweil schicken, wenn eine Weiterleitung 
					nach Schaffhausen sichergestellt werden kann. Eindrücklich führt diese Passage die ganz konkreten Begleitumstände und Hürden brieflicher Kommunikation in 
					der Frühen Neuzeit vor Augen, in einer Zeit also, in der sich eine flächendeckende postalische Infrastruktur erst noch herausbilden musste.
					<br/><br/>
					In der Folge berichtet Erastus knapp von diversen konfessionspolitischen Vorgängen, die von teilweise herausragender kirchengeschichtlicher Bedeutung sind: 
					Zunächst unterrichtet er Bullinger von der unmittelbar bevorstehenden Drucklegung des überaus wirkmächtigen Heidelberger Katechismus. Als dessen 
					Hauptverfasser gilt Zacharias Ursinus (1534-1583). Aufschlussreich ist nicht nur, wie Erastus den Katechismus charakterisiert – niemand wird den 
					Heidelbergern Dissimulation vorwerfen können (<i>Nemo, arbitror, dicet aliquid a nobis dissimulari</i>) –, sondern auch und vor allem, wie er seinen eigenen 
					Anteil an der Entstehung beschreibt: Die im Katechismus <i>aperte et fuse </i>(also offen und ausführlich) formulierte Sakramentenlehre lehne sich zum einen 
					an ein bereits veröffentlichtes Werk an (<i>pro ratione propositi operis</i>) – gemeint sein könnte Erastus’ eigener <i>Gründtlicher bericht </i>über die 
					Einsetzungsworte Christi beim Abendmahl von 1562 –; zum anderen habe sich Erastus der Arbeit am Katechismus schon lange voll und ganz hingegeben: <i>Iam diu 
					in eo totus fui</i>.<br/><br/>
					<ItemContainer>
						<img src="bdm18570.jpg" width="50%" alt="" />
						<Row><Space/>Nürnberger Briefbote&nbsp;
						<Link to="//objektkatalog.gnm.de/objekt/HB2304" target="_blank">(Germanisches Nationalmuseum Nürnberg)</Link></Row>
					</ItemContainer>
					<br/>
					Sodann kommt Erastus auf die Pläne Kurfürst Friedrichs III. über die Einführung des Reformiertentums in der Oberpfalz zu sprechen. Konkret bittet er um 
					Bullingers Meinung über den ehemaligen bayerischen Hofmarschall Pankraz von Freyberg, der u. a. aufgrund seiner reformatorischen Neigungen kurz darauf in 
					Bayern wegen des Verdachts der Verschwörung angeklagt werden sollte. Friedrich III., so mutmaßt Erastus, erwägt, Freyberg die Leitung des Hofs seines Sohnes 
					Ludwig zu übertragen, den er nach Amberg schicken will.
					<br/><br/>
					Es folgt ein durchaus polemischer Ausfall gegen den Jenaer Lutheraner Johannes Stössel. Dieser hatte im Juni 1560 mit dem Heidelberger Theologieprofessor 
					Pierre Bouquin öffentlich über das Abendmahl disputiert; damals war Erastus seinem Kollegen Bouquin beigesprungen und hatte das reformierte Verständnis 
					vertreten. Nun brandmarkt Erastus Stössel als falschen Höfling, als Fähnchen im Wind (<i>Ego hominem novi falsum et aulicum accomodantem se temporibus et 
					locis</i>), dem gleichzeitig sein verbohrter Hass gegen die Flacianer wichtiger ist als die Wahrheit.
					<br/><br/>
					Im Anschluss zeigt sich eine weitere entscheidende Dimension frühneuzeitlicher Korrespondenzen: Sie dienten nicht zuletzt dem Austausch und der Verifizierung 
					von Nachrichten aus ganz Europa. In diesem Fall teilt Erastus – wenn auch unsichere – Informationen über den Verlauf des 1. Hugenottenkriegs in Frankreich 
					(<i>De Gallicis rebus</i>) mit. So soll etwa – nach der Schlacht bei Dreux vom 19. Dezember 1562 – der Hugenottenführer Admiral Gaspard de Coligny die Festung 
					belagern, in der der Fürst von Condé, der zweite hugenottische Befehlshaber, vom katholischen Herzog von Guise festgehalten wird. Erastus glaubt dies 
					allerdings nicht (<i>Fabulas puto</i>). Englische Truppen sollen sich mit dem Admiral zusammengeschlossen haben; offenbar finden zudem Friedensverhandlungen 
					statt. Falls Bullinger Neuigkeiten hat, so Erastus’ Bitte, möge er diese baldmöglichst mitteilen. 
					<br/><br/>
					Das Ende des Briefes schließlich enthält u. a. eine für	Erastus’ Korrespondenz einschlägige, ganz lebensweltliche Bitte: Bullinger möge veranlassen, dass der 
					Zürcher Drucker Christoph Froschauer ihm guten Käse zukommen lässt; bezahlen werde ihn Erastus bei der kommenden Frankfurter Messe.
					<br/>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Januar 2023: Aller Anfang ist schwer – Zanchis Ankunft in Heidelberg 1568</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>25. Januar 1568, Heidelberg. Hieronymus Zanchi an Konrad Hubert in Straßburg</b> (<Link to="/brief/19439" target="_blank">Brief-ID 19439</Link>)<Space/><br/>
					<b>7. Februar 1568, [Heidelberg]. Hieronymus Zanchi an Kurfürst Friedrich III. von der Pfalz</b> (<Link to="/brief/19428" target="_blank">Brief-ID 19428</Link>)<br/>
					</Brieftitel>
					Ein beruflicher Neubeginn an einem fremden Ort bringt manche Herausforderungen mit sich. Das mussten bereits Theologen des 16. Jahrhunderts wie Hieronymus 
					Zanchi (1516–1590) erfahren. Zanchi, gebürtiger Italiener, war nach seiner Zeit als Theologieprofessor in Straßburg wenige Jahre Pfarrer in Chiavenna 
					gewesen. Nach seiner dortigen Entlassung erhielt er Ende des Jahres 1567 einen Ruf an die reformierte Universität Heidelberg. Hier sollte er als Nachfolger 
					von Zacharias Ursinus (1534–1583), der fortan ausschließlich als Vorsteher des Sapienzkollegs tätig war, Dogmatik lehren.<br/><br/>
					Die Schwierigkeiten des Umzugs und der Ankunft in Heidelberg spiegeln sich in Zanchis Briefen wider. Nach einer ersten Reise, die Zanchi allein nach Heidelberg 
					auf sich genommen hatte, folgte am 13. Dezember 1567 der eigentliche Umzug mit seiner Familie aus Piuro, dem Heimatort seiner Frau Livia, über die Alpen. Dazu 
					gehörte auch der aufwändige Transport der Bibliothek und des Hausrats – alles in Fässern verpackt – über Zwischenstationen in Chur und Straßburg. Verzögert und damit 
					finanziell kostspieliger wurde das Unterfangen noch durch die Schwäche seiner Frau, die vor kurzem entbunden hatte, die Kränklichkeit seiner kleinen Kinder, die 
					beschwerlichen Wege und die kurzen, unwirtlichen Wintertage (<i>Uxoris a novo puerperio infirmitas, trium liberorum et omnium fere infantium imbecillitas, difficultas 
					viarum, breves et incommodi frigidae hiemis dies, quorundam etiam iniquitas</i>).	Am 23. Januar 1568 erreichten die Zanchis schließlich Heidelberg. Am Abend konnten 
					sie ein Quartier aufsuchen, das die Universität für kurze Zeit gemietet hatte – das Zanchi eigentlich zustehende Haus in der heutigen Hauptstraße war nämlich noch vom 
					Theologen Victorinus Strigel (1524–1569) bewohnt. 
					Gleichwohl packte Zanchi am 24. Januar bereits die Kisten aus (<i>24. totus fui in dissolvendis cistis – et nondum finis</i>).<br/><br/>
					Zanchi nahm es dem Kurfürsten vielleicht etwas übel, dass dieser ihn noch nicht begrüßte, wie er am 25. Januar 1568 an Konrad Hubert in Straßburg schrieb:<i> Princeps 
					hic est, sed nondum me accersivit. </i>Gute zwei Wochen später, am 7. Februar, konnte es Zanchi dann nicht unterlassen, sich selbst schriftlich an den Kurfürsten zu 
					wenden, um demütig mitzuteilen, dass er durchaus Interesse gehabt hätte, sich persönlich vorzustellen. Dass er das aber bislang unterließ (<i>mihi fuit significatum non 
					esse moris, ut quis illam [sc. Tuam Celsitudinem] nisi vocatus accedat</i>), möge Friedrich III. ihm nicht falsch auslegen. Man fühlt sich bei dem Ratschlag, den Zanchi 
					erhielt, an die Redewendung „Gehe nie zu deinem Fürst, wenn du nicht gerufen wirst“ erinnert. Daran hielt sich Zanchi freilich nicht, da er dem Kurfürsten ungefragt 
					eine detaillierte Aufstellung seiner Reisekosten mitschickte (<i>Mitto autem hic omnium a me factorum sumptuum summam</i>). 
					So sollte die Schilderung all seiner vergangenen Strapazen und gegenwärtigen Herausforderungen neben dem überschwänglichen Lob des Kurfürsten vor allem dazu dienen, 
					dass ihm finanzielle Erleichterung aus der kurfürstlichen Kasse zuteilwerde. Schließlich kostete der Umzug summa summarum 320 Gulden und 2 Batzen – damals etwa zwei 
					jährliche Professorengehälter.<br/>					
					<ItemContainer>
						<img src="heidelberg1570.jpg" width="100%" alt="" />
						<Row><Space/>Heidelberg um 1570. Braun/Hogenberg, Civitates orbis terrarum, 1593&nbsp;
						<Link to="//doi.org/10.11588/diglit.12704#0095" target="_blank">(UB Heidelberg)</Link></Row>
					</ItemContainer>
					<ItemContainer>
					Eine kritische Edition des Briefs vom 7. Februar 1568 bietet der gerade erschienene zweite Editionsband des Projekts:<br/>
					Theologenbriefwechsel im Südwesten des Reichs in der Frühen Neuzeit (1550–1620). Kritische Auswahledition, Band 2: Kurpfalz I (1556–1583), hg. von Christoph Strohm, 
					Gütersloh 2022 (Quellen und Forschungen zur Reformationsgeschichte 99), S. 164–169 (Nr. 36).
					</ItemContainer>
					<Unterschrift>Marcel Böhme</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Dezember 2022: Böse Omen. Brände, Dürre, Erdbeben</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>16. April 1569, [Heidelberg]. Victorinus Strigel an Georg Mauritius in [Wittenberg]</b> (<Link to="/brief/87185" target="_blank">Brief-ID 87185</Link>)<Space/><br/>
					<b>17. Oktober 1590, Altdorf. Edo Hilderich von Varel an Johann Jakob Grynaeus in Basel</b> (<Link to="/brief/24887" target="_blank">Brief-ID 24887</Link>)<br/></Brieftitel>
					Die Frühe Neuzeit war für die Zeitgenossen in vielerlei Hinsicht von existenzieller Unsicherheit geprägt. Die Bauernkriege, die Umwälzungen im Zuge der Reformation,
					 die Religions- und die Türkenkriege, Teuerungen und Pestepidemien sorgten immer wieder für Situationen großer politischer, sozialer und ökonomischer Prekarität. 
				Diese spiegeln sich natürlich auch in den Briefwechseln von Theologen, etwa in zahlreichen Zeitklagen. Vor dem Hintergrund konfessioneller Konflikte werden bestimmte 
				Ereignisse aber auch immer wieder als Vorzeichen kommender Katastrophen oder drohender göttlicher Strafen gedeutet.<br/><br/>So berichtet etwa der an der 
				philosophischen Fakultät in Heidelberg lehrende Theologe Victorinus Strigel (1524-1569) in einem Brief an den Dichter und Pädagogen Georg Mauritius (1539-1610) in 
				Wittenberg von einem (in der Forschung bislang kaum bekannten) Brand auf dem Heidelberger Schloss. Am 8. April 1569, so Strigel, brach das riesige Feuer um 1 Uhr nachts 
				aus und wütete bis zum Morgengrauen:<i> Nuper enim octavo die Aprilis ingens incendium in arce Illustrissimi Electoris Palatini excitatum est, quod, cum durasset a prima 
				hora noctis usque ad solis ortum, insigne aedificium absumsit. </i>Der Brand habe an den Schlossbauten durchaus Schäden hinterlassen, durch Gottes Hilfe sei aber das Schlimmste 
				verhindert worden – sonst wäre es um Schloss und Stadt geschehen gewesen:<i> Ac nisi DEUS praesens auxilium suum nobis obtulisset, actum fuisset non modo de arce, sed etiam de 
				toto oppido. </i>Das letztlich glimpflich ausgegangene Spektakel deutet Strigel aber als böses Omen, das Bürger- und andere Kriege ankündige (<i>alia tristiora incendia bellorum 
				civilium et externorum</i>), die viele Kirchen und Staaten in Flammen aufgehen lassen werden (<i>miserabiliter conflagrabunt</i>) – wenn Gott nicht eingreife.<br/><br/>
				Als zweites Beispiel kann ein Brief des Altdorfer Theologieprofessors Edo Hilderich (1533-1599) an Johann Jakob Grynaeus (1540-1617) in Basel vom 17. Oktober 1590 dienen. 
				Inständig, so Hilderich, hofft er auf ein Ende des 8. Hugenottenkriegs, damit die französischen Kirchen Ruhe finden. Angesichts des grausamen Gemetzels der Pariser Bluthochzeit 
				(<i>quam crudelis fuerit laniena Parisiensis in nuptiis regiis</i>) – gemeint ist die sog. Bartholomäusnacht (24. August 1572) – scheint es Hilderich kaum verwunderlich, dass dieser 
				Bürgerkrieg so lange andauert. Er hofft trotzdem auf Gottes Gnade und ein Ende des Krieges – allerdings beobachtet er Beunruhigendes: Wegen des Mangels an Wasser und Regen 
				verteuern sich zum einen die Lebensmittel. Zum anderen haben sich schwere Erdbeben (<i>maximi terrae motus</i>) in Österreich, Schlesien und Meißen ereignet – gemeinhin bekannt als 
				Erdbeben von Neulengbach am 15. September 1590, dessen Epizentrum sich in der Nähe von Wien befand –, die sogar in Nürnberg zu spüren waren. Diese haben zwar zweifellos 
				physikalische Ursachen (<i>habent quasdam caussas physicas</i>), kündigen in Hilderichs Augen aber vor allem kommende Umwälzungen und Strafen für die Gottlosen an.<br/>
					<ItemContainer>
						<img src="bdm24887.jpg" width="100%" alt="" />
						<Row><Space/>Warhafftige vnd Erschröckliche newe Zeitung auß Wien. Einblattdruck, Nürnberg: Georg Lang, 1590&nbsp; 
					<Link to="//nbn-resolving.org/urn:nbn:de:bvb:29-einblattdr-0302-0" target="_blank">(Erlangen UB)</Link></Row>
					</ItemContainer>
					<ItemContainer>
					Weitere Informationen zum Heidelberger Schlossbrand von 1569 bietet:<br/>
					Max Graff: Ein ingens incendium als böses Omen. Victorinus Strigel über den Heidelberger Schlossbrand im Jahr 1569, in: Zeitschrift für die Geschichte des Oberrheins 169 (2021), 
					S. 223-231.
					</ItemContainer>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>November 2022: Darf ein Geistlicher heiraten?</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>31. Dezember 1573, Hemsbach. David Pareus an Zacharias Ursinus</b><br/><Link to="/brief/40775" target="_blank">Brief-ID 40775</Link><br/></Brieftitel>
					Im August 1573 wurde David Pareus Pfarrer des Städtchens Hemsbach an der Bergstraße. Vier Monate später, am Tag nach seinem 25. Geburtstag, richtete er einen Brief 
					an seinen ehemaligen Lehrer am Heidelberger Sapienzkolleg und schlesischen Landsmann, Zacharias Ursinus. Darin lud er diesen zu seiner Hochzeit ein, die in fünf 
					Tagen stattfinden sollte. Das Verhältnis zwischen Pareus und dem 14 Jahre älteren Ursinus war nicht völlig unbelastet: 1571 hatte Pareus das Collegium Sapientiae 
					gegen den Willen seines Lehrers verlassen, um eine Pfarrstelle im südpfälzischen Schlettenbach zu übernehmen, die er nur kurz innehatte. Spuren dieses zwei Jahre 
					zurückliegenden Zwists lassen sich freilich in dem betont höflich gehaltenen Schreiben nicht finden. Auffällig dagegen ist die Mühe, die sich Pareus gibt, um seinen 
					Schritt in die Ehe vor seinem Mentor zu rechtfertigen: Er versichert Ursinus, von den Gefahren einer übereilten und unüberlegten Eheschließung zu wissen und beteuert, 
					nicht von Wollust, sondern von sittlichen, ehrbaren Überlegungen geleitet worden zu sein. Fast entschuldigt er sich für die bevorstehende Eheschließung.<br/><br/>
					Der Kontrast zu den Anfangsjahren der Reformation könnte nicht frappierender sein: Deren erste klerikale Anhänger bekundeten ab Mai 1521 ihre neue evangelische Gesinnung 
					bewusst mit öffentlichen Eheschließungen. Diese demonstrative Verletzung des Zölibatsgelübdes und provokative Missachtung des kanonischen Rechts sollte ihr Verständnis 
					von evangelischer Freiheit unmissverständlich zum Ausdruck bringen. Der „gleisnerischen Keuschheit“ des Klerikerzölibats wurde die „wahre Keuschheit“ des Ehestandes 
					polemisch gegenübergestellt. So wurde ein verheirateter Klerus zum Markenzeichen der Reformation. Von einem evangelischen Pfarrer wurde geradezu erwartet, dass er 
					verheiratet wäre. Vor allem im Jahr 1523 fanden auffällig viele Pfarrerhochzeiten statt, etwa in Erfurt, Würzburg, Straßburg, Augsburg, Worms, Eisenach und Basel. Genau 
					50 Jahre später gibt Pareus’ Brief an Ursinus Zeugnis von einem Mentalitätswandel: Die Vehemenz und Unnachgiebigkeit, die zu Beginn der Reformation auf die Frage „Darf 
					ein Geistlicher heiraten?“ nur eine uneingeschränkt positive Antwort kannten, sind Zögerlichkeit und Selbstzweifel gewichen.<br/><br/>
					Stilisierte Darstellung der öffentlichen Hochzeit des Propsts von Kemberg bei Wittenberg, Bartholomäus Bernhardi, 1521:
					<ItemContainer>
						<img src="bdm40775.jpg" width="50%" alt="" />
						<Row><Space/>VD16 ZV 2155,&nbsp; 
					<Link to="//digital.slub-dresden.de/werkansicht/dlf/21391/1" target="_blank">SLUB Dresden</Link></Row>
					</ItemContainer><br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Oktober 2022: Olympia Fulvia Morata – eine <i>foemina Itala</i> in Heidelberg</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>17. August 1556, Heidelberg. Johannes Flinner an Konrad Hubert in Straßburg</b><br/><Link to="/brief/16533" target="_blank">Brief-ID 16533</Link><br/></Brieftitel>
					Während eines Kuraufenthalts in Baden-Baden Anfang Juli 1556 gelang es dem pfälzischen Kurfürsten Ottheinrich (1502–1559), den ebenfalls dort weilenden 
					Straßburger Prediger Johannes Flinner (1520–1578) für eine Stelle als Prediger am Heiliggeiststift in Heidelberg zu gewinnen. Ottheinrich war entschlossen, 
					die Reformation in der Kurpfalz einzuführen, und hoffte auf die tatkräftige Mitarbeit Flinners sowie diejenige seines Kollegen Johannes Marbach (1521–1581). 
					Wenige Wochen nach seiner Ankunft in Heidelberg berichtete Flinner seinem Straßburger Freund Konrad Hubert über einen interessanten Fund an seinem neuen 
					Wirkungsort: <br/>
					Neulich sei er unter den Briefen des italienischen Humanisten und Basler Rhetorikprofessors Celio Secondo Curione (1503–1569) auf den 46. Psalm gestoßen, 
					der von einer italienischen Frau in lieblichem sapphischen Versmaß verfasst worden sei. Diese Frau sei in diesem Jahr in Heidelberg gestorben. Man nannte sie 
					Olympia. Es heißt, sie habe weitere solcher Dichtungen hinterlassen. Er wolle ihre Werke aufspüren und ihm einige davon schicken.<br/><br/>
					<i>Inveni nuper inter Epistolas Coelii S. Curionis Psalmum 46 tam dulci saphico compositum a foemina Itala, quae hic obiit hoc anno, Olympia dicta fuit, quae alia 
					quoque dicitur post se reliquisse, Ergo dabunt mihi occasione, ut plura hic indagem et tibi placentia aliquando mittam.</i>
					<ItemContainer>
						<img src="bdm16533.jpg" width="100%" alt="" />
					</ItemContainer><br/>
					Die <i>foemina Itala</i>, deren griechische Übertragung des 46. Psalms Flinner so viel Bewunderung abverlangte, war tatsächlich nach allen Maßstäben der Zeit eine 
					Ausnahmeerscheinung: Olympia Fulvia Morata (1526–1555) genoss am Hofe der Este in Ferrara eine vorbildliche philologische Ausbildung, beherrschte bereits als 
					15-jährige ciceronianisches Latein, begann kurz danach, Briefe und Gedichte auf Griechisch zu verfassen, wandte sich unter dem Einfluss ihres Vaters dem 
					evangelischen Glauben zu, heiratete 1550 den in Italien weilenden Schweinfurter Arzt Andreas Grundtler, floh zusammen mit ihm vor der Inquisition in seine 
					Heimatstadt, wo er Stadtarzt wurde. Während dieser Zeit führte sie eine ausgiebige lateinische Korrespondenz mit italienischen und deutschen Humanisten. <br/>
					Im Zuge der Zerstörung Schweinfurts im Zweiten Markgrafenkrieg (Juni 1554) verloren Olympia Morata und ihr Mann ihr gesamtes Hab und Gut, doch gelang ihnen die 
					Flucht zu den Grafen von Erbach. Noch im Juli 1554 wurde Grundtler zum Professor der Medizin nach Heidelberg berufen. Bald erfolgte die Einladung Kurfürst 
					Friedrichs II. (1482–1556) an Olympia Morata, <i>ut Graecas literas doceat</i>. Ob sie an einem Universitätslehrstuhl oder in ihrer Heidelberger Wohnung Griechisch 
					unterrichtete, kann nicht mehr zweifelsfrei festgestellt werden. Jedenfalls starb sie bereits am 26. Oktober 1555, von den erlittenen Entbehrungen gezeichnet. 
					Sie war erst 29 Jahre alt.<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>September 2022: Berufung abgelehnt! Eine Karriere in Württemberg</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="alpirsbach.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/wiki/File:Alpirsbach_Kloster.jpg" target="_blank">Kloster Alpirsbach (Quelle: Wikimedia)</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>[8. Januar 1580], Johannes Brenz d. J. an Herzog Ludwig von Württemberg</b><br/><Link to="/brief/66312" target="_blank">Brief-ID 66312</Link><br/></Brieftitel>
					Johannes Brenz der Jüngere (1539-1596), ältester Sohn des gleichnamigen württembergischen Reformators, studierte wie sein Vater Theologie. Seine Karriere
					 startete vielversprechend: Mit 23 Jahren wurde er in Tübingen zum Doktor der Theologie promoviert und noch im gleichen Jahr erhielt er die neu eingerichtete
				 	vierte (außerordentliche) Professur an seiner Alma Mater. Zugleich fungierte er als zweiter Superattendent des Tübinger Stifts, der württembergischen 
					Ausbildungsstätte für den Kirchendienst. Nach diesem rasanten Start tat sich dann fast 20 Jahre lang nichts in Brenz’ Karriere. Erst als 1580 Johannes Stecher, der Abt des Klosters Alpirsbach, auf 
					die Propstei des Klosters Denkendorf wechselte, eröffnete sich eine neue Perspektive.<br/><br/>
					Alpirsbach im Nordschwarzwald zählte zu den 14 großen württembergischen Männerklöstern, die von Herzog Christoph zu evangelischen Klosterschulen umgeformt 
					worden waren, um dort Nachwuchs für Kirche und Staat auszubilden. Die evangelischen Äbte fungierten nun als Rektoren evangelischer Lehranstalten. Darüber 
					hinaus waren sie als Prälaten im württembergischen Landtag repräsentiert. Die Prälaturen der württembergischen Klöster hatten also sowohl politisches Gewicht 
					als auch eine bedeutende konfessionelle Funktion und insgesamt ein hohes Renommee im Land.<br/><br/>
					Als der Prälat Johannes Stecher Anfang 1580 nach Denkendorf wechselte, verhandelten Landhofmeister, Kanzler, Propst und andere württembergische Räte über die 
					Neubesetzung der Alpirsbacher Prälatur und zogen für diese Position auch Johannes Brenz den Jüngeren in Betracht. Im Auftrag Herzog Ludwigs von Württemberg trugen die Räte Brenz am 7. und 8. Januar 1580 die Nachfolge in Alpirsbach an. Der Berufene wusste, was er wollte 
					und schrieb umgehend einen ausführlichen Brief an den Herzog. Darin dankte er zunächst für das unterbreitete Angebot. Obwohl er in der Berufung die 
					Wertschätzung des Herzogs für seine 18-jährige Tätigkeit im Dienst des Tübinger Stifts sah, lehnte er sie aus mehreren Gründen ab. In selbstbewusstem Ton 
					erklärte er:<br/>
					Erstens sei ihm einst von Herzog Christoph zugesagt worden, bei nächster Gelegenheit eine ordentliche Professur an der Universität Tübingen übertragen zu 
					bekommen. Hierauf bereite er sich nun seit Jahren vor, unter anderem, indem er Jakob Andreae in dessen Lektur zu den Paulusbriefen vertrete. Zweitens sei er 
					in Tübingen derzeit damit befasst, die Werke seines Vaters herauszugeben. Drittens sei er mit Anfang 40 noch recht jung und habe nach wie vor Freude daran, 
					in Kirche und Schule Dienst zu tun. Wenn er später einmal älter und müder sei und der Herzog ihm dann erneut eine Prälatur anbieten würde, nähme er diese 
					gerne an. Viertens benötige er die Stelle in Tübingen, um seinen zwei Söhnen das Studium zu ermöglichen, und schließlich habe er Besitzungen in der Nähe.
					Brenz bittet um Verständnis für seine Situation und bekräftigt zugleich seinen Wunsch, endlich eine ordentliche Professur in Tübingen zu erhalten.<br/><br/>
					Von Brenz’ deutlicher Absage ließ sich Herzog Ludwig jedoch nicht beeindrucken. Er schickte seine Räte erneut zu Brenz mit dem Auftrag, diesen umzustimmen. 
					Dieser erbat sich zwar Bedenkzeit aus, aber auch nachdem diese verstrichen war, blieb er dabei, das Stellenangebot aus den genannten Gründen abzulehnen und 
					auf die Fortsetzung seiner Karriere an der Universität Tübingen zu setzen. Diese Hoffnung sollte Brenz auf eine harte Probe stellen, denn er musste noch weitere zehn Jahre warten, bis er 1590 im Alter von 51 Jahren schließlich die 
					ordentliche Professur des dritten Lehrstuhls für Theologie in Tübingen erhielt. Auf dieser Position blieb er dann nur rund ein Jahr, bis er 1591 doch noch 
					eine Prälatur übernahm, und zwar diejenige des Klosters Hirsau. Hier amtierte er bis zu seinem Tod im Jahre 1596 noch fünf Jahre.<br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>August 2022:<i> pessime pingit </i>– Hieronymus Zanchis schwer lesbare Handschrift</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>26. August 1570, [Heidelberg]. Zacharias Ursinus an Johannes Crato von Krafftheim</b><br/><Link to="/brief/19773" target="_blank">Brief-ID 19773</Link><br/></Brieftitel>
					Zacharias Ursinus korrespondierte häufig mit seinem engen Freund und Breslauer Landsmann, dem kaiserlichen Leibarzt Johannes Crato von Krafftheim (1519–1585). 
					Dieser wünschte sich, in Briefaustausch mit dem Italiener Hieronymus Zanchi (1516–1590), Ursinusʼ Kollegen an der Heidelberger Universität, zu treten und bat 
					Ursinus um Vermittlung. In einem Brief vom 26. August 1570 lässt Ursinus Crato wissen, dass Zanchi vermutlich auf die Bitte eingehen werde. Diese gute Nachricht 
					versieht Ursinus allerdings mit einer kleinen Warnung:<i> pessime pingit </i>– Zanchi habe eine äußerst schlechte Handschrift.<br/><br/>
					Diejenigen, die sich heute mit der Entzifferung von Zanchis Handschrift abmühen, sind vielleicht erleichtert zu erfahren, dass es seinen Zeitgenossen nicht anders 
					ging. Eine noch größere Erleichterung bereitet ihnen aber gewiss die Entdeckung, dass sogar Johann Wilhelm Baum (1809-1878), der große Reformationshistoriker 
					Straßburgs und geübter Transkriptor tausender Reformatorenbriefe („Thesaurus Baumianus“), mit der Handschrift Zanchis ebenfalls seine liebe Mühe hatte. Wer heute 
					Zanchis Briefe im Straßburger Stadtarchiv durchblättert, macht an zahllosen Stellen folgende Beobachtung: Baums Bedürfnis, das mühevoll Entzifferte für alle Zeiten 
					festzuhalten, obsiegte über die konservatorische Selbstverständlichkeit, in archivalischen Beständen niemals handschriftliche Eintragungen vorzunehmen.			
					<br/>
					Entzifferungsnotizen Baums in einem Brief Hieronymus Zanchis an Konrad Hubert (1507–1577) vom 28. Juni 1566 aus Chiavenna (Brief-ID 19153):
					<ItemContainer>
						<img src="bdm19773.jpg" width="100%" alt="" />
						<Row>Straßburg StA, 1 AST 162, S. 582</Row>
					</ItemContainer>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juli 2022: Eine kleine Enttäuschung für einen großen Käseliebhaber</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>20. Dezember 1578, Heidelberg. Thomas Erastus an Johann Jakob Grynaeus in Basel</b><br/><Link to="/brief/21996" target="_blank">Brief-ID 21996</Link><br/></Brieftitel>
					Die Briefe von Theologen sind nicht nur eine zentrale Quelle für den intellektuellen Austausch unter Gelehrten, sondern auch hervorragende Zeugnisse für 
					materielle Kultur und Konsumgüter aller Art. Dazu gehört auch die Beschaffung und der Verzehr regionaltypischer Nahrungsmittel wie Schweizer Käse, der 
					insbesondere in Heidelberg von Thomas Erastus nachgefragt wurde.<br/><br/>
					Thomas Erastus (1524–1583) stammte aus Baden im Aargau, hatte in Padua und Bologna Medizin studiert und sich anschließend als Arzt einen Namen gemacht. Nach 
					einem dreijährigen Aufenthalt am Hof der Thüringer Grafen von Henneberg wurde er vom Pfälzer Kurfürsten Ottheinrich 1558 auf die zweite Professur für Medizin 
					nach Heidelberg berufen. Erastus lebte mehr als 20 Jahre in der Universitätsstadt.<br/>
					In dieser Zeit ließ er sich regelmäßig Käse aus seiner Heimat kommen. Als Kontaktperson fungierte sein Schwager Johann Jakob Grynaeus, der zunächst Pfarrer im 
					südbadischen Rötteln und seit 1575 Professor der Theologie in Basel war. Der Käseliebhaber Erastus bat Grynaeus regelmäßig um Kauf und Zusendung von Schweizer 
					Hartkäse in übersichtlichen Mengen von zwei, vier oder fünf Laiben, die er überwiegend selbst verzehrte, gelegentlich aber auch etwas an seine Heidelberger 
					Kollegen und Freunde weitergab. Daneben bestellte er häufig fetten Zieger, einen weichen Säuremilchkäse, ähnlich dem Ricotta.<br/><br/>
					Erastus war ein anspruchsvoller Konsument, er bevorzugte Käse aus Schwyz und nicht etwa aus Saanen oder dem Haslital im Bernerland, der seinem Gaumen nicht 
					zusagte. Welche Käsesorte Erastus genau präferierte, geht aus seinen Briefen nicht hervor. Geschmacklich liebte er gereiften, kräftigen, rezenten Käse und 
					weißen Zieger, der wenig gesalzen, aber nicht fad sein sollte und der auf gar keinen Fall Kümmel enthalten durfte. Beim Käsegenuss hatte Erastus hohe Ansprüche 
					und bevorzugte Ware bester Qualität (<i>ex optimis</i>). Geld spielte für den gutbezahlten Professor dabei keine Rolle, zumindest machte er in seinen Briefen kein 
					Aufhebens um Preise – weder für die Ware selbst noch für Verpackung, Transport und Zoll.<br/><br/>
					Der Käse, der in Fässern verpackt und in Basel zunächst auf Schiffe geladen wurde, die rheinabwärts fuhren, gelangte auf dem Wasser- oder Landweg nach 
					Heidelberg. War die Ware eingetroffen, berichtete Erastus seinem Schwager im nächsten Brief, in welchem Zustand sich der Käse befand (im Dezember 1572 kam der 
					Zieger einmal gefroren bei ihm an!), welche Güte er hatte und natürlich auch, ob er seinen Geschmack getroffen hatte.<br/>
					Da Käse ein verderbliches Lebensmittel war, kam es gelegentlich vor, dass die Ware auf dem Transportweg litt. Am 20. Dezember 1578 bestätigte Erastus Grynaeus, 
					die bestellten Milchprodukte erhalten zu haben (<i>caseum et zigerum hodie accepi</i>). Der Zieger weise allerdings Mängel auf, für die Grynaeus als Mittelsmann jedoch 
					keine Verantwortung trage (<i>zigerus non est bonus, vitium ex vetustate et praeparatione contraxit, cuius rei tu explorator esse non potuisti</i>). Um die Sache noch 
					deutlicher zu machen und auf einen offensichtlichen Produktionsfehler hinzuweisen, führt Erastus seine Mängelbeschreibung noch einmal in deutscher Sprache aus:
					<i> Er hatt ein spalt durch under, ist nit vest zusamen getruckt im machen vnd ist also graw vnd grün in dem spalt worden. Oben ist er veraltet, sonst ist er nit böß. </i>
					Dort, wo der Käse durch den Herstellungsfehler aufgebrochen war, scheint er nicht mehr genießbar gewesen zu sein. Der übrige Laib war aber offenbar noch 
					akzeptabel, so dass die Lieferung letztlich also nur eine kleine Enttäuschung für den großen Käseliebhaber war.<br/>
					<ItemContainer>
							<img src="stilleben.jpg" width="100%" alt="" />
						<Row><Link to="//www.staatsgalerie.de/g/sammlung/sammlung-digital/einzelansicht/sgs/werk/einzelansicht/29BB80BB4655B9A5D39BCCBBF6BCCB18.html" target="_blank">Flegel, Stilleben, 1635 (Staatsgalerie Stuttgart)</Link></Row>
					</ItemContainer>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juni 2022: Die Kurpfalz – ein „kleines Italien“?</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>17. September 1556, Dirmstein. Johannes Flinner an Konrad Hubert in Straßburg</b><br/><Link to="/brief/16753" target="_blank">Brief-ID 16753</Link><br/></Brieftitel>
					Deutsche Italiensehnsucht reicht viel weiter zurück als Johann Wolfgang von Goethe sie in seiner Italienischen Reise (1786-1788) beschrieb. 
					Das mehr als sporadische Vorkommen der Themen „Italienreise“ oder „Studium in Italien“ in den Briefen des Theologenbriefwechsels lässt dies erahnen.
					Ihr Pendant findet diese Sehnsucht in der Neigung, einer deutschen Region zu schmeicheln, indem man sie mit Italien vergleicht. Während der Dresdener 
					Theologe Ferdinand Florens Fleck schon 1837 die Überzeugung äußerte, Sachsen sei die „Toskana Deutschlands“, nehmen  heute viele deutsche Urlaubsregionen, 
					etwa das Markgräflerland, der Kraichgau und nicht zuletzt die Pfalz, dieses Prädikat für sich in Anspruch. Doch bereits vor 466 Jahren ließ sich ein von der 
					Kurpfalz entzückter fremder Besucher, der aus Augsburg stammende Straßburger Prediger Johannes Flinner, dazu hinreißen, diesen Landstrich in einem Brief an 
					seinen Kollegen Konrad Hubert als „von allen das beste“ und „sozusagen ein kleines Italien“ zu beschreiben.<br/><br/>
					Dieses Lob fand allerdings vor dem Hintergrund einer Klage statt. Seit dem 9. August 1556 hielt sich Flinner zusammen mit seinem Straßburger Kollegen Johannes 
					Marbach auf einer vom pfälzischen Kurfürsten Ottheinrich in Auftrag gegebenen Kirchenvisitation in der Kurpfalz auf. Als Flinner seinen Brief an Hubert schrieb 
					(17. September 1556), hatten die Visitatoren schon Besuche in Mosbach, Bretten (beide damals pfälzisch), Germersheim, Neustadt und Kaiserslautern hinter sich 
					und befanden sich gerade in Dirmstein bei Worms. Im Zuge dieser Besuche waren sie auf eine für sie alarmierend hohe Zahl von Täufern gestoßen – Anhänger jener 
					nonkonformistischen Laienbewegung, die Luther und Zwingli schon früh entschieden ablehnten und deren Anhänger seit 1529 reichsweit unter Todesstrafe standen. 
					Dies führte Flinner zu folgender trauriger Feststellung:<br/><br/>
					„Übel ist der Anblick der Kirche wegen des Unflats der Täufer, die sich bei der großen Nachlässigkeit der kirchlichen und weltlichen Obrigkeit eingeschlichen haben, 
					wo doch dieses Land sonst von allen das beste und sozusagen ein kleines Italien ist.“<br/>
					<i>Misera Ecclesiae facies est circumcirca propter colluviem Anabaptistarum, qui in tanta Magistratus Ecclesiastici et Politici negligentia subintroierunt, cum alioqui 
					haec terra sit omnium optima et quasi parva Italia.</i>
					<ItemContainer>
						<img src="bdm16753.jpg" width="100%" alt="" />
						<Row><Space/>Straßburg StA, 1 AST 156, S. 247</Row>
					</ItemContainer>
					Über ein Jahr später war die Klage über das Auftreten der Täufer in der Kurpfalz immer noch ein aktuelles Thema. Dies zeigt ein Brief, den Marbach zusammen mit einem 
					kurpfälzischen Amtmann und einem Heidelberger Hofprediger am 18. Oktober 1557 an Kurfürst Ottheinrich schrieb und der als Nr. 5 im Band ThBW 2 (Kurpfalz I) erscheinen 
					wird.
					<br/><br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Mai 2022: Ohrfeigen und Würfelspiele – immer Ärger mit den Studenten!</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>13. Juli 1583, Altdorf. Edo Hilderich von Varel an Hieronymus Baumgartner d. J. in Nürnberg</b><br/><Link to="/brief/23402" target="_blank">Brief-ID 23402</Link><br/></Brieftitel>
					Im Juli 1583 sieht sich Edo Hilderich (1533-1599) zu einem Verteidigungsschreiben genötigt. Hilderich, der wenige Jahre zuvor, nach seiner
					Weigerung, die Konkordienformel zu unterzeichnen, die Universität Heidelberg verlassen hatte, lehrt seit 1581 als Theologieprofessor in 
					Altdorf. Offenbar kursieren nun in Nürnberg Gerüchte, die nicht nur seine Reputation, sondern auch die der Altdorfer Universität zu kompromittieren 
					drohen – und so schreibt Hilderich am 13. Juli 1583 einen Brief an den einflussreichen Nürnberger Patrizier Hieronymus Baumgartner d. J. (1538-1602).
					<br/><br/>
					Angeblich hat ihn jemand, dessen Namen Hilderich bewusst nicht nennt – es handelt sich wohl um den Nürnberger Prediger Johannes Schelhammer – 
					verleumdet. In der Folge weist er die gegen ihn erhobenen Vorwürfe zurück. Den ersten, dass er nämlich Schelhammers Sohn Jeremias, der in Altdorf 
					studierte, von seiner Tischgesellschaft ausgeschlossen habe (<i>quod a mensa et convictu meo filium eiecerim et expulerim</i>), versucht Hilderich 
					aufzuklären. Jeremias habe ohne triftigen Grund eine öffentliche Vorlesung des Theologen versäumt; als er danach zum Mittagessen kam, wies ihn
					 Hilderich scharf zurecht und berührte, um seine Ernsthaftigkeit zu betonen, mehrmals mit der Hand leicht den Kopf des Schwänzers – eher als 
					dass er ihn schlug: <i>leviter eius caput magis tetigi quam percussi</i>. Die anwesenden Kostgänger können bezeugen, dass er mit diesen „Schlägchen“ 
					(percussiuncula) die Grenzen des Erziehers nicht überschritten hat. Nach diesem Tag blieb der freche Student (<i>petulans et ociosus hic adolescens</i>) 
					der Tafel und auch Hilderichs Vorlesungen fern – angeblich mit Wissen seines Vaters. Am Verhalten des Sohnes sei er also schuldlos.
					<br/><br/>
					Den zweiten Vorwurf, seine Schüler gäben sich dem Spiel hin (<i>crimen de lusu meorum discipulorum</i>), versucht Hilderich folgendermaßen zu entkräften: 
					Ein Student aus Braunschweig, der einige Wochen vor Ostern ein Spielbrett (<i>tabella lusoria</i>) gekauft hatte, gab dieses einigen von Hilderichs 
					Kostgängern mit nach Nürnberg. Nach etwa einer Woche überraschte Hilderichs Ehefrau die Studenten beim Würfelspiel, ermahnte sie und drohte, dass 
					Hilderich das Spielbrett ins Feuer werfen würde, sollten sie noch einmal damit erwischt werden: <i>ut ego resciscens eam in ignem coniicerem</i>. Seitdem 
					haben die Kostgänger das Spielbrett nicht mehr benutzt. Allerdings ist so das verleumderische – und mit diesem Brief zurückgewiesene – Gerücht 
					entstanden, Hilderichs Schüler würden nur spielen und der Theologe würde ihnen statt privater Vorlesungen das Spielen beibringen (<i>ego loco 
					privatarum praelectionum cum eisdem lusum exercuerim</i>).<br/>
					<ItemContainer>
							<img src="student.jpg" width="100%" alt="" />
						<Row><Space/>Darstellung eines liederlichen Studenten:&nbsp;<Link to="//gallica.bnf.fr/ark:/12148/bpt6k134344t/f33.item" target="_blank">Heyden, Speculum, Straßburg 1618 (BNF)</Link></Row>
					</ItemContainer><br/>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>April 2022: Finde die Jahreszahl! Ein gelehrtes Rätsel in einem lateinischen Brief</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>3. Februar [1615], Jebsheim. Egenolf III. von Berckheim an Johann Jakob Grynaeus</b><br/><Link to="/brief/49219" target="_blank">Brief-ID 49219</Link><br/></Brieftitel>
					In der Frühen Neuzeit waren Briefe das Medium für Nachrichten, die nicht persönlich übermittelt werden konnten. Zugleich bot das Briefschreiben 
					vielen Gelehrten eine willkommene Gelegenheit, die eigene Bildung unter Beweis zu stellen. Sie schrieben meist auf Lateinisch und wurden somit 
					über Ländergrenzen hinweg verstanden. Den Humanisten galt der Brief gleichsam als Ersatz für das persönliche Gespräch, ein Gedanke, der sich 
					schon in der Antike findet, etwa bei Cicero (<i>amicorum conloquia absentium</i>, 2. Philippische Rede 7), und der in der Renaissance in der Brieflehre 
					des Erasmus von Rotterdam wieder aufgegriffen wurde.<br/>
					Ein schönes Beispiel der facettenreichen frühneuzeitlichen Briefkultur begegnet uns in einem Schreiben des elsässischen Adligen Egenolf von 
					Berckheim (1552-1629). Er antwortete damit auf einen Brief des Basler Theologen und Kirchenvorstehers Johann Jakob Grynaeus (1540-1617). Egenolf 
					von Berckheim erwähnt, dass er einen Brief von Grynaeus erhalten hat, den dieser am 22. November 1614 geschrieben hatte. Dann gibt Berckheim das 
					Empfangsdatum in der folgenden Form an:<i> post Epiphanias 9. Janu[arii] hoc anno NatI DeI qVI est ManIfestatVs In Carne accepi</i>.<br/><br/>
					<ItemContainer>
							<img src="chronogramm.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//swisscollections.ch/Record/991170478179505501/Holdings?openHierarchy=true#tabnav" target="_blank">Basel UB, G II 2, S. 12</Link>
					</ItemContainer>
					Zunächst also die Angabe „nach Epiphanias am 9. Januar in diesem Jahr“ - aber wie dann weiter? Egenolf von Berckheim deutet selbst die Antwort an: 
					In der Wortgruppe<i><b> NatI DeI qVI est ManIfestatVs In Carne</b></i> sind sogenannte Zahlbuchstaben zu finden – hervorgehoben durch Versalien (Großbuchstaben), 
					die jeweils für ein römisches Zahlzeichen stehen. Auf Latein nennt man sie<i> literae numerales</i>. 
					Darauf wurde Egenolf von Berckheim seinen eigenen Worten zufolge durch Bartholomäus Piscator aufmerksam, einen Kirchendiener in Sundhoffen an der Ill. 
					Für ein geschultes Auge sind die Versalien in der hier abgebildeten Briefhandschrift gut erkennbar. Aber was wird damit ausgesagt? <br/>
					Reihen wir die Zahlbuchstaben aneinander:<b> I D I V I M I V I C</b>. Dann sortiert man die Zahlzeichen in absteigender Reihenfolge. Es ergibt sich folgendes Bild:
					<b> M D C V V I I I I I. </b>Nun werden die Zahlen addiert: M (1000) + D (500) + C (100) + zweimal V (10) + fünfmal I (5) = 1615. Rätsel gelöst! 1615 ist das 
					Jahr, in dem Egenolf von Berckheim den Brief seines Korrespondenzpartners Grynaeus erhielt. An den Schluss seines eigenen Briefes setzte Egenolf von 
					Berckheim dann das folgende Datum:<i> Ibshemii 3. Feb[ruarii] Anno ut in literis </i>– eine Aufforderung an Grynaeus, die kryptische Angabe aufzulösen. 
					Man darf annehmen, dass Grynaeus dies gelang, denn solche Verschlüsselungsphänomene, Chronogramme genannt, waren unter Gelehrten seit dem Mittelalter 
					gebräuchlich und beliebt.<br/>
					<Unterschrift>Judith Steiniger</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>März 2022: Der gleißende Stern (Supernova 1572)</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="supernova.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/wiki/File:Tycho_Cas_SN1572.jpg" target="_blank">Brahe, Tycho, De nova stella, 1573</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Tübingen, 21. Dezember 1572: Jakob Andreae an Herzogin Sophie von Braunschweig-Wolfenbüttel</b><br/><Link to="/brief/20466" target="_blank">Brief-ID 20466</Link><br/></Brieftitel>
					Im 16. Jahrhundert waren Himmelserscheinungen noch in vielerlei Hinsicht unbekanntes Terrain und Anlass für religiöse Deutungen. Ähnlich wie 
					schon der Komet C/1556 D1 (s. Brief des Monats Januar 2022) zog auch die Supernova, die sich am 11. November 1572 ereignete, die Aufmerksamkeit 
					der gelehrten Welt auf sich. Ihre Beobachter nahmen das Phänomen als „neuen Stern“ (lat. stella nova) wahr und prägten damit auch den Begriff 
					der Nova. Der dänische Astronom Tycho de Brahe stellte das herrschende, aristotelische Weltbild in Frage, laut dem die Ereignisse am Himmel 
					festgeschrieben seien und keine Änderungen durchliefen.<br/><br/>
					Auch unter den süddeutschen Theologen fand die Supernova großes Interesse. Manche betrachteten das Himmelsereignis mit Sorge (so z. B. der 
					kurpfälzische Theologe Zacharias Ursinus, der befürchtete, dass es Unheil ankündigte, vgl. Brief-ID 20348). Der Tübinger Theologe und Konkordienmann 
					Jakob Andreae dagegen deutete die Supernova als Zeichen der nahenden Herrlichkeit Gottes und nahm sie argumentativ für sich ein.<br/><br/>
					Weil im Herzogtum Braunschweig-Wolfenbüttel der um Vermittlung bemühte Theologe Nikolaus Selnecker dem deutlich schärfer auftretenden Timotheus 
					Kirchner weichen sollte, schrieb Herzogin Sophie voller Sorge an Andreae. Dieser bestärkte in seinem Brief vom 21.&nbsp;Dezember 1572 ihre Befürchtung, 
					dass der Personalwechsel in ihrem Herzogtum für einigen Unfrieden sorgen könnte. Doch er riet der Herzogin, die ins Land strömenden Unruhestifter 
					gewähren zu lassen und geduldig zu ertragen. Denn<i> am Himmel sich ein Newer vngewonlicher stern sehen lassen, dergleichen niemals geschehen, wölcher 
					klärer vnd schöner ist denn khein Stern am Himmel, wölchs meines erachtens das Zeichen deß Menschen Sons ist, daruon geschrieben stehet Matth. 24. 
					Capittel. </i>Diesen Stern – so Andreaes Überzeugung – habe Gott selbst als Zeichen der nahenden Herrlichkeit und Erlösung der Menschen
					<i> vom Himmel herab prediget. </i>Bis es so weit sei, sollten aber alle weiterhin<i> nach christlichem friden nicht anderst trachten alls wann die 
					Wellt sollt ewig stehn. </i>Den Lohn für diese Friedensarbeit würden sie (mutmaßlich schon bald) am jüngsten Tag erhalten.<br/>
					<Unterschrift>Stefan Aderhold</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Februar 2022: Familiäre Schicksale und Trauerbewältigung</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="andreae1.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//digi.ub.uni-heidelberg.de/diglit/bibliotheca_chalcographica1652bd8/0019" target="_blank">Jakob Andreae<br/>(Boissard 1652)</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Esslingen, 5. September 1571: Jakob Andreae an Herzogin Sophie von Braunschweig-Wolfenbüttel</b><br/><Link to="/brief/20274" target="_blank">Brief-ID 20274</Link><br/></Brieftitel>
					Der württembergische Theologe Jakob Andreae (1528-1590) war Zeit seines Lebens darauf fokussiert, die Streitigkeiten unter den lutherischen Theologen zu 
					schlichten und eine reichsweite Einigung zu erzielen. Dieser schweren und undankbaren Aufgabe war er so verbunden, dass er unermüdlich Briefe verfasste 
					und zahlreiche Reisen auf sich nahm. Oft klagte er über körperliche Beschwerden, fürchtete nicht selten um sein Leben und war über lange Zeiträume von 
					seiner Familie getrennt. Mit seiner Frau war er bis zu ihrem Lebensende 37 Jahre lang verheiratet und zeugte 18 Kinder, von denen jedoch nur 10 das 
					Erwachsenenalter erreichten. Andreae berichtete in seiner Autobiographie, dass er in seiner Frau Anna angesichts der schmerzvollen Erfahrungen Beständigkeit 
					und Trost fand: <i>cum qua aetatem summa concordia, et mutuo domesticae calamitas solamine vigit</i> (Ehmer, Leben des Jakob Andreae, S. 26).<br/><br/>
					In den Jahren um 1571 stand er vor dem Scherbenhaufen seines ersten großen Einigungsversuchs. Um nicht noch größere Zwietracht zu säen, sollte er sich auf 
					herzoglichen Befehl über die Kritik seiner Gegner nicht öffentlich äußern. An Herzogin Sophie von Braunschweig-Wolfenbüttel, zu der er ein gutes und erstaunlich 
					persönliches Verhältnis pflegte, schrieb er jedoch sehr offen über seine Situation. Trotz seiner schwierigen Lage hat sein Brief vom 5. September 1571 einen 
					hoffnungsvollen Grundton. Dieser ändert sich auch nicht, als er am Ende des Briefs von einem schweren Schicksal in seiner Familie berichtet:<br/>
					<i>Es hatt mir der Allmechtig in meinem abwesen zwey kinder auß disem Jamerthal abgesondert,</i> schreibt er und lässt damit vermuten, dass er den Tod seiner Kinder 
					in gewisser Weise auch als Erlösung von den Leiden der Welt verstand. Und weiter: <i>aber meine liebe haußfraw wider mitt leibsgesundhaitt vnd frucht gesegnet, das 
					jch jn Monats frisch verhoff, Es soll durch Gottes gnad die Hal wider erfüllet werden.</i> Andreae, gezeichnet vom Leben, fand in der Hoffnung auf eine 
					verheißungsvollere Zukunft, der Vorfreude auf ein weiteres Kind und im Vertrauen auf Gottes Beistand Trost.<br/>
					<Unterschrift>Stefan Aderhold</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Januar 2022: Zeichen am Himmel – Komet C/1556 D1</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
					<b>Wittenberg, 22. März 1556: Zacharias Ursinus an Johannes Crato</b> (<Link to="/brief/16691" target="_blank">Brief-ID 16691</Link>)<Space/><br/>
					<b>Stuttgart, 12. April 1556: Johannes Brenz an Andreas Aurifaber</b> (<Link to="/brief/16509" target="_blank">Brief-ID 16509</Link>)<br/></Brieftitel>
					Gelegentlich kommt es vor, dass südwestdeutsche Theologen des 16. Jahrhunderts in ihrem Briefwechsel auf Geschehnisse Bezug nehmen, die auch die Aufmerksamkeit der 
					Menschen im restlichen Europa auf sich zogen. Aber nur selten kommt es vor, dass sie ein Ereignis erwähnen, das sogar in der ganzen Welt, etwa im fernen China, 
					zeitgleich beobachtet wurde. Dazu gehört die Sichtung des von heutigen Wissenschaftlern unter dem Namen C/1556 D1 bekannten großen Kometen von 1556 durch Zacharias 
					Ursinus und Johannes Brenz.<br/><br/>
					Am 22. März 1556 schrieb Ursinus, zu dem Zeitpunkt 21-jähriger Student in Wittenberg, seinem Freund Johannes Crato in Breslau, dass am 5. März ein Komet unweit vom 
					Stern Spica im Sternbild Jungfrau gesichtet worden sei, der sich langsam in Richtung Kleiner Bär bewege.<br/>
					Wesentlich beunruhigter klang der 56-jährige württembergische Stiftsprediger Brenz, der am 12. April dem Königsberger Mediziner Andreas Aurifaber von der Sichtung 
					desselben Phänomens in Stuttgart berichtete. Um die Auswirkung des Kometen auf die Menschen zu beschreiben, greift Brenz auf Psalm 2,1f. zurück: „Die Heiden toben, 
					die Völker sinnen Nichtiges, die Könige der Erde erheben sich und schließen sich zusammen gegen den Herrn und seinen Gesalbten“.<br/>
					Der portugiesische Dominikaner Gaspar da Cruz (1520-1570), der sich zu dieser Zeit in China aufhielt und den Kometen ebenfalls beobachtet, meinte einen Zusammenhang 
					zwischen dessen Erscheinen und dem unmittelbar vorangegangenen verheerenden Erdbeben von Shaanxi zu entdecken.<br/><br/>
					Später entstand sogar der Mythos, dass Kaiser Karl V. sich durch den Kometen veranlasst sah, die kaiserliche Gewalt an seinen Bruder Ferdinand abzutreten und sich in 
					die monastische Einsamkeit von Yuste zurückzuziehen.<br/>
					Der in Wien residierende kaiserliche Mathematiker Paul Fabricius (1529-1589) verfolgte den Kometen schon früh und hielt ihn in einer Zeichnung fest, die später gedruckt 
					wurde: Man sieht den Weg des Kometen vom Sternbild Jungfrau unten links polwärts nach oben rechts wandern, wo Fabricius ihn zuletzt Ende April 1556 beobachtete.
					<br/>
						Gedruckte Wiedergabe der Zeichnung des kaiserlichen Hofmathematikers Paul Fabricius (1529-1589):
						<ItemContainer>
							<img src="komet.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/w/index.php?curid=10718901" target="_blank">Quelle: Wikimedia</Link>
						</ItemContainer>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Dezember 2021: Burnout im 16. Jahrhundert?</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="heerbrand.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//kk.haum-bs.de/?id=j-lederlein-wb3-0002" target="_blank">Jakob Heerbrand<br/>(Virt. Kupferstichkabinett)</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Jakob Heerbrand an Herzog Ludwig von Württemberg, 12. Mai 1580</b><br/><Link to="/brief/22615" target="_blank">Brief-ID 22615</Link><br/></Brieftitel>
					Jakob Heerbrand (1521–1600), Tübinger Professor der Theologie und engagierter Lutheraner, war ein vielbeschäftigter Mann. Nicht nur die Lehrverpflichtungen an der 
					Universität und die Auseinandersetzung mit dogmatischen Fragen seiner Zeit, sondern auch die Beratung des württembergischen Herzogs in theologischen Fragen nahmen 
					seine Zeit und Kraft in Anspruch. Im Frühjahr 1580 hatte Herzog Ludwig ihm wieder einmal ein Buch zugesandt, und zwar Christoph Herdesians „Consensus orthodoxus“. Da der Autor bekannt für seine 
					kompromissbereite Haltung gegenüber den Reformierten war, sollte Heerbrand die Schrift widerlegen.<br/>
					In seinem Antwortbrief bedankt sich Heerbrand bei seinem Landesherrn für die Übersendung des Werks und das ihm entgegengebrachte Vertrauen. Im Anschluss an diese 
					artigen Einleitungsworte kommt Heerbrand jedoch zur Sache: Er habe bisher<i> wie ein willig Pferd </i>nie etwas abgeschlagen oder verweigert, sondern vielmehr der 
					württembergischen Kirche sowie der Universität 37 Jahre lang treu und ohne<i> rhu noch rast </i>nach dem<i> von Gott verlihnen pfündle </i>gedient. Er sei jedoch nicht mehr der 
					jüngste, gehe schon auf die 60 zu und sei vielleicht sogar der älteste Theologe im ganzen Herzogtum. Im Rahmen seiner Ämter müsse er viele Predigten und Vorlesungen 
					halten sowie auf zahlreiche Briefe und Anfragen antworten. Zudem liege er<i> noch mit vnd gegen den Jesuiten vnd anderen Papisten zu feld</i>, die an ihm hingen<i> wie die 
					Zecken</i>, die er<i> ablesen </i>müsse. Der herzogliche Auftrag, Herdesians umfangreiches – 400-seitiges! – Buch zu widerlegen, gehe über seine Kräfte. Diese Aufgabe würde ihn<i> außmerglen </i>und völlig erschöpfen, 
					wie er es früher bereits einmal erlebt habe, und der Herzog könne nicht im Sinne haben, ihn mit dieser Aufgabe<i> auff den schragen </i>– auf das Totenbett – zu bringen.<br/>
					Heerbrand schlägt daher vor, Ludwig möge sich an<i> andere gelertte, iunge, mittelmessige [=mittelalte] vnd auch betagte, gerüwige [=besonnene], erfarne vnd wolbelesne 
					Theologos </i>wenden,<i> welche noch mundter, auch villeicht mehr Zeitt vnd nicht mit so vil vnd mancherley geschefften beladen </i>und<i> nit also abgearbeitet </i>seien wie er. Er 
					bittet also darum, der Herzog möge ihn mit diesem Auftrag<i> gnedigclich verschonen</i>, versichert aber abschließend, der württembergischen Kirche Zeit seines Lebens weiterhin 
					treu dienen zu wollen.<br/><br/>
					Ob Heerbrand schließlich von Ludwig von Württemberg genötigt wurde, Herdesians Werk zu widerlegen oder ob der Herzog einen anderen Theologen dazu verpflichtete, geht aus 
					den Quellen nicht hervor. 19 Jahre später, am 5. Januar 1599, trat Jakob Heerbrand, dessen Kräfte nun endgültig schwanden, in den Ruhestand und legte seine Ämter nieder. Am 22. Mai 1600 starb er im 
					Alter von 78 Jahren in Tübingen.<br/><br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>November 2021: Vandalismus am Auditorium der theologischen Fakultät</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>Rektor Hieronymus Zanchi und Universität Heidelberg an Kurfürst Friedrich III. den Frommen, 9. Juni 1571</b><br/><Link to="/brief/20172" target="_blank">Brief-ID 20172</Link><br/></Brieftitel>
					Schon im 16. Jahrhundert sind Beschwerden über die Zustände in der Heidelberger Altstadt zu vernehmen. Am 9. Juni 1571 übergaben Hieronymus Zanchi, der Rektor 
					der Universität, Immanuel Tremellius, wie Zanchi Professor der Theologie, und der Universitätssyndikus dem pfälzischen Kurfürsten Friedrich III. ein Schreiben, 
					mit dem sie auf Missstände hinwiesen, die vor allem den Alltag der Mitglieder der theologischen Fakultät beeinträchtigten.<br/>
					Die Klage der Verfasser zielt auf das Auditorium der Theologen: Untergebracht in der Kirche des Augustinerklosters – hier befindet sich heute der Universitätsplatz 
					zwischen Alter und Neuer Universität –, werde dieses nämlich immer wieder durch Vandalismus beschädigt. So lässt die theologische Fakultät wissen,<i> das sie ann jrem 
					auditorio jerlich mercklichen schaden nemen, jn deme sie kein gantzen fenster fur den bösen bubenn, so sich gewonlich doselbsten vff dem kirchoff zusamen finden, 
					dorinnen behalten khunden. </i>Außerdem versperrten<i> karrhen vnnd wegen </i>[= Wagen] den Zugang zum Auditorium; Unrat verunstalte den Platz.<br/>
					Um Abhilfe zu schaffen, fordert die Universität nun, die<i> thor vnnd thurlein </i>zum Kirchplatz außerhalb der Vorlesungs- und Predigtzeiten verschlossen halten zu dürfen. 
					Auch möge der Kurfürst seine Fuhrknechte anweisen, ihre Karren nicht wie bisher dort abzustellen. Schließlich sei der Schaden für den Kurfürsten genauso hoch wie jener 
					für die Universität, wenn sich durch die kaputten Fenster jedermann Zugang zum – in der Sakristei des Augustinerklosters verwahrten – Universitätsarchiv verschaffen könne.
					<br/>
					Holzschnitt von der Marienkapelle (der ehemaligen Synagoge), in der sich bis 1558 das Auditorium der Theologen befand:
					<ItemContainer>
						<img src="bdm20172.jpg" width="80%" alt="" />
						<Space/><Space/>Münster, Cosmographia, 1628 (UB Heidelberg), S. 1044.
					</ItemContainer>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Oktober 2021: Ein frühneuzeitlicher „Reisepass“</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="188px" >
						<img src="bdm20500.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//www.landesarchiv-bw.de/plink/?f=1-1466397-115" target="_blank">Stuttgart HSA, A 63, Bü 44</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Herzog Ludwig von Württemberg für Stephan Gerlach, 2. April 1573</b><br/><Link to="/brief/20500" target="_blank">Brief-ID 20500</Link><br/></Brieftitel>
					Wie sah ein frühneuzeitlicher „Reisepass“ aus? Leider besitzen wir keine Ausfertigung des Dokuments, das Herzog Ludwig von Württemberg für den 26-jährigen Tübinger 
					Stiftsrepetenten Stephan Gerlach am 2. April 1573 ausstellte, als dieser sich anschickte, die von David Ungnad geleitete Gesandtschaft Kaiser Maximilians II. nach 
					Konstantinopel als Gesandtschaftsprediger zu begleiten. Doch das im Stuttgarter Hauptstaatsarchiv aufbewahrte Konzept vermittelt uns zumindest einen Eindruck von diesem 
					Vorläufer unserer modernen Reisepässe.<br/>
					In dieser mit <i>Patent vnd Paßwort M[agistri] Stephanj Gerlachs</i> betitelten Urkunde („Paßwort“ geht wohl auf eine volksetymologische Verballhornung von „Paßport“ zurück), 
					richtet Herzog Ludwig eine Bitte <i>an alle vnd jede</i>, den Inhaber des Passes <i>Magistrum Stephanum, nit allein zu wasser vnd land frey, sicher vnd onuerhindert passiren 
					zulassen, sonder auch sonsten jn gnedigstem, gnedigem vnd gunstigem beuelh vnd guter befurderung zuhaben</i>.<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>September 2021: Zu heiß gebadet! Jakob Andreae als unvorsichtiger Kurgast</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>Thomas Erastus an Graf Georg Ernst von Henneberg-Schleusingen, 11. Juni 1572</b><br/><Link to="/brief/11247" target="_blank">Brief-ID 11247</Link><br/></Brieftitel>
					Vor seiner Zeit als einflussreicher Berater Kurfürst Friedrichs III. am Heidelberger Hof amtierte Thomas Erastus, der studierte Mediziner, seit 1555 als 
					Leibarzt Georg Ernsts von Henneberg, mit dem er zeitlebens verbunden blieb. Wie andere Fürsten seiner Zeit reiste der Graf in den Sommermonaten gerne und häufig in die angesagten Kurorte. Hier wollte er nicht nur seiner Gesundheit 
					etwas Gutes tun, sondern auch mit anderen Fürsten und namhaften Persönlichkeiten allerlei Neuigkeiten aus Politik und Gesellschaft austauschen.<br/>
					Im Sommer 1572 traf der Graf von Henneberg bei seiner Kur in Wildbad im Schwarzwald auf den Tübinger Stiftspropst und Universitätskanzler Jakob Andreae. 
					Unverblümt schrieb Georg Ernst an Erastus am 7. Juni nach Heidelberg, unter welchen gesundheitlichen Beschwerden dieser litt und welche Anwendungen er nahm. 
					Andreae hatte offenbar besonderen Gefallen an heißen Bädern gefunden und Georg Ernst berichtete, dass dieser <i>heftig geschwitzet</i> habe und daraufhin in den 
					Regen hinaus gegangen sei, <i>damit er ein wenig kühl werden möcht</i>, schließlich aber so nass geworden sei, dass man sein Hemd <i>hat außwinden mussen. </i>
					In der Nacht darauf sei es Andreae sehr schlecht gegangen und er habe immer wieder das Bewusstsein verloren. Inzwischen gehe es ihm jedoch wieder besser: 
					<i> Er helt noch an mit baden, Badet des tags oft vndt wenig </i>[= kurz]<i>, bißweilen 1, bißweilen 1 ½ oder ii stundt, das wir hoffen, inmassen er sich denn wol 
					befindet, es soll jhm gut thun.</i> […] <i>Sonsten schmecket jhm essen vndt trincken wol.</i><br/>
					Auf diesen freimütigen Bericht über das Kurverhalten des berühmten württembergischen Theologen antwortete Erastus am 11.&nbsp;Juni und schrieb aus ärztlicher 
					Sicht merklich ungehalten: Es sei <i>nit recht gewesen</i>, dass Andreae sich im Regen abkühlen wollte, <i>den die kelte vnd feuchte oder regen alle feuchtigkeit, 
					welche die Natur gegen der haut ausgestossen hatte, widerumb in den leib eins theils getriben hatt. Viel mal hatt sichs zugetragen, das durch solche 
					widerwertige bewegungen grosse, schwere Kranckheiten erwachsen, welchs auch hie geschehen were, wen die Natur nit so krefftig sich erzeigt hette.</i><br/>
					Erastus hofft abschließend, dass Andreae sich in Zukunft vernünftiger verhalten und <i>recht thun</i> werde.											
					<br/>
					<ItemContainer>
						<img src="bdm11247.jpg" width="90%" alt="" />
						<Space/><Space/><Link to="//www.digitale-sammlungen.de/de/view/bsb10164505?page=4,5" target="_blank">Fries, Lorenz: Tractat der Wildbeder natuer wirckung vnd eigentschafft, Straßburg 1519 (VD16 F 2882)</Link>
					</ItemContainer>
					<ItemContainer>
					Weitere Einblicke in das Verhältnis Arzt und Patient bietet:<br/>
					Sabine Arend: Zwischen Selbstfürsorge und Hypochondrie. Georg Ernst von Henneberg (1511-1583) im Briefwechsel mit seinem Leibarzt Thomas Erastus (1524-1583), 
					in: Martin Dinges/Pierre Pfütsch (Hg.): Männlichkeit in der Frühmoderne. Körper, Gesundheit und Krankheit (1500-1850) (Medizin, Gesellschaft und Geschichte, 
					Beiheft 76), Stuttgart 2020, S. 307-329.
					</ItemContainer>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>August 2021: Ursinus’ Mühen mit einem unverschämten Hausschüler</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>Zacharias Ursinus an Johannes Crato, 24. Mai 1555</b><br/><Link to="/brief/16389" target="_blank">Brief-ID 16389</Link><br/></Brieftitel>
					Um sich einen Zuverdienst zu verschaffen, übernahm der aus Breslau stammende und seit April 1550 in Wittenberg studierende Zacharias Ursinus Anfang 1552 
					die Erziehung eines jungen Patriziers, der ebenfalls in die Stadt an der Elbe zum Studium kommen sollte. Es handelte es sich um Eleasar Schlaher, Sohn des 
					reichen Breslauer Bergwerkbesitzers (und wichtigen Gönners von Ursinus!) Quirinus Schlaher. Eleasar bezog ein Zimmer im Haus des Professors Veit Örtel, in 
					dem auch Ursinus wohnte. Von der Entwicklung des Verhältnisses zwischen dem damals 18jährigen Ursinus und seinem Hausschüler zeichnet die Sekundärliteratur 
					ein anschauliches Bild: „Der Zögling wuchs dem schüchternen Lehrmeister rasch über den Kopf. Er überließ sich dem Strom eines ausgelassenen Studentenlebens, 
					das damals in Wittenberg so roh und wild war wie je an einer andern Hochschule Deutschlands. Fast verzweifelte Ursin, als er mit ansehen musste, wie sein 
					Zögling den ganzen Tag außerhalb des Hauses verschwärmte und sinnlos betrunken des Nachts heimkehrte“ (Rott, Ursinus, S. 43f.). Für Ursinus war am 24. Mai 
					1555 das Maß voll: Eleasar machte sich inzwischen über die Milde Philipp Melanchthons lustig und provozierte: Wer ihm mit Kerkerhaft drohe, werde es bitter 
					bereuen. Ursinus schrieb nun seinem Freund Crato, dass er Quirinus Schlaher würde bitten müssen, seinen Sohn wieder ins Elternhaus zurückzuholen. In der 
					Zwischenzeit werde er Gott unaufhörlich anflehen, den Jungen nicht der Macht des Teufels zu überlassen (<i>strenue profecto et ardenter orare non desinam, ut 
					non relinquat eum potestati Diaboli</i>).
					<br/>
					<ItemContainer>
						<img src="wittenberg.jpg" width="90%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/wiki/File:Wittenberg-1556.jpg" target="_blank">Stadtansicht Wittenbergs um 1556</Link>
					</ItemContainer>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juli 2021: Ein Professorenstreit eskaliert</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="100px">
                    	<img src="erastus.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//kunstmuseumbasel.ch/" target="_blank">Portrait des Erastus (Kunstmuseum Basel)</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="42rem">
					<Brieftitel><b>Rektor Thomas Erastus an den Senat der Universität Heidelberg, [12. Dezember 1559]</b><br/><Link to="/brief/71652" target="_blank">Brief-ID 71652</Link><br/></Brieftitel>
					Auch Universitätsprofessoren vergreifen sich bisweilen im Ton. Eindrücklich belegt dies ein Schreiben, das Thomas Erastus – seit 1558 Professor für Medizin in Heidelberg 
					und Mitglied des kurpfälzischen Kirchenrats – Ende 1559 an den Senat der Universität richtet. Anlässlich einer Disputation des Mediziners Petrus Lotichius am 23. August 
					– so berichtet Erastus, der zu diesem Zeitpunkt Rektor der Universität ist – geraten vormittags zunächst Justus Velsius, ein niederländischer Mediziner und Humanist, der 
					bereits für einige Kontroversen gesorgt hatte, und Paul Einhorn (Unicornius), von 1559 bis 1560 Theologie- und Hebräischprofessor, aneinander. Als Einhorn eine Einlassung
					 Velsius’ zurückweisen will, geht ihn dieser scharf an und beschimpft ihn als ungelehrt:<br/>
					<i>„Tace“, inquit, „tu, quoniam nescis disputare, neque facile patior, ut tu indoctus homo inter tam doctos loquare.“</i><br/>
					Nach dem Mittagessen revanchiert sich Einhorn und wirft Velsius vor, nicht nur ein Gaukler und Betrüger zu sein, sondern auch theologische und medizinische Irrtümer zu 
					propagieren:<br/>
					<i>„[E]s impostor et praestigiator. Et non solum in theologiam errores seu haereses invehere cupis, sed etiam in medicinam.“</i><br/>
					Nun schreiten Erastus – in seiner Funktion als Rektor der Universität – und andere ein und fordern Einhorn auf, zu schweigen (<i>iussi, [...] et quidem ex officio, tacere</i>); 
					Erastus droht gar mit einem Universitätsverweis. Das wiederum will Einhorn nicht auf sich sitzen lassen – Erastus lasse sich von seinen Affekten leiten und sei ungerecht: 
					<i>„Domine Rector, tu ex affectibus agis et inique.“</i><br/>
					Erastus bezichtigt Einhorn daraufhin der Lüge (<i>„mentiris“, respondi. [...] „mentiris, nisi quod in me dicis probes“</i>). In seinem Brief an den Senat verteidigt er nun sein 
					Verhalten. Öffentlich hätte ihn Einhorn niemals derart angreifen dürfen, so ein erzürnter Erastus, er hätte ihn unter vier Augen ansprechen oder aber sich an die Universität 
					und den Kurfürsten wenden müssen. Der Senat möge deshalb von Einhorn eine Stellungnahme fordern. Als Rektor erwartet er zudem, vor solchen Beleidigungen geschützt zu werden:
					<i> Ego sane aut Rector esse nolo aut ab hisce iniuriis tutus esse volo.</i>
					<br/>
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Juni 2021: Ein Pfarrer in Geldnöten</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>Elias Schadaeus an die Straßburger Schulherren, 20. März 1584</b><br/><Link to="/brief/86234" target="_blank">Brief-ID 86234</Link><br/></Brieftitel>
					Dass ein Theologenbriefwechsel durchaus auch nicht-theologische Themen umfassen kann, geht aus dem folgenden Brief von Elias Schadeus überdeutlich hervor. Dieser hatte 
					1571 das Straßburger Bürgerrecht erworben, war seit 1577 Pfarrer an der dortigen Alt-St. Peter Kirche und seit 1581 auch Münsterprediger. Im vorliegenden Brief wandte 
					er sich an das städtische Gremium der Straßburger Schulherren.<br/>
					Die Schulherren hatten Schadaeus einen Vorschuss auf seinen Lohn für zwei Jahre gewährt. Dieser hatte sich zwar vorgenommen, die vorgestreckte Summe erst einmal 
					vollständig abzuarbeiten, eine Notlage zwang ihn nun aber, von seinem Vorhaben abzurücken: Seit vier Jahren verzinste Schadaeus 200 Gulden, die er von der Witwe 
					Jakob Bilgenzweigs erhalten hatte, mit jährlich 10 Gulden. Zum Vergleich: Auf dem Höhepunkt seiner Straßburger Karriere verdiente der Reformator Martin Bucer 137 
					Gulden im Jahr. Da die Witwe aber zur Abtragung von Schulden, die ihr verstorbener Mann beim Stift Neuweiler und anderen Gläubigern aufgehäuft hatte, das Geld benötigte, 
					kündigte sie ihm die 200 Gulden auf. Schadaeus stand eine solche Summe jedoch nicht zur Verfügung und er konnte sie auch nicht bei Privatpersonen leihen. So bat er die 
					Schulherren, ihm neben den 100 Gulden für das laufende Jahr, von denen er bereits 30 Gulden abverdient hatte, weitere 100 vorzustrecken, damit er der Witwe die Summe 
					zurückzahlen und auch den Zins ablösen konnte. Als Sicherheit überschrieb er den Schulherren die Bücher seiner Bibliothek:
					<br/>
					<ItemContainer width="780px">
							<i>So aber der liebe Gott, in dessen Hand mein leben und End stehet, mich mitler Zeit mochte aus dieser Welt abfordern, kont und solt als dann aus meiner zimlichen 
							Biblioteck der vbrige schuldige Rest wol bezalt vnd in Bibliothecam Academiae verwendet werden.</i>
                        	<img src="bdm86234.jpg" width="90%" alt="" />
							Straßburg StA, 1 AST 347, Nr. 4, fol. 8v
					</ItemContainer>
					Es ist uns nicht bekannt, ob die Scholarchen der Bitte von Schadaeus entsprochen haben.<br/>
					<Unterschrift>Stephen Buckwalter/Gerald Dörner</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Mai 2021: Bodyshaming im 16. Jahrhundert?</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel><b>Cornelius Faber an Konrad Hubert, 22. Januar 1546</b><br/><Link to="/brief/14435" target="_blank">Brief-ID 14435</Link><br/></Brieftitel>
					Am 22. Januar 1546 richtete Cornelius Faber, ein Diener des Straßburger Reformators Martin Bucer, der diesen gerade auf den Regensburger Reichstag (1545/46) begleitete, 
					einen Brief an seinen Freund Konrad Hubert, der Pfarrer an der Straßburger Thomaskirche war. Darin empfiehlt Faber dem Straßburger Freund seinen Neffen, der in der 
					vom Pädagogen Johannes Sturm gegründeten und von diesem geleiteten Straßburger Akademie ein Studium beginnen soll. Faber bittet Hubert, den Jungen wo nur möglich zu 
					fördern. Wer ein Empfehlungsschreiben wie jedes andere vor sich zu haben meint, stolpert freilich über den nächsten Satz: 
					<br/>
					<ImageColumns width="780px">
						<Row>
                        	<img src="bdm14435.jpg" height="65%" width="65%" alt="" />
							<Space/>
							<div>
							<i>Doleo autem tantae esse iuvenem proceritatis atque corporis vastitatis, ut in collegium pauperum recipi non posset.</i><br/><br/>
							„Ich bedauere, dass der junge Mann von solcher Körpergröße und Leibesfülle ist, dass er in das Collegium Pauperum nicht aufgenommen werden kann“.
							</div>
						</Row>
					</ImageColumns>
					Straßburg StA, 1 AST 156, S. 5<br/><br/>
					Das Collegium Pauperum war ein Konvikt für arme Studenten der Straßburger Akademie, 1543 gegründet und im ehemaligen Wilhelmiterkloster untergebracht, weshalb es auch 
					„Collegium Wilhelmitanum“ genannt wurde.<br/><br/>
					Als zu groß empfundener Körperumfang und Leibesfülle geben natürlich auch heute Anlass zu Gram. Was aber uns moderne Lesende dieser Zeilen stutzig macht – und den 
					biologisch-kulturellen Wandel der letzten 475 Jahre unübersehbar verdeutlicht – ist die letztlich positive Beurteilung der Leibesfülle, die aus der Beobachtung Fabers über 
					seinen Neffen implizit hervorgeht: Er bedauert dessen körperliches Aussehen nicht aus gesundheitlichen oder ästhetischen Gründen, sondern weil es als nicht versteckbaren 
					Beweis von Wohlstand dessen Aussichten auf einen Platz im Armenkonvikt vereitelt. Vom heutigen Bodyshaming weit entfernt!<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>April 2021: Ein saufender Tübinger Stipendiat</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="bdm31216.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="/ansicht/briefhandschrift/5c18ed230c56d81847f9cdf8" target="_blank">Tübingen StiftsA, E 1 279/2</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Herzog Christoph von Württemberg an die Superattendenten und den Magister Domus des Tübinger Stifts, 21. November 1565</b><br/><Link to="/brief/31216" target="_blank">Brief-ID 31216</Link><br/></Brieftitel>
					Bereits im 16. Jahrhundert gelten die Deutschen als besonders trinkfreudig. Der Konsum von Alkohol ist aber nicht nur ein integraler Teil von Geselligkeit und Festkultur. 
					Besonders Wein und Bier sind so etwas wie alltägliche Grundnahrungsmittel, nicht zuletzt, weil Wasser häufig keimbelastet ist. Auch in medizinischen und rituellen Kontexten 
					spielt Alkohol eine wichtige Rolle.<br/>
					Gleichwohl wird der exzessive Alkoholgenuss natürlich als moralisches Laster gebrandmarkt – etwa in Pamphleten und Traktaten –, aber auch als Gefahr für die gesellschaftliche 
					Ordnung und den öffentlichen Frieden wahrgenommen – davon zeugen die zahlreichen Mandate und Ordnungen gegen Zutrinken oder Völlerei.
					</TextblockArea>
				</ItemContainer>
				</Row>
				<Row>
				<ItemContainer>
					<ImageBoxCentered width="355px" >
                    	<img src="zutrinken.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//digital.slub-dresden.de/id498650235" target="_blank">Vonn zutrincken Laster vnnd myßbrauch. Erfurt 1524 (VD16 S 4740), Titelblatt – SLUB Dresden</Link>
					</ImageBoxCentered>
					<TextblockArea width="49rem">
					Auch Studenten trinken gerne und viel; das zumindest lässt unser Brief aus dem Jahr 1565 erahnen, den der württembergische Herzog Christoph (1515-1568) ans Tübinger Stift schickt. 
					Das Stift ist eine Art Kaderschmiede für württembergische Geistliche und Theologen. Herzog Christoph bezieht sich auf einen Bericht über den Stipendiaten Kilian Rauh aus Lauffen,
					den wohl die Superattendenten Jakob Heerbrand (1521-1600) und Johannes Brenz d. J. (1539-1596) sowie der Magister Domus Samuel Heiland (1533-1592) angefertigt hatten:<br/> 
					<i>Wir haben ewern schrifftlichen bericht, vnnsern Stipendiaten Chilianum Rauhen von Lauffen betreffendt, so jetzo zum anndern mahl jm haubt zerrütt vnnd vnrichtig worden Vnnd jr 
					jne derwegen verwaren vnnd anlegen lassen etc., verlesen.</i> Dass sich Rauh wiederholt geistig verwirrt zeigte und deshalb offenbar in Fesseln gelegt wurde, sei nicht Folge ungehorsamen 
					Verhaltens, vielmehr sei <i>jme dise plödigkheit vnnd vbersehen allein aus vbermessigem zechen (dahin er von anndern bewegt vnnd verursacht worden) eruolgt</i>.<br/><br/>
					Das herzogliche Schreiben führt nun aus, wie weiter mit dem Zecher zu verfahren sei: Er soll vor seinen Mit-Stipendiaten um Entschuldigung bitten, soll ermahnt werden, notfalls
					 sollen seine <i>parentes</i> erscheinen. Rauh selbst müsse in Zukunft auf Wein verzichten; allerdings – und das verweist auf die Bedeutung des Alkohols im Alltag – darf er weiter 
					den ihm über Tisch ausgegebenen Wein erhalten. Die anderen Stipendiaten mögen Rauh nicht <i>zum zechen (weil jme der wein so schädlich vnnd nachtheillig ist</i>) auffordern. Bei Verstoß 
					gegen die herzogliche Anordnung drohen empfindliche Strafen.<br/>
					Weitere Einblicke in die Trinkkultur der Frühen Neuzeit bietet:<br/>
					Max Graff: Zutrinken und Trunksucht im Spiegel frühneuzeitlicher Quellen, in: Signa Ivris. Beiträge zur Rechtsikonographie, Rechtsarchäologie und Rechtlichen Volkskunde 18 (2021), S. 211-239.<br/> 
					<Unterschrift>Max Graff</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>März 2021: Des Menschen bester Freund</Titel>
				<Row>
				<ItemContainer>
					<TextblockArea width="49rem">
					<Brieftitel>
						<b>Heinrich Fabricius an Konrad Hubert, 7. November [1574]</b> (<Link to="/brief/21030" target="_blank">Brief-ID 21030</Link>)<br/>
					</Brieftitel>
					Haben Menschen im 16. Jahrhundert eine innige Beziehung zu ihren Haustieren gepflegt? Durchaus, wenn wir den Ausführungen des Bergzaberner 
					Humanisten und Mediziners Heinrich Fabricius (1547-1612) glauben dürfen. In einem Brief an den Straßburger Prediger Konrad Hubert schildert 
					er den schmerzhaften Verlust eines Hundes, seines „treuen Begleiters und Schatzes“, auf der Reise von Straßburg nach Basel. Als Fabricius 
					zwischen Schlettstadt (dem heutigen Séléstat) und Breisach unterwegs war, ergatterte er am überfluteten Schilfufer des Rheins einen Platz in einem 
					vorbeifahrenden Wagen, während sein Hund ihm hinterher folgte. Doch unerwartet hohes Wasser trennte sie immer weiter voneinander, so dass Fabricius 
					irgendwann seinen Hund gar nicht mehr sah, dafür aber sein erbärmliches Geheul (<i>lamentabilis ululatus</i>) aus dem fernen Schilfgras umso herzzerreißender 
					vernahm. Zurück konnte er nicht mehr. Seinen Hund sollte er nie wiedersehen.<br/> 
					In Basel angekommen, ringt er um Worte, die den Verlust angemessen beschreiben: Sein unglückseliger Begleiter, der ihm über Hunderte von Meilen, bei 
					Regenwetter, auf unwegsamen Wegen, zu Wasser und zu Land die Treue hielt, und der mit ihm Gefahren, Mühen und Hunger teilte – hätte es nach so einer 
					langen irdischen Wanderschaft mehr als verdient, sich unter glücklichen und friedlichen Umständen mit seinem Herrchen auszuruhen!
					<br/>
					<ImageColumns width="780px">
						<Row>
                        	<img src="bdm21030.jpg" height="60%" width="60%" alt="" />
							<Space/>
							<i>Infelix, qui centum milliaribus amplius, caelo pluvio, viis inviis, terra aquisque mecum profectus et pericula, labores, inediam perpessus, dignissimus 
							erat, qui in secundis nunc et quiete post tantam peregrinationem mecum feriaretur.</i>
						</Row>
					</ImageColumns>
					Straßburg StA, 1 AST 156, S. 79<br/>
					<Unterschrift>Stephen Buckwalter</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Februar 2021: Zurück zur Normalität!<br/> Der Umgang mit einer Epidemie vor 450 Jahren</Titel>
				<Row>
				<ItemContainer>
					<ImageBox width="195px" >
                    	<img src="esslingen.jpg" width="100%" alt="" />
						<Space/><Space/><Link to="//commons.wikimedia.org/wiki/File:De_Merian_Sueviae_097.jpg" target="_blank">Merian, Topographia Sueviae</Link>
					</ImageBox>
				</ItemContainer>
				<ItemContainer>
					<TextblockArea width="36rem">
					<Brieftitel><b>Samuel Heiland an Herzog Christoph von Württemberg, 16. September 1567</b><br/><Link to="/brief/69882" target="_blank">Brief-ID 69882</Link><br/></Brieftitel>
					Als Im Herbst 1566 die Pest in Tübingen immer heftiger wütete, entschloss sich der Landesherr, Herzog Christoph von Württemberg, 
					die Universität ins rund 50 km entfernt gelegene Esslingen zu verlegen. Professoren und Studenten zogen mit ihrem Gesinde und ihrem 
					Hausstand in die kleine Reichsstadt am Neckar und führten dort den Lehrbetrieb fort. Allerdings waren die Lebens- und Arbeitsbedingungen 
					hier nicht so gut wie in Tübingen, sondern glichen eher einem Provisorium.<br/> 
					Als dann im September des folgenden Jahres die Zahl der 
					infizierten Personen in Tübingen deutlich zurückgegangen waren, dachte man über die Rückkehr nach. Auch Samuel Heiland, der Ephorus 
					des Tübinger Stifts, wollte mit den Stipendiaten so schnell wie möglich wieder nach Tübingen ziehen, nicht zuletzt, weil die Zeit der 
					Ernte und Weinlese vor der Tür stand. <br/><br/>
					Er trug dem Herzog seine Erwägungen brieflich vor:<br/>
					<i>Nach dem jetzund schier ain jar verschinen, daß auff E[urer] F[ürstlichen] G[naden] gnedigen bevelch wir mitt dem Stipendio gehn Eßlingen 
					verruckt und unns bißher allda gehallten, Seind wir seydher inn erfarung kommen, daß an der erbsucht</i> [= Epidemie]<i>, die wir dazumal fliehen 
					müeßten, seyd mittfasten zu Thüwingen niemand weder gestorben noch kranck gelegen. Darumb dann auch jetz ettliche professores vorhabens, 
					damitt sie ihre herpstfrüchte einbringen, mitt ihrem haußgesind hinauff zu ziehen, villeycht der maynung, wa sich disen herpst kain böser 
					lufft eraygen würde, allso zu Thüwingen zuverharren, biß auch andere universitets verwandte hinach kämen.
					Nun seind aber E. F. G. one Zweyffell berichtet, mitt waß beschwerden biß anher wir allhie mitt dem Stipendio haußgehallten und wie schwerlich 
					wir die frucht bekommen kunden, daß, wa wir noch ain weyl verharren sollten, wir nitt one sondere inconvenientz daß Stipendium speysen wurden, 
					zu dem, so ist die Zeytt, da ain jeder haußvatter mitt den seinen seine[n] wein einzuthun sich rüstet und verfaßt macht und inn allen herpst 
					geschefften selber darbey ist.</i><br/><br/>

					Herzog Christoph von Württemberg nahm sich Samuel Heilands Bitte an und schrieb ihm sowie den Verantwortlichen der Universität am 
					22. September (<Link to="/brief/91673" target="_blank">Brief-ID 91673</Link>): Die Stipendiaten sollen in die Herbstferien geschickt werden, 
					sich aber im Anschluss auf den Wiederbeginn des Studienbetriebs 
					in Tübingen einstellen. Der Verwalter soll unterdessen mit seinen Helfern nach Tübingen ziehen und dort das Gemüse ernten und einlagern sowie 
					den Wein lesen und verarbeiten. Da man jedoch nicht wisse, wie lange die Universität wegen der Seuche noch fern von Tübingen bleiben müsse, 
					solle der Hausrat des Stifts zunächst noch in Esslingen bleiben.
					<br/><br/>
					Gegen Ende des Jahres 1567 kehrte die Universität dann nach einjährigem „Exil“ nach Tübingen zurück.<br/>
					<Unterschrift>Sabine Arend</Unterschrift>
					</TextblockArea>
				</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<>
		<Row center>
			<br/><br/>
            <Area>
				<Titel>Januar 2021: Pfarrhaus in Göppingen gesucht</Titel>
				<Row>
					<ItemContainer>
						<ImageBox width="195px" >
                        	<img src="goeppingen.jpg" width="100%" alt="" />
							<Space/><Space/>
							<Link to="//commons.wikimedia.org/wiki/File:De_Merian_Sueviae_118.jpg" target="_blank">Merian, Topographia Sueviae</Link>
						</ImageBox>
					</ItemContainer>
					<ItemContainer>
						<TextblockArea width="36rem">
						<Brieftitel><b>Andreae an Balthasar von Gültlingen, 5. Mai 1554</b><br/>
						<Link to="/brief/80044" target="_blank">Brief-ID 80044</Link><br/></Brieftitel>
						Andreae versichert von Gültlingen seiner Ergebenheit und ist erfreut, dass dieser ihm wohlgesonnen zu sein scheint. 
						Zudem dankt er ihm für seinen Einsatz dafür, dass Andreae ein Haus in Göppingen erhält. Beschreibung eines besichtigten 
						Hauses, das teilweise von anderen bewohnt wird; Andreae bittet, das gesamte Haus kaufen zu dürfen.
						<br/>
						<Unterschrift>Max Graff</Unterschrift>
						</TextblockArea>
					</ItemContainer>
				</Row>
			</Area>
        </Row>
		<br/><br/><br/><br/>
	</>
	<Row>
		<br/><br/>
	</Row>
 </Style>
        );
    }
}

export default BriefDesMonats;
