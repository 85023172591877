import { get } from 'lodash';
import React from 'react';

import { BriefhandschriftRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import RefsLinks from '../../container/RefsLinks';
import SearchHOC from '../../container/SearchHOC';

import { FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

//import { api, iiif_url } from '../../../config'

const SearchHandschrift = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading, token }) => {
    if (!sort.length) defaultSort('archiv.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input width="100%" noMeta label="ID" name="HsID" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('HsID.v')}
                            direction={'HsID.v' === sort ? !asc : false}
                            active={'HsID.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Archiv"
                            api="Archiv"
                            value={get(search, 'archiv') || ''}
                            name="archiv"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('archiv.v')}
                            direction={'archiv.v' === sort ? !asc : false}
                            active={'archiv.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Signatur"
                            api="Signatur"
                            value={get(search, 'signatur') || ''}
                            name="signatur"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('signatur.v')}
                            direction={'signatur.v' === sort ? !asc : false}
                            active={'signatur.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="fol./S."
                            name="seite"
                            value={get(search, 'seite') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('seite.v')}
                            direction={'seite.v' === sort ? !asc : false}
                            active={'seite.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Umfang"
                            name="umfang"
                            value={get(search, 'umfang') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('umfang.v')}
                            direction={'umfang.v' === sort ? !asc : false}
                            active={'umfang.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Nr."
                            name="nummer"
                            value={get(search, 'nummer') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('nummer.v')}
                            direction={'nummer.v' === sort ? !asc : false}
                            active={'nummer.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Überlieferungsform"
                            api="UebForm"
                            placeholder="Überlieferungsform"
                            name="uebForm"
                            value={get(search, 'uebForm')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('uebForm.v')}
                            direction={'uebForm.v' === sort ? !asc : false}
                            active={'uebForm.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Digitalisat"
                            placeholder="http"
                            name="reproduktion.datei"
                            value={get(search, 'reproduktion.datei') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('reproduktion.datei.v')}
                            direction={'reproduktion.datei.v' === sort ? !asc : false}
                            active={'reproduktion.datei.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <div>zugehöriger Brief:</div>
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/briefhandschrift/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.HsID && item.HsID.v}</Cell>
                            <Cell>
                                <Link to={'/edit/archiv/' + get(item, 'archiv.v._id')}>
                                    {get(item, 'archiv.v.short.v')}
                                </Link>
                            </Cell>
                            <Cell>
                                <Link to={'/edit/signatur/' + get(item, 'signatur.v._id')}>
                                    {get(item, 'signatur.v.short.v')}
                                </Link>
                            </Cell>
                            <Cell>{get(item, 'seite.v')}</Cell>
							<Cell>{get(item, 'umfang.v')}</Cell>
                            <Cell>{get(item, 'nummer.v')}</Cell>
                            <Cell>{get(item, 'uebForm.v.short.v')}</Cell>
                            <Cell>
                                {item.reproduktion && item.reproduktion.map((e, index) => (
                                    <>
                                        {(get(e, 'datei.0.v') !== undefined && get(e, 'datei.0.v') !== null) &&
                                        get(e, 'datei.0.v').includes('http') ? (
                                            <LinkExtern href={get(e, 'datei.0.v')} target="blank">
                                                <WebGlobeSVG />
                                            </LinkExtern>
                                        ) : (
											/*	
                                            <LinkExtern
                                                href={
                                                    iiif_url + '?url=' + api + '/api/v1/iiif_old/' +
                                                    get(item, '_id') +
                                                    '/' +
                                                    get(e, 'datei.0.v').replace('/', '!') +
                                                    '?token=' +
                                                    token
                                                }
                                                target="blank"
                                            >
                                                Vorschau
                                            </LinkExtern>*/
                                            get(e, 'datei.0.v') && get(e, 'datei.0.v').includes('/HID') ? (<>importiert</>) : (get(e, 'datei.0.v'))
                                        )}
                                        <br />
                                    </>
                                ))}
                            </Cell>
                            <RefsLinks
                                label=""
                                api="brief"
                                query="handschrift.v="
                                idPath={get(item, '_id')}
                            />
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/briefhandschrift/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="Briefhandschrift" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchHandschrift);
